import React from "react";
import {
  Link,
  type TableColumn,
  TagGroup,
  Text,
} from "@adaptive/design-system";
import { formatCurrency, suffixify } from "@adaptive/design-system/utils";
import { formatDate } from "@adaptive/design-system/utils";
import type { VendorsVendor } from "@api/vendors/types";
import { CURRENCY_FORMAT } from "@src/jobs";
import { isValidEmail } from "@utils/is-valid-email";
import { vendorTagColor } from "@utils/usefulFunctions";
const TABLE_TEST_ID = "vendors-table";
import { CostCodeAccountInfo } from "@components/cost-code-account-info";
import { VENDOR_REQUEST_STATUS } from "@components/request-vendor-ach/constants";

export const COLUMNS: TableColumn<VendorsVendor>[] = [
  {
    id: "display_name",
    sortable: "asc",
    visibility: "always-visible",
    name: "Vendor name",
    minWidth: 200,
    width: "fill",
    render: (row) => (
      <Text data-testid={suffixify(TABLE_TEST_ID, "display-name")}>
        {row.displayName}
      </Text>
    ),
  },
  {
    id: "email",
    width: "fill",
    minWidth: 200,
    sortable: "asc",
    name: "Email",
    render: (row) =>
      isValidEmail(row.email) && (
        <Link
          size="sm"
          href={`mailto:${row.email}`}
          data-testid={suffixify(TABLE_TEST_ID, "email")}
        >
          <Text weight="regular" as="span">
            {row.email}
          </Text>
        </Link>
      ),
  },
  {
    id: "phone",
    name: "Phone",
    width: 150,
    render: (row) =>
      row.phoneNumber && (
        <Link
          size="sm"
          href={`tel:${row.phoneNumber}`}
          data-testid={suffixify(TABLE_TEST_ID, "phone")}
        >
          <Text weight="regular" as="span">
            {row.phoneNumber}
          </Text>
        </Link>
      ),
  },
  {
    id: "total_payables",
    sortable: true,
    name: "Unpaid bills ($)",
    minWidth: 165,
    textAlign: "right",
    render: (row) => formatCurrency(row.totalPayables || 0, CURRENCY_FORMAT),
  },
  window.FAIL_SAFE_COST_CODES_ENABLED
    ? {
        id: "default_job_cost_method",
        name: "Vendor codes",
        width: "fill",
        minWidth: 215,
        render: (row) => {
          const items = row.defaultItems.reduce(
            (acc, item) =>
              item?.displayName ? [...acc, item.displayName] : acc,
            [] as string[]
          );

          const accounts = row.defaultAccounts.reduce(
            (acc, account) =>
              account?.displayName ? [...acc, account.displayName] : acc,
            [] as string[]
          );

          return (
            <CostCodeAccountInfo
              items={items}
              accounts={accounts}
              data-testid={suffixify(TABLE_TEST_ID, "cost-codes-accounts")}
            />
          );
        },
      }
    : {
        id: "default_job_cost_method",
        sortable: "asc",
        minWidth: 245,
        name: "Default cost code / account",
        render: (row) => (
          <Text
            data-testid={suffixify(TABLE_TEST_ID, "default-job-cost-method")}
          >
            {row.defaultAccount?.displayName ?? row.defaultItem?.displayName}
          </Text>
        ),
      },

  {
    id: "average_transaction",
    sortable: true,
    minWidth: 220,
    name: "Average transaction ($)",
    textAlign: "right",
    render: (row) =>
      formatCurrency(row.averageTransaction || 0, CURRENCY_FORMAT),
  },
  {
    id: "doc_status",
    minWidth: 160,
    name: "Document status",
    render: (row) => {
      const statuses = [
        ...(row.hasExpiredDocuments ? ["Expired"] : []),
        ...(row.hasDocRequests ? ["Request sent"] : []),
      ];

      return (
        <TagGroup
          data={statuses}
          color={(item) => vendorTagColor(item)}
          data-testid={suffixify(TABLE_TEST_ID, "doc-status")}
        />
      );
    },
  },
  {
    id: "ach_status",
    minWidth: 160,
    name: "ACH status",
    render: (row) => {
      const statuses = [
        ...(row.hasBankingAch ? ["Active"] : []),
        ...(row.latestAchRequest
          ? [
              `Request ${
                row.latestAchRequest?.status === VENDOR_REQUEST_STATUS.EXPIRED
                  ? "expired"
                  : "sent"
              }
              ${formatDate(row.latestAchRequest?.createdAt, "P")}`,
            ]
          : []),
      ];

      return (
        <TagGroup
          data={statuses}
          color={(item) => vendorTagColor(item)}
          data-testid={suffixify(TABLE_TEST_ID, "ach-status")}
        />
      );
    },
  },
];
