import { useCallback, useMemo } from "react";
import { is } from "@adaptive/design-system/utils";
import type { BudgetLineItems } from "@api/jobs";
import {
  type AccountFilters,
  useAccountsSimplified,
} from "@hooks/use-accounts-simplified";
import {
  useCostCodesSimplified,
  type UseCostCodesSimplifiedProps,
} from "@hooks/use-cost-codes-simplified";
import type { Option } from "@shared/types";
import { isAccount } from "@utils/is-account";

type Payload = Partial<{
  type: string;
  item: { display_name: string | null; url: string | null };
  account: { display_name: string | null; url: string | null };
  category: string | null;
  billable_status: string;
}>;

export type UseCostCodesAccountsSimplifiedProps = {
  accountFilters?: AccountFilters & { enabled?: boolean };
  costCodeFilters?: UseCostCodesSimplifiedProps;
  disableBudgetLines?: Pick<
    BudgetLineItems,
    "jobCostMethod" | "builderAmount" | "changeAmount" | "builderRevisedAmount"
  >[];
  showAll?: boolean;
  includeLabel?: boolean;
};

export const useCostCodesAccountsSimplified = ({
  accountFilters,
  costCodeFilters,
  showAll,
  includeLabel = true,
}: UseCostCodesAccountsSimplifiedProps = {}) => {
  const accounts = useAccountsSimplified({
    enabled:
      accountFilters?.enabled === undefined ? true : accountFilters.enabled,
    filters: { ...accountFilters, showAll },
    includeLabel,
  });

  const costCodes = useCostCodesSimplified({
    showAll,
    includeLabel,
    ...(costCodeFilters || {}),
  });

  const data = useMemo(
    () => [...costCodes.data, ...accounts.data],
    [accounts.data, costCodes.data]
  );

  const status = useMemo(() => {
    const statuses = [accounts.status, costCodes.status];

    if (statuses.some((status) => status === "failed")) return "failed";

    if (statuses.some((status) => status === "loading")) return "loading";

    return "success";
  }, [accounts.status, costCodes.status]);

  const transform = useCallback(
    (option: string | Option) => {
      const enhancedOption = is.object(option)
        ? option
        : data.find((item) => item.value === option);

      const payload: Payload = {
        item: { display_name: null, url: null },
        account: { display_name: null, url: null },
        category: null,
      };

      if (enhancedOption) {
        payload.category =
          (enhancedOption.groupLabel ?? isAccount(enhancedOption))
            ? "Account"
            : "Cost code";

        if (payload.category === "Account") {
          payload.type = "AccountBasedExpenseLineDetail";
          payload.account = {
            display_name: enhancedOption.label,
            url: enhancedOption.value,
          };
        } else {
          payload.type = "ItemBasedExpenseLineDetail";
          payload.item = {
            display_name: enhancedOption.label,
            url: enhancedOption.value,
          };
        }
      }

      return payload;
    },
    [data]
  );

  return { data, status, transform } as const;
};
