import type { PaymentTerm } from "@api/vendors/types";
import { type Option } from "@shared/types/objects";

export const STRINGS = {
  HEADER_TAX_INFO: "Tax Information",
  HEADER_ACH_INFO: "ACH Information",
  HEADER_ADD_DOCS: "Add documents",
  HEADER_PREFERENCES: "Preferences",
  LABEL_TAX_ID: "Tax ID",
  LABEL_TRACK_1099: "Track payments for 1099",
  LABEL_ROUTING_NUMBER: "Routing number",
  LABEL_ACCOUNT_NUMBER: "Account number",
  LABEL_PREFERENCE_TERM: "Payment Terms",
  LABEL_PREFERENCE_ATTRIBUTION: "Default cost code / account",
  BUTTON_REMOVE_ACH: "Clear",
  HINT_PREFERENCE_TERM: "Will automatically prefill due dates on bills",
  ACH_INFO_HELPER_MESSAGE: "Cancel outstanding request to manually add",
};

export const PAYMENT_TERMS: Option<PaymentTerm>[] = [
  { label: "Due on Receipt", value: "ON_RECEIPT" },
  { label: "Net 30", value: "NET_30" },
  { label: "Net 60", value: "NET_60" },
  { label: "Net 90", value: "NET_90" },
];

export const DEFAULT_TRANSACTION_TYPES: Option[] = [
  { label: "Bills", value: "bill" },
  { label: "Receipts", value: "expense" },
];

export const STATE_OPTIONS: Option[] = [
  { value: "AL", label: "AL" },
  { value: "AK", label: "AK" },
  { value: "AZ", label: "AZ" },
  { value: "AR", label: "AR" },
  { value: "CA", label: "CA" },
  { value: "CO", label: "CO" },
  { value: "CT", label: "CT" },
  { value: "DE", label: "DE" },
  { value: "FL", label: "FL" },
  { value: "GA", label: "GA" },
  { value: "HI", label: "HI" },
  { value: "ID", label: "ID" },
  { value: "IL", label: "IL" },
  { value: "IN", label: "IN" },
  { value: "IA", label: "IA" },
  { value: "KS", label: "KS" },
  { value: "KY", label: "KY" },
  { value: "LA", label: "LA" },
  { value: "ME", label: "ME" },
  { value: "MD", label: "MD" },
  { value: "MA", label: "MA" },
  { value: "MI", label: "MI" },
  { value: "MN", label: "MN" },
  { value: "MS", label: "MS" },
  { value: "MO", label: "MO" },
  { value: "MT", label: "MT" },
  { value: "NE", label: "NE" },
  { value: "NV", label: "NV" },
  { value: "NH", label: "NH" },
  { value: "NJ", label: "NJ" },
  { value: "NM", label: "NM" },
  { value: "NY", label: "NY" },
  { value: "NC", label: "NC" },
  { value: "ND", label: "ND" },
  { value: "OH", label: "OH" },
  { value: "OK", label: "OK" },
  { value: "OR", label: "OR" },
  { value: "PA", label: "PA" },
  { value: "RI", label: "RI" },
  { value: "SC", label: "SC" },
  { value: "SD", label: "SD" },
  { value: "TN", label: "TN" },
  { value: "TX", label: "TX" },
  { value: "UT", label: "UT" },
  { value: "VT", label: "VT" },
  { value: "VA", label: "VA" },
  { value: "WA", label: "WA" },
  { value: "WV", label: "WV" },
  { value: "WI", label: "WI" },
  { value: "WY", label: "WY" },
];

export const vendorCodesArticleUrl = "https://www.adaptive.build/";
