import React, {
  type ForwardedRef,
  forwardRef,
  type HTMLAttributes,
  memo,
  type ReactNode,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "clsx";

import { useDeepMemo } from "../../hooks/use-deep-memo";
import { useEvent } from "../../hooks/use-event";
import { useResizeObserver } from "../../hooks/use-resize-observer";
import { isEqual } from "../../utils/is-equal";
import { mergeRefs } from "../../utils/merge-refs";
import { suffixify } from "../../utils/suffixify";
import { Loader } from "../loader";
import {
  Table,
  type TableColumn,
  type TableColumnAddon,
  type TableColumnWidth,
  type TableExpandAddon,
  type TableFooterAddon,
  type TableHeaderAddon,
  type TableOrderAddon,
  type TableProps,
  type TableRef,
  type TableVisibilityAddon,
} from "../table";
import { useTableContext } from "../table/table-context";
import { type OrderAddon, type VisibilityAddon } from "../table/types";
import { getLeafColumns } from "../table/utils";

import styles from "./multiple-table.module.css";

export type MultipleTableRef = HTMLDivElement & { tables: TableRef[] };

type GenericData = Record<string, unknown> & { data?: GenericData[] };

type OnColumnResizeHandler = (i: number, template: string) => void;

type OnFooterHeightChangeHandler = (i: number, height: number) => void;

type MultipleTableWidths = Record<string, TableColumnWidth>;

export type MultipleTableHeader = {
  offset?: number;
};

const SPAN_KEYWORD = "__SPAN__";

export type MultipleTableFooter = {
  data: {
    id: string;
    render: ReactNode | typeof SPAN_KEYWORD;
  }[][];
  offset?: number;
};

export type MultipleTableProps<Data extends GenericData[]> = Pick<
  TableProps<GenericData>,
  "id" | "size" | "data-testid"
> & {
  data: {
    [K in keyof Data]: Omit<
      TableProps<Data[K]>,
      "pagination" | "column" | "header" | "columns"
    > & {
      hide?: boolean;
      header?: Pick<Exclude<TableProps<Data[K]>["header"], undefined>, "hide">;
      columns: TableProps<Data[K]>["columns"];
    };
  };
  header?: MultipleTableHeader;
  footer?: MultipleTableFooter;
  bordered?: boolean;
  className?: string;
};

type MultipleTableTableProps = {
  index: number;
  isLast: boolean;
  widths: MultipleTableWidths;
  hasSelect?: boolean;
  headerHide?: boolean;
  headerOffset?: number;
  footerOffset?: number;
  onColumnResize: OnColumnResizeHandler;
  footerTotalHeight: number;
} & Omit<TableProps<GenericData>, "pagination" | "column" | "header">;

const MultipleTableTable = memo(
  forwardRef<TableRef, MultipleTableTableProps>(
    (
      {
        id,
        index,
        isLast,
        widths,
        footer,
        select,
        columns,
        bordered,
        hasSelect,
        className,
        headerHide,
        headerOffset,
        footerOffset,
        onColumnResize,
        footerTotalHeight,
        "data-testid": testId,
        ...props
      },
      ref
    ) => {
      const internalId = useId();

      const enhancedId = id ?? internalId;

      const isFirst = index === 0;

      const enhancedFooterOffset = footerTotalHeight - 1 + (footerOffset ?? 0);

      const header = useMemo<TableHeaderAddon>(
        () => ({
          hide: headerHide ?? index > 0,
          sticky: {
            offset: headerOffset ?? 0,
          },
        }),
        [headerHide, index, headerOffset]
      );

      const column = useMemo<TableColumnAddon>(
        () => ({ onResize: ({ template }) => onColumnResize(index, template) }),
        [index, onColumnResize]
      );

      const enhancedFooter = useMemo<TableFooterAddon>(
        () => ({
          hide: footer?.hide,
          sticky: { offset: enhancedFooterOffset },
        }),
        [footer?.hide, enhancedFooterOffset]
      );

      const enhancedColumns = useMemo<TableColumn<GenericData>[]>(() => {
        const enhanced: TableColumn<GenericData>[] = [];

        if (hasSelect) {
          enhanced.push({
            id: "select",
            name: null,
            render: () => null,
            maxWidth: 36,
          });
        }

        const enhanceColumn = (
          column: TableColumn<GenericData>
        ): TableColumn<GenericData> =>
          !("columns" in column)
            ? { ...column, width: widths[column.id] ?? "fill" }
            : {
                ...column,
                columns: column.columns.map((subColumn) =>
                  enhanceColumn(subColumn)
                ),
              };

        for (let i = 0; i < columns.length; i++) {
          enhanced.push(enhanceColumn(columns[i]));
        }

        return enhanced;
      }, [columns, hasSelect, widths]);

      return (
        <Table
          id={enhancedId}
          ref={ref}
          column={column}
          header={header}
          select={select}
          footer={enhancedFooter}
          columns={enhancedColumns}
          bordered={bordered}
          className={cn(className, styles["table"], {
            [styles["-shift"]]: hasSelect,
            [styles["-borderless-top"]]: !isFirst,
            [styles["-borderless-select"]]: select || hasSelect,
            [styles["-borderless-bottom"]]: !isLast || footerTotalHeight > 0,
          })}
          data-testid={suffixify(testId, "table", index)}
          {...props}
          loading={false}
        />
      );
    }
  )
);

MultipleTableTable.displayName = "MultipleTableTable";

type MultipleTableFooterProps = HTMLAttributes<HTMLDivElement> & {
  data: MultipleTableFooter["data"][number];
  index: number;
  offset?: MultipleTableFooter["offset"];
  heights: number[];
  hasSelect?: boolean;
  "data-testid"?: string;
  onHeightChange: OnFooterHeightChangeHandler;
};

const MultipleTableFooter = memo(
  ({
    data,
    index,
    style,
    offset = 0,
    heights,
    hasSelect,
    "data-testid": testId,
    onHeightChange,
  }: MultipleTableFooterProps) => {
    const internalRef = useRef<HTMLDivElement>(null);

    const enhancedOffset =
      heights.reduce(
        (acc, height, footerIndex) =>
          footerIndex <= index ? acc : acc + height - 1,
        0
      ) + offset;

    const enhancedStyle = useDeepMemo(
      () => ({
        ...style,
        "--multiple-table-footer-offset": `${enhancedOffset}px`,
      }),
      [style, enhancedOffset]
    );

    const enhancedData = useMemo(
      () => [...(hasSelect ? [null] : []), ...data],
      [data, hasSelect]
    );

    useResizeObserver(
      internalRef,
      (el) => onHeightChange(index, el.offsetHeight),
      { observe: "height" }
    );

    useEffect(() => {
      return () => onHeightChange(index, 0);
    }, [index, onHeightChange]);

    return (
      <div
        ref={internalRef}
        style={enhancedStyle}
        className={cn(styles["footer"], { [styles["-select"]]: hasSelect })}
      >
        {enhancedData.map((column, columnIndex) => {
          const isSpan = column?.render === SPAN_KEYWORD;
          const nextColumnIsSpan =
            enhancedData[columnIndex + 1]?.render === SPAN_KEYWORD;

          return (
            <div
              key={columnIndex}
              className={cn(styles["column"], {
                [styles["-span"]]: nextColumnIsSpan,
              })}
              data-last={columnIndex === enhancedData.length - 1 || undefined}
              data-testid={suffixify(
                testId,
                "footer-row",
                index,
                "column",
                columnIndex
              )}
            >
              {!isSpan ? column?.render : null}
            </div>
          );
        })}
      </div>
    );
  }
);

MultipleTableFooter.displayName = "MultipleTableFooter";

const MultipleTable = <Data extends GenericData[]>(
  {
    id,
    data,
    size = "md",
    header,
    footer,
    bordered = true,
    className,
    "data-testid": testId,
  }: MultipleTableProps<Data>,
  ref: ForwardedRef<MultipleTableRef>
) => {
  const internalRef = useRef<MultipleTableRef>(null);

  const tableContext = useTableContext();

  const enhancedId = id ?? tableContext.id;

  const [order, setOrder] = useState<OrderAddon["value"]>();

  const [isLoading, setIsLoading] = useState(true);

  const tableRefs = useRef<TableRef[]>([]);

  const [templates, setTemplates] = useState<string[]>([]);

  const [visibility, setVisibility] = useState<VisibilityAddon["value"]>();

  const [currentData, setCurrentData] = useState([] as typeof data);

  const [footersHeights, setFootersHeights] = useState(
    (footer?.data ?? []).map(() => 0)
  );

  const hasSelect = data.some((table) => !!table.select);

  const footerTotalHeight = useDeepMemo(
    () => footersHeights.reduce((acc, curr) => acc + curr, 0),
    [footersHeights]
  );

  const template = useDeepMemo(() => {
    if (templates.length === 0) return undefined;

    const values: [number | string, string][] = [];

    const maxColumns = Math.max(
      ...templates.map((template) => template.split("minmax").length)
    );

    const enhancedTemplates = templates.map((template) => {
      const templateLength = template.split("minmax").length;
      const missingColumns = maxColumns - templateLength;

      if (missingColumns === 1) {
        return `${template} minmax(0px, 0px)`;
      }

      return template;
    });

    for (const template of enhancedTemplates) {
      const valuesAndUnits = template.match(/(\d+px|\d+fr|min-content)/g) || [];

      for (let j = 0; j < valuesAndUnits.length; j++) {
        const match = (
          valuesAndUnits[j].match(/^(\d+)?(px|fr|min-content)$/) || []
        ).slice(1);

        let value = match[0];
        const nextUnit = match[1];

        if (nextUnit === "min-content") {
          value = nextUnit;
        }

        const nextValue =
          nextUnit !== "min-content" ? parseFloat(value) : value;

        if (!values[j]) {
          values[j] = [nextValue, nextUnit];
          continue;
        }

        const [previousValue, previousUnit] = values[j];

        if (nextUnit === "min-content") {
          if (["px", "fr"].includes(previousUnit)) continue;

          values[j] = [nextValue, nextUnit];
        }

        if (nextUnit === "fr" && previousUnit === "px") {
          values[j] = [nextValue, nextUnit];
        } else if (
          nextUnit === "fr" &&
          previousUnit === "fr" &&
          nextValue > previousValue
        ) {
          values[j] = [nextValue, nextUnit];
        } else if (nextValue > previousValue) {
          values[j] = [nextValue, nextUnit];
        }
      }
    }

    const result = [];

    for (let i = 0; i < values.length; i += 2) {
      if (!values[i] || !values[i + 1]) continue;

      const [minValue, minUnit] = values[i];
      const [maxValue, maxUnit] = values[i + 1];

      result.push(
        `minmax(${minValue === "min-content" ? "" : minValue}${minUnit}, ${
          maxValue === "min-content" ? "" : maxValue
        }${maxUnit})`
      );
    }

    return result.join(" ");
  }, [templates]);

  const style = useMemo(
    () => ({
      "--table-template-columns": template
        ? template
        : "repeat(auto-fit, minmax(0, 1fr))",
    }),
    [template]
  );

  const nextData = useMemo(
    () => data.filter((table) => table.hide !== true),
    [data]
  );

  const widths = useDeepMemo(
    () =>
      getLeafColumns(nextData?.[0].columns ?? []).reduce(
        (acc, column) => ({ ...acc, [column.id]: column.width ?? "fill" }),
        {} as MultipleTableWidths
      ),
    [order, nextData]
  );

  const visibleFooter = useMemo(
    () =>
      (footer?.data ?? []).map((columns) =>
        columns
          .filter((column) => visibility?.[column.id] !== false)
          .sort((a, b) => {
            const orderA = order?.indexOf(a.id) ?? 0;
            const orderB = order?.indexOf(b.id) ?? 0;
            return orderA - orderB;
          })
      ),
    [footer?.data, visibility, order]
  );

  const firstTableExpandAddon = useMemo<TableExpandAddon<Data[0]> | undefined>(
    () => ({
      onChangeAll: (value) => {
        tableRefs.current?.forEach((el, i) => {
          if (i !== 0) value ? el.expandAll() : el.collapseAll();
        });
      },
    }),
    []
  );

  const firstTableOrderAddon = useMemo<TableOrderAddon | undefined>(
    () => ({ onChange: setOrder }),
    []
  );

  const firstTableVisibilityAddon = useMemo<TableVisibilityAddon | undefined>(
    () => ({ onChange: setVisibility }),
    []
  );

  const restTableOrderAddon = useDeepMemo<TableOrderAddon | undefined>(
    () => ({
      value: order ? (hasSelect ? ["select", ...order] : order) : undefined,
    }),
    [order, hasSelect]
  );

  const restTableVisibilityAddon = useDeepMemo<
    TableVisibilityAddon | undefined
  >(() => ({ value: visibility }), [visibility]);

  const onColumnResize = useEvent<OnColumnResizeHandler>((i, template) => {
    setTemplates((previousTemplates) => {
      const nextTemplates = [...previousTemplates];
      nextTemplates[i] = template;

      return isEqual(previousTemplates, nextTemplates)
        ? previousTemplates
        : nextTemplates;
    });
  });

  const onFooterHeightChange = useEvent<OnFooterHeightChangeHandler>(
    (index, height) => {
      setFootersHeights((previousHeights) => {
        const nextHeights = [...previousHeights];
        nextHeights[index] = height;

        return isEqual(previousHeights, nextHeights)
          ? previousHeights
          : nextHeights;
      });
    }
  );

  const enhancedIsLoading =
    currentData.map(({ loading }) => loading).some(Boolean) || isLoading;

  useEffect(() => {
    setCurrentData(nextData as typeof data);
  }, [nextData]);

  useEffect(() => {
    const isLoading = currentData.map(({ loading }) => loading).some(Boolean);
    const adjustedTemplates = templates.filter((template) => template);
    const adjustedCurrentData = currentData.filter(({ data }) => data?.length);

    if (!isLoading && adjustedTemplates.length === adjustedCurrentData.length) {
      setIsLoading(false);
    }
  }, [templates, currentData]);

  useImperativeHandle(ref, () => {
    const rootEl = internalRef.current!;
    rootEl.tables = tableRefs.current;

    return rootEl;
  }, []);

  return (
    <div
      ref={mergeRefs(ref, internalRef)} // eslint-disable-line
      className={cn(className, styles["multiple-table"], {
        [styles[`-${size}`]]: size,
        [styles["-loading"]]: enhancedIsLoading,
        [styles["-bordered"]]: bordered,
      })}
      data-testid={testId}
    >
      {enhancedIsLoading && (
        <div className={styles["loader"]}>
          <Loader />
        </div>
      )}
      {nextData.map(({ header: tableHeader, ...table }, i) => {
        const isLast = i === nextData.length - 1;

        return (
          <MultipleTableTable
            id={i === 0 ? enhancedId : undefined}
            key={i}
            size={size}
            index={i}
            order={i === 0 ? firstTableOrderAddon : restTableOrderAddon}
            visibility={
              i === 0 ? firstTableVisibilityAddon : restTableVisibilityAddon
            }
            ref={(el) => {
              if (el) tableRefs.current[i] = el;

              return () => {
                delete tableRefs.current[i];
              };
            }}
            expand={i === 0 ? firstTableExpandAddon : undefined}
            style={style}
            widths={widths}
            isLast={isLast}
            bordered={bordered}
            hasSelect={hasSelect && !table.select}
            headerHide={tableHeader?.hide}
            data-testid={testId}
            headerOffset={header?.offset ?? 0}
            footerOffset={footer?.offset ?? 0}
            onColumnResize={onColumnResize}
            footerTotalHeight={footerTotalHeight}
            {...table}
          />
        );
      })}
      {visibleFooter.map((item, i) => (
        <MultipleTableFooter
          key={i}
          data={item}
          index={i}
          style={style}
          offset={footer?.offset}
          heights={footersHeights}
          hasSelect={hasSelect}
          data-testid={testId}
          onHeightChange={onFooterHeightChange}
        />
      ))}
    </div>
  );
};

const ForwardedMultipleTable = forwardRef(MultipleTable) as <
  Data extends GenericData[],
>(
  props: MultipleTableProps<Data> & { ref?: ForwardedRef<MultipleTableRef> }
) => ReturnType<typeof MultipleTable>;

export { ForwardedMultipleTable as MultipleTable };
