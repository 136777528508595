import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Link,
  Loader,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { ErrorAlert } from "@components/common/error-alert";
import { TooManyLinesAlert } from "@components/common/too-many-lines-alert";
import { DuplicateAlert } from "@components/duplicate-alert";
import { Form } from "@components/form";
import {
  useBillFormActions,
  useBillFormPermissions,
} from "@src/bills/bill-form-context";
import { BILL_STATUS, STRINGS } from "@src/bills/constants";
import {
  approvalsBillSelector,
  camelCaseBillSelector,
  duplicatesBillSelector,
  errorsBillSelector,
  relatedErrorsBillSelector,
  workflowsBillSelector,
} from "@src/bills/utils";
import { loadBill, unarchiveCurrentBill } from "@store/billSlice";
import { useAppSelector } from "@store/hooks";

import { ApprovalsSection } from "../approvals-section/";
import { Comments } from "../comments";
import { useCycle } from "../cycle-provider";
import { DynamicActions } from "../dynamic-actions";
import { Info } from "../info";
import { Items } from "../items";
import { PurchaseOrders, PurchaseOrdersAlert } from "../purchase-orders";

export const EditBillStep = () => {
  const { save, close: onClose } = useBillFormActions();

  const {
    id,
    isArchivedByUser,
    linesCount,
    tooManyLines,
    initialReviewStatus,
    shouldShowPurchaseOrders,
  } = useAppSelector(camelCaseBillSelector);

  const errors = useAppSelector(errorsBillSelector);

  const relatedErrors = useAppSelector(relatedErrorsBillSelector);

  const approvals = useAppSelector(approvalsBillSelector);

  const duplicates = useAppSelector(duplicatesBillSelector);

  const workflows = useAppSelector(workflowsBillSelector);

  const cycle = useCycle();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const refetchBill = useCallback(() => dispatch(loadBill(id)), [dispatch, id]);

  const permissions = useBillFormPermissions();

  const onCancel = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const onUnarchive = useEvent(() => {
    cycle.disable();
    dispatch(unarchiveCurrentBill());
  });

  const onSubmit = useEvent(() => {
    save({
      status: BILL_STATUS.APPROVAL,
      onSuccess: async (data) => {
        toast.success(
          `Bill ${
            data.doc_number ? `#${data.doc_number} ` : ""
          }was sent for approval`
        );

        const hasNext = cycle.hasNavigation && (await cycle.next());

        if (hasNext) return;

        navigate("/bills?status=draft");
        toast.success("You reviewed all bills in your inbox");
      },
    });
  });

  const onSave = useEvent(() => save({ status: BILL_STATUS.DRAFT }));

  const onAdvanceToApproval = useEvent(async () => {
    await save({ status: BILL_STATUS.APPROVAL });
    cycle.disable();
  });

  return (
    <>
      <Flex gap="5xl" direction="column" className="steps-section-content">
        <Form id="edit-bill-form" onSubmit={onSubmit} onEnterSubmit={onSave}>
          <Flex gap="5xl" direction="column" padding={["3xl", "5xl", "none"]}>
            <Flex gap="xl" direction="column">
              {isArchivedByUser && permissions.canEditBill && (
                <Alert variant="info">
                  <AlertTitle>This bill is not editable</AlertTitle>
                  <AlertContent>
                    <Link as="button" type="button" onClick={onUnarchive}>
                      Restore this bill
                    </Link>{" "}
                    in order to make any modifications to it
                  </AlertContent>
                </Alert>
              )}

              {duplicates.length > 0 && <DuplicateAlert data={duplicates} />}

              <PurchaseOrdersAlert />

              <TooManyLinesAlert
                transactionType="Bill"
                linesCount={linesCount}
              />

              {(errors.length > 0 || relatedErrors.length > 0) && (
                <ErrorAlert
                  data={errors}
                  onChange={refetchBill}
                  objectType="Bill"
                  relatedData={relatedErrors}
                />
              )}

              <Info />
            </Flex>

            {shouldShowPurchaseOrders && <PurchaseOrders />}

            <Items />
          </Flex>
        </Form>

        <Flex
          direction="column"
          shrink={false}
          gap="5xl"
          padding={["none", "5xl", "3xl"]}
        >
          <ApprovalsSection
            objectId={id}
            editable={
              initialReviewStatus === BILL_STATUS.DRAFT && !isArchivedByUser
            }
            objectType="Bill"
            approvals={approvals}
            workflows={workflows}
            helperMessage={(mode) =>
              mode !== "EMPTY" && initialReviewStatus !== BILL_STATUS.DRAFT
                ? "Click save to enable editing of approvals"
                : ""
            }
          />
          <Comments />
        </Flex>
      </Flex>

      <footer className="steps-section-footer">
        <Flex gap="xl">
          <Button variant="text" size="lg" color="neutral" onClick={onCancel}>
            Cancel
          </Button>
          <DynamicActions />
        </Flex>
        <Flex gap="xl">
          {isArchivedByUser ? (
            <Button size="lg" onClick={onUnarchive}>
              Restore
            </Button>
          ) : (
            <>
              <Tooltip
                message={
                  permissions.canEditBill
                    ? tooManyLines
                      ? STRINGS.BUTTON_TOO_MANY_LINES
                      : undefined
                    : STRINGS.ACTION_NOT_ALLOWED
                }
                placement="left"
              >
                <Button
                  disabled={
                    isArchivedByUser || !permissions.canEditBill || tooManyLines
                  }
                  variant="ghost"
                  size="lg"
                  onClick={onSave}
                >
                  Save draft
                </Button>
              </Tooltip>
              <Tooltip
                message={
                  permissions.canEditBill
                    ? tooManyLines
                      ? STRINGS.BUTTON_TOO_MANY_LINES
                      : undefined
                    : STRINGS.ACTION_NOT_ALLOWED
                }
                placement="left"
              >
                <ButtonGroup
                  size="lg"
                  disabled={
                    isArchivedByUser ||
                    !permissions.canEditBill ||
                    tooManyLines ||
                    cycle.isLoading
                  }
                >
                  {cycle.status && cycle.status !== "all" ? (
                    <>
                      <Button type="submit" form="edit-bill-form">
                        {cycle.isLoading ? <Loader /> : "Send for approval"}
                      </Button>
                      {!cycle.isLoading && (
                        <Dropdown placement="bottom-end">
                          <DropdownTrigger
                            as={Button}
                            aria-label="Actions"
                            data-testid="bill-edit-actions-trigger"
                          >
                            <Icon name="chevron-down" />
                          </DropdownTrigger>
                          <DropdownList>
                            <DropdownItem onClick={onAdvanceToApproval}>
                              Advance to approval
                            </DropdownItem>
                          </DropdownList>
                        </Dropdown>
                      )}
                    </>
                  ) : (
                    <Button onClick={onAdvanceToApproval}>
                      Advance to approval
                    </Button>
                  )}
                </ButtonGroup>
              </Tooltip>
            </>
          )}
        </Flex>
      </footer>
    </>
  );
};
