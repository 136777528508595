import { is } from "@adaptive/design-system/utils";
import { type Option } from "@shared/types";

export function isAccount(
  optionOrUrl: Option | undefined
): optionOrUrl is Option;
export function isAccount(optionOrUrl: string): optionOrUrl is string;
export function isAccount(optionOrUrl: null | undefined): false;
export function isAccount(
  optionOrUrl: Option | null | undefined | string
): boolean {
  return (
    (is.string(optionOrUrl) ? optionOrUrl : optionOrUrl?.value)?.includes(
      "/accounts/"
    ) || false
  );
}
