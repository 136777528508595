import { useMemo } from "react";
import { useAsSimplifiedData } from "@shared/hooks/useSimplifiedData";
import { useGetCostCodesSimplifiedQuery } from "@store/api-simplified";
import { useClientInfo } from "@store/user";

export type UseCostCodesSimplifiedProps = {
  active?: boolean;
  enabled?: boolean;
  includeLabel?: boolean;
  showDisableAsEnabled?: boolean;
  missingExpenseAccount?: boolean;
  canItemsLinkToLinesDesktop?: boolean;
  vendorId?: number | string;
  showAll?: boolean;
  customerId?: number | string;
};

export const useCostCodesSimplified = ({
  enabled = true,
  includeLabel = false,
  showDisableAsEnabled = false,
  missingExpenseAccount,
  canItemsLinkToLinesDesktop,
  active,
  vendorId,
  showAll,
  customerId,
}: UseCostCodesSimplifiedProps = {}) => {
  const { client, realmId } = useClientInfo();

  const params = {
    ...(missingExpenseAccount !== undefined && {
      expense_account__isnull: missingExpenseAccount,
    }),
    ...(active !== undefined && { active }),
    realm: realmId as number,
    show_all: showAll,
    vendor_id: vendorId,
    customer_id: customerId,
  };

  const enhancedCanItemsLinkToLinesDesktop =
    canItemsLinkToLinesDesktop ??
    client?.settings.can_items_link_to_lines_desktop;

  const skip = !enabled || !realmId || !enhancedCanItemsLinkToLinesDesktop;

  const query = useGetCostCodesSimplifiedQuery(params, { skip });

  const { data, ...results } = useAsSimplifiedData(query, {
    groupLabel: includeLabel ? "Cost code" : undefined,
  });

  const enhancedData = useMemo(
    () =>
      data.map((costCode) => {
        return {
          ...costCode,
          disabled:
            !showDisableAsEnabled && costCode.disabled
              ? "This cost code does not have an expense account"
              : undefined,
        };
      }),
    [data, showDisableAsEnabled]
  );

  return { ...results, data: enhancedData };
};
