import React, { type PropsWithChildren, useEffect, useRef } from "react";

import { BREAKPOINTS } from "../../constants";
import { useResizeObserver } from "../../hooks/use-resize-observer";
import { isIOS } from "../../utils/is-ios";
import { onlyShowFocusOnTabs } from "../../utils/only-show-focus-on-tabs";
import { ResponsiveProvider } from "../responsive-provider/responsive-provider";
import { ToastContainer } from "../toast/toast-container";

import {
  Context,
  type ContextType,
  DEFAULT_CONTEXT,
  DialogContainer,
} from "./provider-context";

type Props = PropsWithChildren<Partial<ContextType>>;

export const Provider = ({ children, ...props }: Props) => {
  const windowRef = useRef<Window>(window);

  const documentRef = useRef<HTMLElement>(document.documentElement);

  /**
   * 100vh doesn't work properly on WebKit mobile so we need to add it manually. We could follow something
   * suggested here https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ but we prefer
   * to set it dynamic as we don't want to prevent our team to use css calc with --spacing-screen-y variable.
   */
  useResizeObserver(
    documentRef,
    (el) => {
      if (isIOS()) {
        el.style.setProperty("--spacing-screen-y", `${window.innerHeight}px`);
      }
    },
    { observe: "height" }
  );

  useEffect(() => {
    const stop = onlyShowFocusOnTabs();

    return () => stop();
  }, []);

  return (
    <Context.Provider value={{ ...DEFAULT_CONTEXT, ...props }}>
      <ResponsiveProvider
        ref={windowRef}
        breakpoints={BREAKPOINTS}
        initialBreakpoint="largeDesktop"
      >
        <ToastContainer />
        <DialogContainer />
        {children}
      </ResponsiveProvider>
    </Context.Provider>
  );
};
