import React, { forwardRef, type PropsWithChildren } from "react";

import { useBreakpoints } from "../../hooks/use-breakpoints";

import { Context } from "./responsive-provider-context";

type Props = PropsWithChildren<{
  breakpoints: Record<string, number>;
  initialBreakpoint: string;
}>;

const EMPTY_REF = { current: null };

export const ResponsiveProvider = forwardRef<
  HTMLElement | Window | null,
  Props
>(({ children, breakpoints, initialBreakpoint }, ref) => {
  const value = useBreakpoints(
    ref && typeof ref === "object" && "current" in ref ? ref : EMPTY_REF,
    { breakpoints, initialBreakpoint }
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
});

ResponsiveProvider.displayName = "ResponsiveProvider";
