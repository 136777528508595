import React, { useRef } from "react";
import {
  type ResponsiveProp,
  useResizeObserver,
  useResponsiveProp,
} from "@adaptive/design-system/hooks";
import { forwardRefAs, mergeRefs } from "@adaptive/design-system/utils";
import cn from "clsx";

import styles from "./main.module.css";

const DEFAULT_COMPONENT = "main";

type Props = {
  variant?: ResponsiveProp<"unspaced" | "spaced-sm" | "spaced-md">;
  scrollable?: boolean;
};

export const MainContent = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      variant: variantRaw,
      scrollable = true,
      className,
      ...props
    },
    ref
  ) => {
    const internalRef = useRef<HTMLElement>(null);
    const variant = useResponsiveProp(variantRaw, "spaced-md");

    useResizeObserver(internalRef, (el) => {
      const scrollbarWidth = el.offsetWidth - el.clientWidth;
      el.style.setProperty(
        "--main-content-scrollbar-width",
        `${scrollbarWidth}px`
      );
    });

    return (
      <Component
        ref={mergeRefs(ref, internalRef)}
        className={cn(className, styles["main-content"], {
          [styles[`-${variant}`]]: variant,
          [styles["-scrollable"]]: scrollable,
        })}
        data-main-content=""
        {...props}
      />
    );
  }
);

MainContent.displayName = "MainContent";
