import React, { useMemo } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Loader,
  Table,
  type TableColumn,
  Text,
  Textarea,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  createFormContext,
  useDeepMemo,
  type UseDialogReturn,
  useEvent,
} from "@adaptive/design-system/hooks";
import {
  handleErrors,
  parseCustomErrors,
  transformErrorToCustomError,
} from "@api/handle-errors";
import { postVendorAchRequest } from "@api/vendor-requests";
import { useTwoFactorAuth } from "@store/ui";
import { useUserInfo } from "@store/user";
import { summarizeResults } from "@utils/all-settled";
import { idSchema } from "@utils/schema";
import { type AnyZodObject, z } from "zod";

import { STRINGS } from "./constants";

export type AchRequestDialogProps = {
  dialog: UseDialogReturn;
  multiple: boolean;
  onSubmit?: () => void;
  initialValues?: Partial<Fields>;
};

const partialVendorSchema = z.object({
  id: idSchema,
  message: z.string().nullish(),
  displayName: z.string(),
  billId: z.number().nullish(),
});

const partialSchema = z.object({
  applyMessageIndex: z.number().nullish(),
  includeCustomMessage: z.boolean(),
});

const setEmailSchema = partialSchema.extend({
  vendors: z.array(
    partialVendorSchema.extend({
      email: z.string().email("An email address is required"),
    })
  ),
  ccRequestingUser: z.boolean(),
});
type Fields = z.input<typeof setEmailSchema>;

const { useForm, FormConsumer, FormProvider } = createFormContext<Fields>();

const getColumns = (includeCustomMessage: boolean, dialogHide: () => void) => {
  const columns: TableColumn<Fields["vendors"][number]>[] = [
    {
      id: "displayName",
      name: "Vendor name",
      width: "fill",
      align: includeCustomMessage ? "start" : "center",
      render: (row) => <Text size="sm">{row.displayName}</Text>,
      minWidth: "content",
    },
    {
      id: "email",
      name: "Email",
      width: 280,
      align: includeCustomMessage ? "start" : "center",
      render: (row, index) => (
        <FormConsumer>
          {(form) => (
            <TextField
              key={row.id}
              size="sm"
              type="email"
              required
              disabled={form.isSubmitting}
              placeholder="Enter an email address"
              messageVariant="hidden"
              {...form.register(`vendors.${index}.email`)}
            />
          )}
        </FormConsumer>
      ),
    },
  ];

  if (includeCustomMessage) {
    columns.push({
      id: "message",
      name: "Message",
      align: includeCustomMessage ? "start" : "center",
      width: 380,
      render: (row, index) => (
        <FormConsumer>
          {(form) => (
            <Flex gap="md" direction="column">
              <Textarea
                minHeight={56}
                maxHeight={56}
                disabled={form.isSubmitting}
                placeholder="Optional message included in the request email"
                messageVariant="hidden"
                {...form.register({
                  name: `vendors.${index}.message`,
                  type: "string",
                  onChange: (value) => {
                    if (form.values.applyMessageIndex === null) return;

                    if (form.values.applyMessageIndex !== index) {
                      return form.setValue("applyMessageIndex", null);
                    }

                    form.values.vendors.map((_, i) => {
                      if (i === index) return;

                      form.setValue(`vendors.${i}.message`, value);
                    });
                  },
                })}
              />
              <Checkbox
                label="Apply to all requests"
                checked={form.values.applyMessageIndex !== null}
                disabled={
                  (form.values.applyMessageIndex !== null &&
                    form.values.applyMessageIndex !== index) ||
                  form.isSubmitting
                }
                onChange={(checked) => {
                  form.setValue("applyMessageIndex", checked ? index : null);

                  if (checked) {
                    form.values.vendors.map((_, i) =>
                      form.setValue(
                        `vendors.${i}.message`,
                        form.values.vendors[index].message
                      )
                    );
                  }
                }}
              />
            </Flex>
          )}
        </FormConsumer>
      ),
    });
  }

  columns.push({
    id: "action",
    name: "Actions",
    align: includeCustomMessage ? "start" : "center",
    width: 115,
    render: (_, index) => (
      <FormConsumer>
        {(form) => (
          <Button
            size="sm"
            color="neutral"
            variant="ghost"
            disabled={form.isSubmitting}
            onClick={() => {
              const lastElement = form.values.vendors.length === 1;
              form.remove("vendors", index);
              if (lastElement) dialogHide();
            }}
            aria-label="Remove email"
            data-testid="ach-email-remove-button"
          >
            Remove
          </Button>
        )}
      </FormConsumer>
    ),
  });

  return columns;
};

export const AchRequestDialog = ({
  dialog,
  multiple,
  onSubmit,
  initialValues = {},
}: AchRequestDialogProps) => {
  const { checkTwoFactorAuth } = useTwoFactorAuth();
  const { user } = useUserInfo();

  const enhancedInitialValues = useMemo(
    () => ({
      vendors: initialValues.vendors?.forEach((e) => (e.message = "")) || [],

      applyMessageIndex: null,
      includeCustomMessage: false,
      ccRequestingUser: false,
      ...initialValues,
    }),
    [initialValues]
  );

  const form = useForm<Fields>({
    /**
     * @todo figure out how to adjust this type issue without casting it
     */
    schema: setEmailSchema as unknown as AnyZodObject,
    async onSubmit({ vendors, ccRequestingUser }) {
      checkTwoFactorAuth(async () => {
        const requests = vendors.map(async (vendor) => {
          try {
            await postVendorAchRequest({
              vendor_id: vendor.id,
              vendor_email: vendor.email,
              message: vendor.message,
              bill_id: vendor.billId ?? null,
              cc_requesting_user: ccRequestingUser,
            });
          } catch (error) {
            throw transformErrorToCustomError({
              error,
              extra: { displayName: vendor.displayName },
              render: (message) => `${message} on the following vendors:`,
            });
          }
        });

        const { success, errorResponses } = summarizeResults(
          await Promise.allSettled(requests)
        );

        const enhancedErrors = parseCustomErrors<{ displayName: string }>({
          errors: errorResponses,
          render: ({ isFirst, message, displayName }) =>
            `${message}${isFirst ? "" : ","} ${displayName}`,
        });

        if (enhancedErrors.length) {
          enhancedErrors.forEach((error) =>
            handleErrors(error, { maxWidth: 800, truncate: 2 })
          );
        }

        if (success) {
          toast.success(`ACH request${success > 1 ? "s" : ""} sent!`);
        }

        onSubmit?.();

        await dialog.hide();
      });
    },
    initialValues: enhancedInitialValues,
  });

  const onChangeIncludeCustomMessage = useEvent((checked: boolean) => {
    if (checked) return;

    form.values.vendors.map((_, index) =>
      form.setValue(`vendors.${index}.message`, "")
    );
    form.setValue(`applyMessageIndex`, null);
  });

  const data = useDeepMemo(() => form.values.vendors, [form.values.vendors]);

  const dialogHide = dialog.hide;

  const columns = useMemo(
    () => getColumns(form.values.includeCustomMessage, dialogHide),
    [form.values.includeCustomMessage, dialogHide]
  );

  return (
    <>
      {multiple ? (
        <Dialog
          size="auto"
          show={dialog.isVisible}
          variant="dialog"
          onClose={dialog.hide}
        >
          <DialogHeader>
            <Flex direction="column">
              <Text size="xl" weight="bold">
                {STRINGS.REQUEST_DIALOG_HEADER_MULTIPLE}
              </Text>
              <Text size="md">{STRINGS.REQUEST_DIALOG_CONTENT_MULTIPLE}</Text>
            </Flex>
          </DialogHeader>
          <DialogContent>
            <Flex
              as="form"
              gap="2xl"
              minWidth={form.values.includeCustomMessage ? "920px" : "650px"}
              justify="center"
              direction="column"
              {...form.props}
            >
              <Flex direction="row" gap="2xl">
                <Checkbox
                  label="Include a custom message"
                  {...form.register({
                    name: "includeCustomMessage",
                    type: "boolean",
                    onChange: onChangeIncludeCustomMessage,
                  })}
                />
                <Checkbox
                  label={`cc: ${user.email}`}
                  {...form.register({
                    name: "ccRequestingUser",
                    type: "boolean",
                  })}
                />
              </Flex>
              <FormProvider form={form}>
                <Table
                  id="ach-request-dialog-table"
                  size="sm"
                  data={data}
                  columns={columns}
                  maxHeight="500px"
                />
              </FormProvider>
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Flex width="full">
              <Button
                block
                color="neutral"
                variant="text"
                onClick={dialog.hide}
              >
                Cancel
              </Button>
            </Flex>
            <Tooltip
              as={Flex}
              width="full"
              message={
                !form.isValid ? "Email is required to send ACH requests" : ""
              }
              placement="left"
            >
              <Button
                type="submit"
                form={form.id}
                block
                disabled={form.isSubmitting || !form.isValid}
              >
                {form.isSubmitting ? <Loader /> : STRINGS.REQUEST_ACH_LABEL}
              </Button>
            </Tooltip>
          </DialogFooter>
        </Dialog>
      ) : (
        <Dialog
          size="auto"
          show={dialog.isVisible}
          variant="dialog"
          onClose={dialog.hide}
        >
          <DialogHeader>
            <Flex direction="column">
              <Text size="xl" weight="bold">
                {STRINGS.REQUEST_DIALOG_HEADER_SINGLE}
              </Text>
              <Text size="md">{form.values.vendors?.[0]?.displayName}</Text>
            </Flex>
          </DialogHeader>
          <DialogContent>
            <Flex
              as="form"
              gap="lg"
              justify="center"
              minWidth="480px"
              direction="column"
              {...form.props}
            >
              <Flex direction="column" gap="md">
                <TextField
                  type="email"
                  label="Email address"
                  required
                  placeholder="Enter the vendor's email address"
                  messageVariant="hidden"
                  {...form.register(`vendors.0.email`)}
                />
                <Checkbox
                  label={`cc: ${user.email}`}
                  {...form.register({
                    name: "ccRequestingUser",
                    type: "boolean",
                  })}
                />
              </Flex>
              <Textarea
                label="Message"
                minHeight={100}
                maxHeight={300}
                placeholder="Optional message included in the request email"
                messageVariant="hidden"
                {...form.register(`vendors.0.message`)}
              />
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Flex width="full">
              <Button
                block
                color="neutral"
                variant="text"
                onClick={dialog.hide}
              >
                Cancel
              </Button>
            </Flex>
            <Tooltip
              as={Flex}
              width="full"
              message={
                !form.isValid ? "Email is required to send ACH request" : ""
              }
              placement="left"
            >
              <Button
                type="submit"
                form={form.id}
                block
                disabled={form.isSubmitting || !form.isValid}
              >
                {form.isSubmitting ? <Loader /> : STRINGS.REQUEST_ACH_LABEL}
              </Button>
            </Tooltip>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
};
