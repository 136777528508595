import { api, getApi, getUrlBuilder } from "@utils/api";

import { type VENDOR_REQUEST_TYPES } from "../../public/components/vendor-request/constants";

const apiV2 = getApi();
const urlBuilderV2 = getUrlBuilder("/api/vendor-comms/vendorpublicrequest/");

type Payload = OutgoingDocumentPayload | { requested_document_id: string };

import { transformKeysToSnakeCase } from "@utils/schema/converters";

import type { OutgoingDocumentPayload } from "./vendors/types";

type PostVendorAchRequestProps = {
  bill_id: number | null;
  vendor_id: number | string;
  vendor_email: string;
  message?: string | null;
  cc_requesting_user?: boolean;
};

type DeleteVendorAchRequestProps = {
  request_id?: number | string;
  vendor_id: number | string;
};

type PostVendorDocRequestProps = {
  document_types: string[];
  vendor_id: number | string;
  vendor_email: string;
  message?: string | null;
};

type DeleteVendorDocRequestProps = {
  request_id?: number | string;
  vendor_id: number | string;
};

type PostVendorPoRequestProps = {
  purchase_order_id: number | string;
  vendor_id: number | string;
  vendor_email: string;
  message?: string | null;
};

type PostVendorPoRequestPropsV2 = {
  purchaseOrderId: number | string;
  vendorId: number | string;
  vendorEmail: string;
  message?: string | null;
  files?: File[];
};

type DeleteVendorPoRequest = {
  request_id?: number | string;
  vendor_id: number | string;
};

type PostVendorPublicAchRequestProps = {
  account_number: string;
  routing_number: string;
  vendor: string;
};

type PostVendorPublicPOSignatureRequestProps = {
  signature: string;
};

export type VendorPublicRequestBaseParams = {
  realmUid: string;
  vendorUid: string;
  token: string;
};

export type VendorPublicRequestParams = {
  realmUid: string;
  vendorUid: string;
  token: string;
  type: keyof typeof VENDOR_REQUEST_TYPES;
};

export type RequestResponse = Request;

export const postVendorAchRequest = (params: PostVendorAchRequestProps) => {
  return api.post("/api/vendorrequests/ach/", {
    ...params,
  });
};

export const deleteVendorAchRequest = (params: DeleteVendorAchRequestProps) => {
  const { request_id, ...data } = params;
  return api.delete(`/api/vendorrequests/ach/?request_id=${request_id}`, {
    data,
  });
};

export const postVendorDocRequest = (params: PostVendorDocRequestProps) => {
  return api.post("/api/vendorrequests/document/", {
    ...params,
  });
};

export const deleteVendorDocRequest = (params: DeleteVendorDocRequestProps) => {
  const { request_id, ...data } = params;
  return api.delete(`/api/vendorrequests/document/?request_id=${request_id}`, {
    data,
  });
};

export const postVendorPoRequest = (params: PostVendorPoRequestProps) => {
  return api.post("/api/vendorrequests/po_signature/", params);
};

export const postVendorPoRequestV2 = ({
  files,
  ...params
}: PostVendorPoRequestPropsV2) => {
  const payload = new FormData();
  Object.entries(transformKeysToSnakeCase(params)).forEach(([key, value]) => {
    payload.append(key, String(value));
  });
  files?.forEach((file) => {
    payload.append("files", file);
  });
  const config = { headers: { "content-type": "multipart/form-data" } };
  return api.post<{
    purchase_order_id: string;
    vendor_id: string;
    vendor_email: string;
    message: string;
    files: { id: string; file: string; name: string }[];
  }>("/api/vendorrequests/po_signature/", payload, config);
};

export const deleteVendorPoRequest = (params: DeleteVendorPoRequest) => {
  const { request_id, ...data } = params;
  return api.delete(
    `/api/vendorrequests/po_signature/?request_id=${request_id}`,
    {
      data,
    }
  );
};

const transformParams = (queryParams: VendorPublicRequestParams) => {
  return {
    realm_unique_id: queryParams.realmUid,
    vendor_unique_id: queryParams.vendorUid,
    ...queryParams,
  };
};

export const getVendorPublicRequest = (
  queryParams: VendorPublicRequestParams
) => {
  const params = transformParams(queryParams);
  return api
    .get(`/api/vendor-comms/vendorpublicrequest/`, { params })
    .then((response) => response.data);
};

export const postVendorPublicAchRequest = (
  queryParams: VendorPublicRequestParams,
  data: PostVendorPublicAchRequestProps
) => {
  const params = transformParams(queryParams);
  return apiV2.post(urlBuilderV2.forCollection(), data, { params });
};

export const postVendorPublicDocRequest = (
  params: VendorPublicRequestParams,
  formData: Payload
) => {
  const config = {
    params: transformParams(params),
    headers: { "content-type": "multipart/form-data" },
  };
  return apiV2.post(urlBuilderV2.forCollection(), formData, config);
};

export const putVendorPublicDocRequest = (
  params: VendorPublicRequestParams,
  formData: Payload
) => {
  return apiV2.put(urlBuilderV2.forCollection(), formData, {
    params: transformParams(params),
  });
};

export const postVendorPublicPOSignatureRequest = (
  queryParams: VendorPublicRequestParams,
  data: PostVendorPublicPOSignatureRequestProps
) => {
  const params = transformParams(queryParams);
  return apiV2.post(urlBuilderV2.forCollection(), data, { params });
};
