import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  type ReactNode,
  useEffect,
  useRef,
} from "react";
import {
  CompositeProvider,
  Menu,
  MenuButton,
  MenuProvider,
  useMenuContext,
  useStoreState,
} from "@ariakit/react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { mergeRefs } from "../../utils";
import { useComboboxContext } from "../combobox/combobox-context";
import { useDialogContext } from "../dialog/dialog-context";
import { Flex } from "../flex";
import { Icon } from "../icon";

import { useDropdown } from "./dropdown-context";
import { DropdownItem, type DropdownItemProps } from "./dropdown-item";
import styles from "./dropdown.module.css";

type DefaultComponent = "div";

type Props = ComponentPropsWithoutRef<DefaultComponent> &
  Omit<DropdownItemProps, "onClick"> & { label?: ReactNode };

export type DropdownListRef = ComponentRef<DefaultComponent>;

const ITEM_HEIGHT = 48;

export const DropdownList = forwardRef<DropdownListRef, Props>(
  ({ icon, label, color, style, children, className, ...props }, ref) => {
    const { hasHeader, hasFooter, hasContent } = useDialogContext();

    const isInsideDialog = hasHeader || hasFooter || hasContent;

    const isInsideCombobox = useComboboxContext();

    const internalRef = useRef<DropdownListRef>(null);

    const menuContext = useMenuContext()!;

    const { flip, maxWidth, trigger, listSize } = useDropdown() ?? {};

    const open = useStoreState(menuContext, "open");

    const maxHeightList = listSize
      ? ITEM_HEIGHT * listSize + ITEM_HEIGHT / 2
      : undefined;

    /**
     * This prevent to hide menu if you close ComboBox pressing "Escape"
     */
    const hideOnEscape = useEvent(() =>
      document.activeElement
        ? document.activeElement.tagName.toLowerCase() !== "input"
        : true
    );

    useEffect(() => {
      if (open && internalRef.current) {
        internalRef.current.scrollTop = 0;
      }
    }, [open]);

    if (label) {
      return (
        <MenuProvider focusShift focusWrap={false} focusLoop="vertical">
          <MenuButton
            render={(props) => (
              <CompositeProvider store={menuContext.parent ?? menuContext}>
                <DropdownItem
                  {...props}
                  icon={icon}
                  color={color}
                  hideOnClick={false}
                />
              </CompositeProvider>
            )}
            role={undefined}
          >
            <Flex align="center" width="full" gap="md" justify="space-between">
              {label}
              <Icon name="chevron-right" />
            </Flex>
          </MenuButton>
          <Menu
            portal
            className={cn(className, styles["dropdown-list"], {
              [styles["-dialog"]]: isInsideDialog,
              [styles["-combobox"]]: isInsideCombobox,
            })}
            style={{
              ...(maxWidth
                ? { "--dropdown-list-max-width": `${maxWidth}px` }
                : {}),
              ...(maxHeightList
                ? { "--dropdown-list-max-height": `${maxHeightList}px` }
                : {}),
              ...style,
            }}
            role="grid"
            unmountOnHide
            hideOnHoverOutside={false}
            {...props}
          >
            <CompositeProvider store={menuContext.parent ?? menuContext}>
              {children}
            </CompositeProvider>
          </Menu>
        </MenuProvider>
      );
    }

    return (
      <Menu
        ref={mergeRefs(ref, internalRef)} // eslint-disable-line
        portal
        flip={flip}
        gutter={trigger === "click" ? 8 : 0}
        className={cn(className, styles["dropdown-list"], {
          [styles["-dialog"]]: isInsideDialog,
          [styles["-combobox"]]: isInsideCombobox,
        })}
        role="grid"
        style={{
          ...(maxWidth ? { "--dropdown-list-max-width": `${maxWidth}px` } : {}),
          ...(maxHeightList
            ? { "--dropdown-list-max-height": `${maxHeightList}px` }
            : {}),
          ...style,
        }}
        unmountOnHide
        hideOnEscape={hideOnEscape}
        hideOnHoverOutside={trigger === "hover"}
        {...props}
      >
        {children}
      </Menu>
    );
  }
);

DropdownList.displayName = "DropdownList";
