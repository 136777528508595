import React, {
  type ComponentProps,
  type FocusEventHandler,
  forwardRef,
  type PropsWithChildren,
  type ReactNode,
  useEffect,
  useId,
} from "react";
import {
  Combobox,
  ComboboxGroup,
  ComboboxGroupLabel,
  ComboboxItem,
  ComboboxList,
  ComboboxProvider,
} from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { useEvent } from "../../hooks/use-event";
import { Dialog } from "../dialog";
import { EmptyState } from "../empty-state";
import { Icon } from "../icon";
import { Loader } from "../loader";
import { TextField } from "../text-field";

import styles from "./command.module.css";

const DEFAULT_COMMAND_ITEM_COMPONENT = "button";

export const CommandItem = forwardRefAs<typeof DEFAULT_COMMAND_ITEM_COMPONENT>(
  (
    { as: Component = DEFAULT_COMMAND_ITEM_COMPONENT, className, ...props },
    ref
  ) => (
    <ComboboxItem
      render={
        <Component
          className={cn(styles["command-item"], className)}
          ref={ref}
          {...props}
        />
      }
      focusable={false}
      focusOnHover
    />
  )
);

export type CommandGroupProps = PropsWithChildren<{ label: ReactNode }>;

export const CommandGroup = forwardRef<HTMLDivElement, CommandGroupProps>(
  ({ label, children, ...props }, ref) => (
    <ComboboxGroup ref={ref} className={styles["command-group"]} {...props}>
      <ComboboxGroupLabel className={styles["command-group-label"]}>
        {label}
      </ComboboxGroupLabel>
      {children}
    </ComboboxGroup>
  )
);

CommandGroup.displayName = "CommandGroup";

export type CommandProps = PropsWithChildren<{
  show?: boolean;
  value: string;
  onClose: () => void;
  loading?: boolean;
  onChange: (value: string) => void;
  emptyState?: ComponentProps<typeof EmptyState>;
}>;

export const Command = ({
  show,
  value,
  onClose,
  loading,
  children,
  onChange,
  emptyState,
}: CommandProps) => {
  const internalId = useId();

  const onBlur = useEvent<FocusEventHandler<HTMLInputElement>>((e) => {
    requestAnimationFrame(() => e.target.focus());
  });

  useEffect(() => {
    if (!show) onChange?.("");
  }, [onChange, show]);

  return (
    <Dialog variant="dialog" show={show} onClose={onClose} placement="top">
      <div className={styles["command"]}>
        <ComboboxProvider
          open
          value={value}
          setValue={onChange}
          includesBaseElement={false}
        >
          <Combobox
            render={({ onChange, ...comboboxProps }) => (
              <TextField
                onBlur={onBlur}
                suffix={loading ? <Loader /> : <Icon name="search" />}
                onInput={onChange}
                autoFocus
                className={styles["command-input"]}
                placeholder="Type here to search for anything"
                messageVariant="hidden"
                triggerChangeOnFocusedUnmount={false}
                {...comboboxProps}
                autoComplete={`${internalId}-off`}
              />
            )}
            autoSelect
          />
          <div className={styles["command-content"]}>
            {loading ? null : children ? (
              <ComboboxList alwaysVisible>{children}</ComboboxList>
            ) : value && emptyState ? (
              <EmptyState
                className={styles["command-empty-state"]}
                {...emptyState}
              />
            ) : null}
          </div>
        </ComboboxProvider>
      </div>
    </Dialog>
  );
};
