import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Button,
  ComboBox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Loader,
  Table,
  toast,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useDialog,
  useEvent,
  useForm,
} from "@adaptive/design-system/hooks";
import { useMultiStepDialog } from "@adaptive/design-system/hooks";
import { createLinkTokenForExistingBank } from "@api/bank-accounts";
import { ConfirmationDialog } from "@components/confirmation-dialog";
import {
  destroyBankAccount,
  getBankAccounts,
  getBankAccountsPoll,
  patchAccountBalance,
  patchAccountBalances,
} from "@shared/api/bank-accounts";
import logo from "@shared/assets/images/default-bank-logo.png";
import {
  Main,
  MainContent,
  MainHeader,
  MainSubtitle,
  MainTitle,
} from "@shared/components/main";
import { PlaidLink } from "@shared/components/PlaidLink";
import { useUsersSimplified } from "@shared/hooks/useUsersSimplified";
import { api } from "@shared/utils/api";
import { z } from "zod";

import {
  AccountSelectDialog,
  MultiAccountSelectDialog,
} from "../account-select-dialog";

import { InstantAuthColumns } from "./table/instant-auth-verified-columns";
import { SameDayManualVerificationBankColumns } from "./table/manually-verified-columns";
import {
  BANK_ALREADY_EXISTS_ERROR,
  BANK_SYNC_STATUS,
  DELETE_BANK_ACCOUNT_ERROR,
} from "./constants";

const userSelectSchema = z.object({
  user: z.string().min(1, "Please select a user"),
});

export const BankAccounts = () => {
  const currentClient = useSelector((state) => state.user.currentClient);
  const [isLoading, setIsLoading] = useState(false);
  const [bankAccountsLoading, setBankAccountsLoading] = useState(false);
  const [isBankSyncing, setIsBankSyncing] = useState(false);
  const [linkToken, setLinkToken] = useState();
  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedAccountBalance, setSelectedAccountBalance] = useState(null);
  const [deletedBank, setDeletedBank] = useState(null);
  const [unlinkedAccountBalances, setUnlinkedAccountBalances] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [onCreationErrorMessage, setOnCreationErrorMessage] = useState(null);
  const cleanUpPlaidLink = () => {
    setLinkToken(null);
    setSelectedBankId(null);
    setIsLoading(false);
  };

  const errorMessageDialog = useDialog();
  const confirmationRemoveBankDialog = useDialog();

  const accountSelectDialog = useMultiStepDialog({
    initialStep: "set-account",
  });

  const userSelectDialog = useDialog();

  const initialValues = useDeepMemo(
    () => ({
      user: selectedAccountBalance?.users?.[0]?.url || "",
    }),
    [selectedAccountBalance]
  );

  const { reset: userSelectFormReset, ...userSelectForm } = useForm({
    schema: userSelectSchema,
    async onSubmit(values) {
      await patchAccountBalance({
        account: selectedAccountBalance,
        users: [values.user],
      });
      userSelectDialog.hide();
      loadBankAccounts();
      toast.success("Bank updated!");
    },
    initialValues,
  });

  useEffect(() => {
    userSelectFormReset();
  }, [userSelectFormReset, selectedAccountBalance]);

  const multiAccountSelectDialog = useMultiStepDialog({
    initialStep: "set-account",
  });

  const multiAccountSelectDialogShow = multiAccountSelectDialog.show;

  const onQBConnect = useCallback(
    (account) => {
      setSelectedAccountBalance(account);
      accountSelectDialog.show();
    },
    [accountSelectDialog]
  );

  const { data: users } = useUsersSimplified();

  const showMultiAccountSelectDialog = useCallback(
    (accounts) => {
      setUnlinkedAccountBalances(accounts);
      multiAccountSelectDialogShow();
    },
    [multiAccountSelectDialogShow]
  );

  const onConnectBank = useEvent(async () => {
    setIsLoading(true);

    try {
      const { data } = await api.post("/api/banking/create_link_token/");
      setLinkToken(data.link_token);
    } catch (e) {
      toast.error("Cannot connect bank");
    }
  });

  const onManageBank = useEvent(async (bankId) => {
    setIsLoading(true);

    try {
      const { data } = await api.post(
        `/api/banking/create_link_token/${bankId}/`
      );
      setLinkToken(data.link_token);
      setSelectedBankId(bankId);
    } catch (e) {
      toast.error(
        "Error updating bank - try again later or email support@adaptive.build for help"
      );
    }
  });

  const onVerifyBank = useEvent(async (bankId) => {
    setIsLoading(true);
    try {
      const { data } = await createLinkTokenForExistingBank(bankId);
      setLinkToken(data.link_token);
      setSelectedBankId(bankId);
    } catch (e) {
      toast.error("Cannot update bank");
    } finally {
      setIsLoading(false);
    }
  });

  const getUnlinkedAccountBalances = (banks) =>
    banks.flatMap((client) =>
      client.bank_accounts.flatMap((bankAccount) =>
        bankAccount.account_balances.filter(
          (account) => !account?.payment_account?.url
        )
      )
    );

  const linkToQBAccount = useCallback(
    (unlinkedAccountBalances) => {
      if (unlinkedAccountBalances.length > 0) {
        showMultiAccountSelectDialog(unlinkedAccountBalances);
      }
    },
    [showMultiAccountSelectDialog]
  );

  // Use effect to poll backend to get bank accounts after they are updated asynchronously in the db
  useEffect(() => {
    if (currentClient) {
      // @todo: Refactor this to use a polling hook
      let pollingCount = 0;
      const stopBankingPoll = getBankAccountsPoll({
        client: currentClient.id,
        refresh_balance: false,
        onSuccess({ data, stopPoll }) {
          let isSyncing = false;
          if (!isBankSyncing) {
            stopPoll();
            const unlinkedAccountBalances =
              getUnlinkedAccountBalances(bankAccounts);
            if (unlinkedAccountBalances && !deletedBank) {
              linkToQBAccount(unlinkedAccountBalances);
            }
          } else {
            let banks = data.results.map((client) => {
              client.bank_accounts.map((bankAccount) => {
                if (bankAccount.sync_status == BANK_SYNC_STATUS.PROGRESS) {
                  isSyncing = true;
                }
                return bankAccount;
              });
              return client;
            });
            if (!isSyncing) {
              setIsBankSyncing(false);
            }
            setBankAccounts(banks);
          }
          pollingCount++;
          if (pollingCount >= 5) {
            setIsBankSyncing(false);
          }
        },
      });
      return () => stopBankingPoll();
    }
    /**
     * @todo Enable this rule and fix useEffect dependencies
     */
  }, [currentClient, isBankSyncing]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadBankAccounts = useCallback(() => {
    if (currentClient) {
      getBankAccounts({
        client: currentClient.id,
        refresh_balance: false,
      }).then((response) => {
        return setBankAccounts(response.results);
      });
    }
  }, [currentClient]);

  useEffect(() => {
    loadBankAccounts();
  }, [
    currentClient,
    loadBankAccounts,
    accountSelectDialog.isVisible,
    multiAccountSelectDialog.isVisible,
    confirmationRemoveBankDialog.isVisible,
  ]);

  useEffect(() => {
    if (deletedBank && !isBankSyncing && bankAccounts.length > 0) {
      const banks = bankAccounts.flatMap((client) => client.bank_accounts);
      const deletedBankExists = banks.find((bank) => bank.id == deletedBank);
      if (deletedBankExists) {
        toast.error(DELETE_BANK_ACCOUNT_ERROR);
      } else {
        toast.success("Bank removed!");
      }
      setDeletedBank(null);
    }
  }, [deletedBank, bankAccounts, isBankSyncing]);

  const onDelete = useCallback(
    (bankId) => {
      confirmationRemoveBankDialog.show();
      setSelectedBankId(bankId);
    },
    [confirmationRemoveBankDialog]
  );

  const removeBankAccount = (bankId) => {
    setDeletedBank(bankId);
    destroyBankAccount(bankId, { client: currentClient.id });
    setIsBankSyncing(true);
    confirmationRemoveBankDialog.hide();
    setSelectedBankId(null);
  };

  const columns = useMemo(
    () => [
      {
        id: "logo",
        width: 72,
        render: () => <Avatar src={logo} size="lg" />,
      },
      {
        id: "bank",
        width: "fill",
        render: (row) => {
          const Row =
            row.account_balances.length === 1 &&
            ["manually_verified", "pending_manual_verification"].includes(
              row.account_balances[0].verification_status
            )
              ? SameDayManualVerificationBankColumns
              : InstantAuthColumns;
          return (
            <Row
              row={row}
              onDelete={onDelete}
              onManageBank={onManageBank}
              onVerifyBank={onVerifyBank}
              onQBConnect={onQBConnect}
            />
          );
        },
      },
    ],
    [onDelete, onManageBank, onQBConnect, onVerifyBank]
  );

  const onCloseErrorDialog = useEvent(() => {
    errorMessageDialog.hide();
    setOnCreationErrorMessage(null);
  });

  const ErrorMessageDialog = () => {
    return (
      <Dialog
        show={
          onCreationErrorMessage?.message == BANK_ALREADY_EXISTS_ERROR &&
          errorMessageDialog.isVisible
        }
        variant="confirmation"
        onClose={onCloseErrorDialog}
      >
        <DialogHeader>
          This bank ({onCreationErrorMessage?.bank_name}) is already connected
        </DialogHeader>
        <DialogFooter>
          <Button
            block
            variant="text"
            color="neutral"
            onClick={onCloseErrorDialog}
          >
            Cancel
          </Button>
          <Button
            block
            onClick={() => {
              errorMessageDialog.hide();
              onManageBank(onCreationErrorMessage?.bank_id);
              setOnCreationErrorMessage(null);
            }}
          >
            Manage accounts
          </Button>
        </DialogFooter>
      </Dialog>
    );
  };

  return (
    <Main>
      {linkToken && (
        <PlaidLink
          accountType="depository"
          linkToken={linkToken}
          currentClient={currentClient}
          onCreationSuccess={() => {
            setIsBankSyncing(true);
            cleanUpPlaidLink();
            if (currentClient) {
              setBankAccountsLoading(true);
              getBankAccounts({
                client: currentClient.id,
                refresh_balance: false,
              })
                .then((response) => {
                  return setBankAccounts(response.results);
                })
                .finally(() => {
                  setBankAccountsLoading(false);
                });
            }
          }}
          onCreationError={(errorMessage) => {
            cleanUpPlaidLink();
            setOnCreationErrorMessage(errorMessage);
            errorMessageDialog.show();
          }}
          onLinkExit={() => {
            cleanUpPlaidLink();
          }}
          selectedBankId={selectedBankId}
        />
      )}
      <ConfirmationDialog
        title="Remove bank account?"
        message="You won't be able to make payments from this account if you remove it from Adaptive."
        confirmButtonColor="error"
        confirmButtonText="Remove bank account"
        dialog={confirmationRemoveBankDialog}
        onConfirm={() => removeBankAccount(selectedBankId)}
      />
      <ErrorMessageDialog />
      <Dialog show={userSelectDialog.isVisible}>
        <DialogHeader>Link to user</DialogHeader>
        <DialogContent>
          <Flex as="form" {...userSelectForm.props}>
            <ComboBox
              label="Select user"
              data={users}
              {...userSelectForm.register("user")}
            />
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Button
            block
            color="neutral"
            variant="text"
            onClick={userSelectDialog.hide}
          >
            Close
          </Button>
          <Button
            block
            type="submit"
            form={userSelectForm.id}
            disabled={userSelectForm.isSubmitting}
          >
            Save
          </Button>
        </DialogFooter>
      </Dialog>
      <AccountSelectDialog
        dialog={accountSelectDialog}
        selectHeader={`Connect your bank account (${selectedAccountBalance?.name} ••••${selectedAccountBalance?.mask}) to QuickBooks`}
        putObject={(values) => {
          patchAccountBalance({ account: selectedAccountBalance, ...values });
        }}
        isBankAccount={true}
        selectedAccount={selectedAccountBalance?.payment_account}
      />
      <MultiAccountSelectDialog
        dialog={multiAccountSelectDialog}
        selectHeader={`Let's connect your bank accounts to QuickBooks`}
        putObjects={(values) => {
          patchAccountBalances(values);
        }}
        isBankAccount={true}
        unlinkedAccountBalances={unlinkedAccountBalances}
      />
      <MainHeader>
        <Flex align="center" height="full" gap="xl">
          <Flex direction="column" grow>
            <MainTitle>Bank accounts</MainTitle>
            <MainSubtitle>
              Manage the bank accounts you use to make payments to your subs and
              vendors
            </MainSubtitle>
          </Flex>
          <Button onClick={onConnectBank} disabled={isLoading}>
            Connect a bank account
          </Button>
        </Flex>
      </MainHeader>

      <MainContent>
        {(isLoading || isBankSyncing) && <Loader position="fixed" />}

        <Table
          id="bank-accounts-table"
          size="sm"
          data={bankAccounts?.[0]?.bank_accounts?.filter(
            (bankAccount) => bankAccount.type === "depository"
          )}
          header={{ hide: true }}
          loading={bankAccountsLoading}
          columns={columns}
          bordered={false}
          emptyState={{
            title: "You don't have any bank accounts yet",
            subtitle: "Create your first bank account.",
            action: {
              children: "Connect bank",
              onClick: onConnectBank,
            },
          }}
        />
      </MainContent>
    </Main>
  );
};
