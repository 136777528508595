import React, { type PropsWithChildren } from "react";
import { TabProvider } from "@ariakit/react";

import { useEvent } from "../../hooks/use-event";

export type TabsProps = PropsWithChildren<{
  value?: string;
  onChange?: (value: string) => void;
}>;

export const Tabs = ({ value, children, onChange }: TabsProps) => {
  const setSelectedId = useEvent((value?: string | null) =>
    onChange?.(value || "")
  );

  return (
    <TabProvider
      activeId={value}
      selectedId={value}
      setSelectedId={setSelectedId}
    >
      {children}
    </TabProvider>
  );
};
