import React from "react";
import { type TableColumn, Text } from "@adaptive/design-system";
import type { Change } from "@api/jobs/changes";

import {
  ActionColumn,
  ChangeAmountColumn,
  ChangeAmountFooter,
  ChangeRevisedAmountColumn,
  ChangeRevisedAmountFooter,
  CostCodeAccountsColumn,
  DateColumn,
  DescriptionColumn,
} from "./changes-dialog-components";

export const getChangeColumns = ({
  changeTrackingEnabled,
  externalBudgetEnabled,
}: {
  changeTrackingEnabled: boolean;
  externalBudgetEnabled: boolean;
}): TableColumn<Change>[] => [
  {
    id: "doc_number",
    name: "Ref #",
    render: (row) => <Text truncate>{row.docNumber}</Text>,
    footer: "Total",
    width: 200,
    sortable: true,
  },
  {
    id: "costCodesAccounts",
    name: "Cost codes / Accounts",
    render: (row) => <CostCodeAccountsColumn {...row} />,
  },
  {
    id: "description",
    name: "Description",
    width: "fill",
    sortable: "asc",
    render: (row) => <DescriptionColumn {...row} />,
  },
  {
    id: "total_builder_amount",
    name: "Cost budget",
    minWidth: 130,
    sortable: true,
    render: (row) => <ChangeAmountColumn {...row} />,
    footer: () => <ChangeAmountFooter />,
  },
  ...(changeTrackingEnabled && externalBudgetEnabled
    ? ([
        {
          id: "total_external_amount",
          name: "External budget",
          minWidth: 160,
          sortable: true,
          render: (row) => <ChangeRevisedAmountColumn {...row} />,
          footer: () => <ChangeRevisedAmountFooter />,
        },
      ] as TableColumn<Change>[])
    : []),
  {
    id: "date",
    name: "Date",
    width: 120,
    sortable: true,
    render: (row) => <DateColumn {...row} />,
  },
  {
    id: "actions",
    name: "Actions",
    width: 110,
    render: (row) => <ActionColumn {...row} />,
  },
];
