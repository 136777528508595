import React, { useCallback, useMemo, useState } from "react";
import { Link as ReactRouterLink } from "react-router";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  ComboBox,
  Dialog,
  dialog,
  Flex,
  Icon,
  Link,
  Loader,
  Table,
  type TableColumn,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { dotObject, suffixify } from "@adaptive/design-system/utils";
import {
  type AccountBalanceV2,
  type BankAccountV2,
  destroyAccountBalance,
  patchAccountBalance,
  type PatchAccountBalanceProps,
  patchPlaidAccountOwner,
  type PatchPlaidAccountOwnerProps,
  type PlaidAccountOwnerV2,
} from "@api/bank-accounts";
import type { FidelCard, UpdateFidelCardPayload } from "@api/fidel-cards";
import { handleErrors } from "@api/handle-errors";
import { LinkedCard } from "@components/linked-card/linked-card";
import {
  Main,
  MainContent,
  MainHeader,
  MainSubtitle,
  MainTitle,
} from "@components/main";
import { PlaidLink } from "@components/PlaidLink";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import { useBankAccountsV2 } from "@hooks/use-bank-accounts";
import { useFidelCards } from "@hooks/use-fidel-cards";
import { useUsersSimplified } from "@hooks/useUsersSimplified";
import { useClientAction, useClientInfo } from "@store/user";
import * as analytics from "@utils/analytics";
import { api } from "@utils/api";
import { transformKeysToSnakeCase } from "@utils/schema/converters";

import { AccountCreateForm } from "../account-select-dialog/account-create-form";

type PlaidRow = AccountBalanceV2 | BankAccountV2 | PlaidAccountOwnerV2;

type CurriedOnChangeAccountHandler = (props: {
  row: PlaidRow;
  field: "user" | "payment-account";
  method: "bank" | "plaid";
}) => (value: string) => Promise<void>;

type CurriedOnChangeFidelCard = (props: {
  row: FidelCard;
  field: "user" | "payment-account";
}) => (value: string) => Promise<void>;

const PaymentAccountName = () => (
  <Flex gap="sm">
    <Text weight="bold">Payment account</Text>
    <Tooltip
      size="sm"
      as={Icon}
      name="info-circle"
      message={`Only transactions with an assigned payment\n account will be pulled into Adaptive`}
    />
  </Flex>
);

export const Cards = () => {
  const { client } = useClientInfo();

  const { reloadClients } = useClientAction();

  const cardFeedEnabled = !!client?.settings?.card_feed_enabled;

  const accountCreateDialog = useDialog({ lazy: true });

  const { show: showAccountCreateDialog } = accountCreateDialog;

  const [isLoading, setIsLoading] = useState(false);

  const [linkToken, setLinkToken] = useState<string>("");

  const [selectedRow, setSelectedRow] = useState<PlaidRow | FidelCard>();

  const [selectedBankId, setSelectedBankId] = useState("");

  const { data: users, status: usersStatus } = useUsersSimplified({
    filters: { is_staff: false },
  });

  const { refetch: refetchAccounts, ...accounts } = useAccountsSimplified({
    filters: {
      only_payment_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
  });

  const {
    data: bankAccounts,
    refetch: bankAccountsRefetch,
    isLoading: bankAccountIsLoading,
    isPlaidLoginRequired,
  } = useBankAccountsV2({ accountType: "credit" });

  const {
    data: fidelCards,
    connect: connectFidel,
    verify: verifyFidel,
    pollCard,
    isLoading: fidelCardsIsLoading,
    updateCard,
    deleteCard,
    isLoadingSdk,
    pagination: fidelCardsPagination,
    sort: fidelCardsSort,
  } = useFidelCards();

  const enhancedIsLoading =
    isLoading || isLoadingSdk || fidelCardsIsLoading || !!linkToken;

  const onLinkExit = useEvent(() => {
    setLinkToken("");
    reloadClients();
    bankAccountsRefetch();
  });

  const launchLink = useCallback(async (bankId: string | null = null) => {
    setIsLoading(true);

    try {
      const { data } = await api.post(
        bankId
          ? `/api/banking/create_link_token/${bankId}/?type=credit`
          : "/api/banking/create_link_token/?type=credit"
      );
      setLinkToken(data.link_token);
    } catch (e) {
      toast.error("Cannot connect bank");
    }

    setIsLoading(false);
  }, []);

  const curriedOnChangeAccount = useCallback<CurriedOnChangeAccountHandler>(
    ({ row, field, method }) =>
      async (value) => {
        const payload: Record<string, unknown> = {};

        if (method === "bank") {
          payload.account = transformKeysToSnakeCase(row);
          if (field === "user") {
            payload.users = value ? [value] : [];
          } else if (field === "payment-account") {
            payload.payment_account = value || null;
          }
        } else if (method === "plaid") {
          payload.plaidAccountOwner = transformKeysToSnakeCase(row);
          if (field === "user") {
            payload.user = value || null;
          } else if (field === "payment-account") {
            payload.payment_account = value || null;
          }
        }

        try {
          await (method === "bank"
            ? patchAccountBalance(payload as PatchAccountBalanceProps)
            : patchPlaidAccountOwner(payload as PatchPlaidAccountOwnerProps));

          if (field === "user") {
            toast.success("Successfully set user!");
          } else if (field === "payment-account") {
            toast.success("Successfully set payment account!");
          }
          bankAccountsRefetch();
        } catch (e) {
          handleErrors(e);
        }
      },
    [bankAccountsRefetch]
  );

  const curriedOnChangeFidelCard = useCallback<CurriedOnChangeFidelCard>(
    ({ row, field }) =>
      async (value) => {
        const payload: UpdateFidelCardPayload = { id: row.id };

        if (field === "user") {
          payload.user = value || null;
        } else if (field === "payment-account") {
          payload.paymentAccount = value || null;
        }

        await updateCard(payload);

        if (field === "user") {
          toast.success("Successfully set user!");
        } else if (field === "payment-account") {
          toast.success("Successfully set payment account!");
        }
      },
    [updateCard]
  );

  const curriedOnDeleteFidelCard = useCallback(
    (row: FidelCard) => () => {
      const handler = async () => {
        const payload: UpdateFidelCardPayload = { id: row.id };

        try {
          await deleteCard(payload);
          toast.success("Successfully deleted!");
        } catch (e) {
          handleErrors(e);
        }
      };

      dialog.confirmation({
        title: "Remove card?",
        action: {
          primary: {
            color: "error",
            onClick: handler,
            children: "Remove card",
          },
        },
      });
    },
    [deleteCard]
  );

  const curriedOnDelete = useCallback(
    (row: PlaidRow) => () => {
      const handler = async () => {
        try {
          await destroyAccountBalance(row.id);
          bankAccountsRefetch();
          toast.success("Successfully deleted!");
        } catch (e) {
          handleErrors(e);
        }
      };

      dialog.confirmation({
        title: "Remove account?",
        action: {
          primary: {
            color: "error",
            onClick: handler,
            children: "Remove account",
          },
        },
      });
    },
    [bankAccountsRefetch]
  );

  const onConnectCard = useEvent(async () => {
    setSelectedBankId("");
    launchLink();
  });

  const curriedOnUpdate = useCallback(
    (row: PlaidRow) => () => {
      setSelectedBankId(row.id);
      launchLink(row.id);
    },
    [launchLink]
  );

  const onVerifyFidel = useEvent(async (fidelCard: FidelCard) => {
    await verifyFidel({ fidelCard });
    try {
      await pollCard({
        fidelId: fidelCard.fidelId,
        verificationStatus: "verified",
      });
      toast.success("Card verified successfully");
    } catch (e) {
      toast.error("Card not linked! Please refresh the page and try again");
    }
  });

  const columns = useMemo<TableColumn<PlaidRow>[]>(
    () => [
      {
        id: "name",
        name: "Bank name",
        width: 300,
        render: "bankName",
      },
      {
        id: "account",
        name: "Account",
        width: "fill",
        minWidth: 300,
        render: (row) => {
          const name = "name" in row ? row.name : "";
          const accountOwner = "accountOwner" in row ? row.accountOwner : "";

          return (
            <LinkedCard
              mask={"mask" in row ? row.mask : ""}
              name={accountOwner || name}
            />
          );
        },
      },
      {
        id: "user",
        name: "Card holder",
        width: 300,
        render: (row) => {
          const method = "users" in row ? "bank" : "user" in row ? "plaid" : "";

          if (!method) return null;

          const value = dotObject.get(
            row,
            method === "bank" ? "users.0.url" : "user.url",
            ""
          );

          return (
            <ComboBox
              size="sm"
              required
              onChange={curriedOnChangeAccount({ row, field: "user", method })}
              loading={usersStatus === "loading"}
              data={users}
              messageVariant="hidden"
              value={value}
              data-testid={suffixify("bank-table", "role")}
            />
          );
        },
      },
      {
        id: "payment_account",
        name: <PaymentAccountName />,
        width: 300,
        render: (row) => {
          const method =
            "accountOwner" in row
              ? "plaid"
              : "paymentAccount" in row
                ? "bank"
                : "";

          if (!method) return null;

          return (
            <ComboBox
              size="sm"
              required
              action={{
                icon: "plus",
                onClick: () => {
                  setSelectedRow(row);
                  showAccountCreateDialog();
                },
                children: "Add new",
              }}
              onChange={curriedOnChangeAccount({
                row,
                field: "payment-account",
                method,
              })}
              data={accounts.data}
              loading={accounts.status === "loading"}
              messageVariant="hidden"
              value={dotObject.get(row, "paymentAccount.url", "")}
              data-testid={suffixify("bank-table", "role")}
            />
          );
        },
      },
      {
        id: "actions",
        name: "Actions",
        width: 225,
        render: (row) =>
          "paymentAccount" in row && !("accountOwner" in row) ? (
            <Button
              size="sm"
              variant="ghost"
              color="error"
              onClick={curriedOnDelete(row)}
            >
              Remove account
            </Button>
          ) : "plaidLoginRequired" in row && row.plaidLoginRequired ? (
            <Button
              size="sm"
              variant="ghost"
              color="error"
              onClick={curriedOnUpdate(row)}
            >
              Re-authenticate
            </Button>
          ) : (
            "plaidLoginRequired" in row &&
            !row.plaidLoginRequired && (
              <Button
                size="sm"
                variant="ghost"
                color="neutral"
                onClick={curriedOnUpdate(row)}
              >
                Manage linked accounts
              </Button>
            )
          ),
      },
    ],
    [
      users,
      usersStatus,
      accounts.data,
      accounts.status,
      curriedOnDelete,
      curriedOnUpdate,
      curriedOnChangeAccount,
      showAccountCreateDialog,
    ]
  );

  const fidelCardsTableColumns = useMemo<TableColumn<FidelCard>[]>(
    () => [
      {
        id: "scheme",
        name: "Type",
        width: "fill",
        render: () => "Visa",
      },
      {
        id: "mask",
        name: "Card number",
        sortable: true,
        width: "fill",
        render: "mask",
      },
      {
        id: "verification_status",
        name: "Verified",
        sortable: true,
        width: "fill",
        render: (row) => (row.verificationStatus === "verified" ? "Yes" : "No"),
      },
      {
        id: "user",
        name: "Card holder",
        sortable: "asc",
        render: (row) => (
          <ComboBox
            size="sm"
            flip
            onChange={curriedOnChangeFidelCard({ row, field: "user" })}
            loading={usersStatus === "loading"}
            data={users}
            messageVariant="hidden"
            value={row.user?.url}
            data-testid={suffixify("fidel-cards-table", "role")}
          />
        ),
      },
      {
        id: "payment_account",
        name: <PaymentAccountName />,
        sortable: "asc",
        render: (row) => (
          <ComboBox
            size="sm"
            flip
            action={{
              icon: "plus",
              onClick: () => {
                setSelectedRow(row);
                showAccountCreateDialog();
              },
              children: "Add new",
            }}
            onChange={curriedOnChangeFidelCard({
              row,
              field: "payment-account",
            })}
            data={accounts.data}
            loading={accounts.status === "loading"}
            messageVariant="hidden"
            value={row.paymentAccount?.url}
            data-testid={suffixify("bank-table", "role")}
          />
        ),
      },
      {
        id: "actions",
        name: "Actions",
        width: "fill",
        textAlign: "right",
        render: (row) => (
          <Flex gap="md">
            <Button
              size="sm"
              variant="ghost"
              color="error"
              onClick={curriedOnDeleteFidelCard(row)}
            >
              Remove card
            </Button>
            {row.verificationStatus !== "verified" && row.consentId && (
              <Button
                size="sm"
                variant="ghost"
                color="neutral"
                onClick={() => onVerifyFidel(row)}
              >
                Verify card
              </Button>
            )}
          </Flex>
        ),
      },
    ],
    [
      curriedOnChangeFidelCard,
      usersStatus,
      users,
      accounts.data,
      accounts.status,
      showAccountCreateDialog,
      curriedOnDeleteFidelCard,
      onVerifyFidel,
    ]
  );

  const onConnectFidel = useEvent(() => {
    connectFidel({
      onCardEnrolledCallback(params) {
        if (!params) return toast.error("Card linking failed");

        pollCard({
          fidelId: params.id,
        })
          .then(() =>
            toast.success(
              "Card enrolled successfully. Please check your bank account for a microdeposit"
            )
          )
          .catch(() =>
            toast.error(
              "Card not linked! Please refresh the page and try again"
            )
          );
      },
      onCardVerifiedCallback(params) {
        if (!params) return toast.error("Card linking failed");

        pollCard({
          fidelId: params.cardId,
          verificationStatus: "verified",
        })
          .then(() => toast.success("Card verified successfully"))
          .catch(() =>
            toast.error(
              "Card not linked! Please refresh the page and try again"
            )
          );
      },
      onCardVerifyFailedCallback() {
        toast.error("Card linking failed");
      },
    });
  });

  return (
    <Main>
      {linkToken && (
        <PlaidLink
          accountType="credit"
          linkToken={linkToken}
          selectedBankId={selectedBankId}
          currentClient={client}
          onCreationSuccess={onLinkExit}
          onCreationError={onLinkExit}
          onLinkExit={onLinkExit}
        />
      )}
      {accountCreateDialog.isRendered && (
        <Dialog
          show={accountCreateDialog.isVisible}
          onClose={accountCreateDialog.hide}
          variant="dialog"
        >
          <AccountCreateForm
            dialog={accountCreateDialog}
            variant="single"
            initialAccountType="Liability.CreditCard.CreditCard"
            isCreditCard={true}
            isBankAccount={false}
            onCreatedAccountChange={(account) => {
              refetchAccounts();

              if (selectedRow) {
                if ("fidelId" in selectedRow) {
                  const handler = curriedOnChangeFidelCard({
                    row: selectedRow as FidelCard,
                    field: "payment-account",
                  });
                  handler(account.url);
                } else {
                  const handler = curriedOnChangeAccount({
                    row: selectedRow as PlaidRow,
                    field: "payment-account",
                    method: "accountOwner" in selectedRow ? "plaid" : "bank",
                  });
                  handler(account.url);
                }
              }

              setSelectedRow(undefined);
            }}
          />
        </Dialog>
      )}

      <MainHeader>
        <Flex align="center" gap="xl">
          <Flex direction="column" grow>
            <MainTitle>Linked cards</MainTitle>
            <MainSubtitle>Manage your linked cards on Adaptive</MainSubtitle>
          </Flex>
        </Flex>
      </MainHeader>

      <MainContent>
        {enhancedIsLoading && <Loader position="fixed" />}
        <Flex shrink={false} direction="column" gap="4xl">
          <Flex gap="xl" direction="column">
            {cardFeedEnabled && (
              <Alert variant="neutral">
                <AlertContent>
                  View and manage your card transactions on the{" "}
                  <Link as={ReactRouterLink} variant="success" to="/card-feed">
                    Card Feed
                  </Link>{" "}
                  page
                </AlertContent>
              </Alert>
            )}
            <Flex direction="row" align="center" justify="space-between">
              <Text weight="bolder" size="lg">
                {client?.settings?.fidel_enabled
                  ? "American Express and Mastercard links"
                  : "Card links"}
              </Text>
              {bankAccounts.length > 0 && (
                <Button
                  onClick={onConnectCard}
                  disabled={enhancedIsLoading}
                  size="lg"
                >
                  Connect
                </Button>
              )}
            </Flex>
            {isPlaidLoginRequired && (
              <Alert variant="error">
                <AlertTitle>Your credentials have expired</AlertTitle>
                <AlertContent>
                  Please re-authenticate your bank account to continue pulling
                  transactions
                </AlertContent>
              </Alert>
            )}
            <Table
              id="card-bank-accounts-table"
              size="sm"
              data={bankAccounts}
              columns={columns}
              header={{ hide: bankAccounts.length === 0 }}
              loading={bankAccountIsLoading}
              emptyState={{
                title: "You do not have any linked accounts yet",
                action: {
                  children: "Connect",
                  onClick: onConnectCard,
                },
              }}
            />
          </Flex>
          {client?.settings?.fidel_enabled && (
            <Flex gap="xl" direction="column">
              <Flex direction="row" align="center" justify="space-between">
                <Text weight="bolder" size="lg">
                  Visa links
                </Text>
                {fidelCards?.count ? (
                  <Button
                    onClick={onConnectFidel}
                    disabled={enhancedIsLoading}
                    size="lg"
                  >
                    Connect
                  </Button>
                ) : null}
              </Flex>
              <Table
                id="cards-fidel-table"
                size="sm"
                data={fidelCards?.results}
                header={{ hide: fidelCards?.results?.length === 0 }}
                columns={fidelCardsTableColumns}
                loading={fidelCardsIsLoading}
                sort={fidelCardsSort}
                emptyState={{
                  title: "You do not have any linked cards yet",
                  action: {
                    onClick: onConnectFidel,
                    children: "Connect",
                    disabled: enhancedIsLoading,
                  },
                }}
                pagination={{
                  page: fidelCardsPagination.page,
                  total: fidelCards?.count ?? 0,
                  perPage: fidelCardsPagination.perPage,
                  onChange: fidelCardsPagination.setPage,
                  onPerPageChange: (perPage) => {
                    fidelCardsPagination.setPage(0);
                    fidelCardsPagination.setPerPage(perPage);
                    analytics.track("perPageLimitChange", {
                      location: "cards-fidel-table",
                      limit: perPage,
                    });
                  },
                }}
              />
            </Flex>
          )}
        </Flex>
      </MainContent>
    </Main>
  );
};
