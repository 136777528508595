import React, { useEffect, useId } from "react";
import { Portal } from "@ariakit/react";
import { normalizeProps, useMachine } from "@zag-js/react";
import * as zagToast from "@zag-js/toast";

import { useEvent } from "../../hooks/use-event";

import { Toast } from "./toast";
import { emitter, type ToastEmitterConfig } from "./toast-create";
import styles from "./toast.module.css";

export function ToastContainer() {
  const id = useId();

  const [state, send] = useMachine(
    zagToast.group.machine({
      id,
      offsets: "8px",
      placement: "top-end",
    })
  );

  const api = zagToast.group.connect(state, send, normalizeProps as any);

  const create = useEvent((options: ToastEmitterConfig) => {
    api.create(options);
  });

  const dismiss = useEvent((id: string) => {
    api.dismiss(id);
  });

  const update = useEvent(({ id, ...options }: ToastEmitterConfig) => {
    api.update(id!, options);
  });

  useEffect(() => {
    emitter.on("create", create);
    emitter.on("update", update);
    emitter.on("dismiss", dismiss);

    return () => {
      emitter.off("create", create);
      emitter.off("update", update);
      emitter.off("dismiss", dismiss);
    };
  }, [dismiss, create, update]);

  return (
    <Portal
      className={styles["wrapper"]}
      {...api.getGroupProps({ placement: "top-end" })}
    >
      {api.getToastsByPlacement("top-end").map((actor) => (
        <Toast key={actor.id} actor={actor} />
      ))}
    </Portal>
  );
}
