import React, { forwardRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Flex } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import { useLazyCheckExpenseDuplicationQuery } from "@api/expenses";
import { Comments, type OnAddCommentHandler } from "@components/comments";
import { type Selector } from "@reduxjs/toolkit";
import { RecordStageProvider } from "@shared/sequential";
import {
  selectExpenseDocNumber,
  selectExpenseVendorId,
  useExpenseAction,
  useExpensePermissions,
} from "@store/expenses";
import { expenseSelectors } from "@store/expenses/selectors";
import { type RootState } from "@store/types";

import type {
  CheckExpenseDuplicationResponse,
  Comment as IComment,
} from "../shared/api/expenses/types";

import { getTableRouterUrlForStatus } from "./utils/table";
import { ExpenseContent, ExpenseFooter, Stages } from "./stages";
import grid from "../shared/sequential/grid.module.css";

type CheckDuplicationHandler = (props: {
  vendorId?: string | number | null;
  docNumber?: string | null;
  totalAmount?: number;
}) => void;

const commentsSelector: Selector<
  RootState,
  { comments: IComment[]; url: string }
> = (state) => {
  return {
    comments: expenseSelectors.comments?.(state) || [],
    url: expenseSelectors.url(state),
  };
};

/**
 * We split this component from the main one to avoid re-rendering the whole
 */
const CheckDuplication = () => {
  const vendorId = useSelector(selectExpenseVendorId);
  const docNumber = useSelector(selectExpenseDocNumber);

  const { setDuplicate } = useExpenseAction();

  const [triggerCheckBillDuplication] = useLazyCheckExpenseDuplicationQuery();

  const checkDuplication = useEvent<CheckDuplicationHandler>(
    async ({ vendorId, docNumber }) => {
      let duplicate: CheckExpenseDuplicationResponse["duplicate"] = [];

      if (vendorId && docNumber) {
        const { data } = await triggerCheckBillDuplication({
          docNumber,
          vendorId,
        });

        if (data?.duplicate) {
          duplicate = data?.duplicate;
        }
      }

      setDuplicate(duplicate);
    }
  );

  useEffect(() => {
    checkDuplication({ vendorId, docNumber });
  }, [checkDuplication, docNumber, vendorId]);

  return null;
};

export const ExpenseForm = forwardRef<HTMLDivElement>((_, ref) => {
  const navigate = useNavigate();

  const currentStage = useSelector(expenseSelectors.reviewStatus);

  const vendor = useSelector(expenseSelectors.vendor);

  const enhancedVendor = useMemo(() => {
    if (!vendor?.url?.includes("/vendors/") || !vendor.displayName) {
      return undefined;
    }

    return {
      url: vendor.url,
      email: vendor.email || undefined,
      displayName: vendor.displayName,
    };
  }, [vendor]);

  const { saveComment } = useExpenseAction();

  const { canCommentExpense } = useExpensePermissions();

  const { state: locationState } = useLocation();

  const onAddComment = useEvent<OnAddCommentHandler>(
    ({ user, text, id, url, parentCommentUrl, hasExternalMention, files }) => {
      saveComment({
        author: user,
        text,
        id,
        files,
        url,
        parentCommentUrl,
        hasExternalMention,
      });
    }
  );

  const onCancel = useEvent(() => {
    const url = getTableRouterUrlForStatus(currentStage);
    navigate(dotObject.get(locationState as object, "prev", url), {
      state: locationState,
    });
  });

  return (
    <>
      <CheckDuplication />
      <RecordStageProvider
        names={Stages}
        currentStage={currentStage as (typeof Stages)[number]}
        finishedStage="REVIEWED"
      >
        <div ref={ref} className={grid["container"]}>
          <ExpenseContent>
            <Flex
              width="full"
              shrink={false}
              direction="column"
              margin={["3xl", "none", "none"]}
            >
              <Comments
                vendor={enhancedVendor}
                selector={commentsSelector}
                onAddComment={canCommentExpense ? onAddComment : undefined}
              />
            </Flex>
          </ExpenseContent>

          <ExpenseFooter onCancel={onCancel} />
        </div>
      </RecordStageProvider>
    </>
  );
});

ExpenseForm.displayName = "ExpenseForm";
