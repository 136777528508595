import React, { useEffect, useMemo } from "react";
import {
  Button,
  ComboBox,
  CurrencyField,
  Flex,
  Icon,
  Text,
} from "@adaptive/design-system";
import { useEvent, type UseFormReturn } from "@adaptive/design-system/hooks";
import {
  CostCodeAccountComboBox,
  type CostCodeAccountComboBoxProps,
} from "@components/cost-code-account-combobox";
import { CustomersComboBox } from "@components/customers-combobox";
import { Circle } from "@components/number/circle";
import { useVendorsSimplified } from "@hooks/use-vendors-simplified";

import type {
  AmountCondition,
  Condition,
  ConditionType,
  CustomerCondition,
  Fields,
  ItemCondition,
  VendorCondition,
} from "./form";
import styles from "./form.module.css";

const TYPES = [
  { label: "Amount", value: "AMOUNT" },
  { label: "Cost code", value: "COST_CODE" },
  { label: "Job", value: "JOB" },
  { label: "Vendor", value: "VENDOR" },
];

const JOB_CONDITION_TYPES = [
  { label: "is one of", value: "ONE_OF" },
  { label: "is any", value: "ANY_JOB" },
  { label: "is empty", value: "NO_JOB" },
];

const COSTCODE_ACCOUNT_COMBOBOX_FILTERS: CostCodeAccountComboBoxProps<false>["filters"] =
  ["costCodeAccount"];

const COSTCODE_ACCOUNT_QUERY_ACCOUNT_FILTERS = { enabled: false };

const MAX_CONDITIONS_LENGTH = 4;

const getConditionTypes = (conditions: Condition[], index?: number) => {
  return TYPES.filter((type) => {
    return !conditions.some((condition, i) => {
      if (index !== undefined) {
        return type.value === condition.type && index !== i;
      }
      return type.value === condition.type;
    });
  });
};

type Props = {
  form: UseFormReturn<Fields>;
};

type ConditionComboboxProps = {
  form: UseFormReturn<Fields>;
  index: number;
};

const ConditionCombobox = ({ form, index }: ConditionComboboxProps) => {
  const data = useMemo(
    () => getConditionTypes(form.values.conditions, index),
    [form.values.conditions, index]
  );

  return (
    <ComboBox
      data={data}
      required
      messageVariant="hidden"
      {...form.register(`conditions.${index}.type`)}
      onChange={(value) => {
        form.setValue(`conditions.${index}.type`, value);

        const zeroedPayload: Record<string, unknown> = {
          type: value,
        };

        if (value === "AMOUNT") {
          zeroedPayload["amountFrom"] = null;
          zeroedPayload["amountTo"] = null;
        } else if (value === "COST_CODE") {
          zeroedPayload["items"] = [];
        } else if (value === "VENDOR") {
          zeroedPayload["vendors"] = [];
        } else {
          zeroedPayload["customers"] = [];
        }

        form.setValue(`conditions.${index}`, zeroedPayload);
      }}
    />
  );
};

export const WorkflowConditions = ({ form }: Props) => {
  const vendorsSimplified = useVendorsSimplified();

  const onAddCondition = useEvent(() => {
    const types = getConditionTypes(form.values.conditions);

    const type = types[0].value as ConditionType;

    let payload:
      | AmountCondition
      | CustomerCondition
      | ItemCondition
      | VendorCondition;

    if (type === "AMOUNT") {
      payload = { amountFrom: null, amountTo: null, type };
    } else if (type === "COST_CODE") {
      payload = { items: [], type };
    } else if (type === "VENDOR") {
      payload = { vendors: [], type };
    } else {
      payload = { customers: [], type, requirementType: "ONE_OF" };
    }

    form.append("conditions", { ...payload });
  });

  const curriedOnRemoveLine = (index: number) => () => {
    form.remove("conditions", index);
  };

  const { jobIndex, jobCondition } = useMemo(() => {
    const jobIndex = form.values.conditions.findIndex(
      (item) => item.type === "JOB"
    );
    const jobCondition = form.values.conditions[jobIndex] as CustomerCondition;

    return { jobIndex, jobCondition };
  }, [form.values.conditions]);

  useEffect(() => {
    if (
      jobCondition &&
      ((jobCondition?.customers && jobCondition?.customers.length > 0) ||
        !jobCondition?.customers) &&
      jobCondition.requirementType !== "ONE_OF"
    ) {
      form.setValue(`conditions.${jobIndex}.customers`, []);
    }

    if (jobCondition && !jobCondition.requirementType) {
      form.setValue(`conditions.${jobIndex}.requirementType`, "ONE_OF");
    }
  }, [form, jobCondition, jobIndex]);

  return (
    <Flex direction="column" gap="xl" shrink={false}>
      <Flex direction="column">
        <Text size="md" weight="bolder">
          If the transaction meets the following conditions:
        </Text>
        <Text size="sm" color="neutral-700">
          Choose the job, vendor, amount, and/or cost code that you want to
          trigger the approval policy.
        </Text>
      </Flex>
      <Flex direction="column">
        {form.values.conditions.map((condition, index) => {
          return (
            <Flex
              gap="md"
              key={`condition-${index}`}
              align="center"
              className={styles["item"]}
            >
              <Circle className={styles["circle"]}>
                {index === 0 ? "If" : "&"}
              </Circle>
              <Flex gap="md" align="center" width="full">
                <Flex width="150px" shrink={false}>
                  <ConditionCombobox form={form} index={index} />
                </Flex>
                <Flex shrink={false}>
                  {condition.type === "JOB" ? (
                    <Flex width="130px">
                      <ComboBox
                        data={JOB_CONDITION_TYPES}
                        required
                        messageVariant="hidden"
                        {...form.register(
                          `conditions.${index}.requirementType`
                        )}
                      />
                    </Flex>
                  ) : (
                    <Text>
                      {condition.type === "AMOUNT" ? "is between" : "is one of"}
                    </Text>
                  )}
                </Flex>
                {condition.type === "AMOUNT" ? (
                  <Flex gap="md" align="center">
                    <CurrencyField
                      placeholder="Any"
                      messageVariant="hidden"
                      {...form.register({
                        name: `conditions.${index}.amountFrom`,
                        type: "currency",
                      })}
                    />
                    <Text>and</Text>
                    <CurrencyField
                      placeholder="Any"
                      messageVariant="hidden"
                      {...form.register({
                        name: `conditions.${index}.amountTo`,
                        type: "currency",
                      })}
                    />
                  </Flex>
                ) : null}
                {condition.type === "COST_CODE" ? (
                  <Flex width="full">
                    <CostCodeAccountComboBox
                      label=""
                      required
                      messageVariant="hidden"
                      multiple
                      accountFilters={COSTCODE_ACCOUNT_QUERY_ACCOUNT_FILTERS}
                      filters={COSTCODE_ACCOUNT_COMBOBOX_FILTERS}
                      {...form.register({
                        name: `conditions.${index}.items`,
                        type: "multiple-select",
                      })}
                    />
                  </Flex>
                ) : null}
                {condition.type === "JOB" ? (
                  <Flex width="full">
                    <CustomersComboBox
                      label=""
                      required
                      multiple
                      messageVariant="hidden"
                      disabled={condition.requirementType !== "ONE_OF"}
                      {...form.register({
                        name: `conditions.${index}.customers`,
                        type: "multiple-select",
                      })}
                    />
                  </Flex>
                ) : null}
                {condition.type === "VENDOR" ? (
                  <ComboBox
                    data={vendorsSimplified.data}
                    required
                    messageVariant="hidden"
                    multiple
                    {...form.register({
                      name: `conditions.${index}.vendors`,
                      type: "multiple-select",
                    })}
                  />
                ) : null}
                <Button
                  variant="ghost"
                  color="neutral"
                  aria-label="Remove condition"
                  onClick={curriedOnRemoveLine(index)}
                  disabled={form.values.conditions.length === 1}
                >
                  <Icon name="trash" />
                </Button>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      <Flex>
        <Button
          variant="ghost"
          disabled={form.values.conditions.length === MAX_CONDITIONS_LENGTH}
          size="sm"
          onClick={onAddCondition}
        >
          Add condition
        </Button>
      </Flex>
    </Flex>
  );
};
