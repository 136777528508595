import React, {
  type ForwardedRef,
  forwardRef,
  useId,
  useImperativeHandle,
} from "react";
import { normalizeProps, useMachine } from "@zag-js/react";
import * as signaturePad from "@zag-js/signature-pad";

import { useEvent } from "../../hooks";
import { suffixify } from "../../utils";
import { Button } from "../button";
import { Flex } from "../flex";

import styles from "./signature-pad.module.css";

type SignaturePadProps = {
  onClear?: () => void;
  name?: string;
  disabled?: boolean;
  onChange?: (values: { url: string; paths: string[] }) => void;
  className?: string;
  testId?: string;
};

export type SignaturePadRef = {
  setPaths?: (paths: string[]) => void;
};

const SignaturePad = (
  { onClear, onChange, disabled, name, className, testId }: SignaturePadProps,
  ref: ForwardedRef<SignaturePadRef>
) => {
  const [state, send, rawMachine] = useMachine(
    signaturePad.machine({
      id: useId(),
      name,
      disabled,
      drawing: {
        simulatePressure: true,
        size: 5,
      },
      onDrawEnd({ getDataUrl, paths }) {
        if (!onChange) return;

        getDataUrl("image/png").then((url) => {
          onChange({ url, paths });
        });
      },
    })
  );

  useImperativeHandle(
    ref,
    () => ({
      setPaths: (paths) => {
        rawMachine.setContext({ paths });
      },
    }),
    [rawMachine]
  );

  const {
    rootProps,
    controlProps,
    segmentProps,
    getSegmentPathProps,
    paths,
    currentPath,
    clear,
  } = signaturePad.connect(state, send, normalizeProps as any);

  const enhancedOnClear = useEvent(() => {
    onClear?.();
    clear();
  });

  return (
    <Flex
      direction="column"
      align="flex-end"
      gap="md"
      height="full"
      width="full"
      overflow="hidden"
      className={className}
      data-testid={suffixify(testId, "root")}
      {...rootProps}
    >
      <div
        className={styles["signature-pad-controls"]}
        data-testid={suffixify(testId, "controls")}
        {...controlProps}
        tabIndex={-1}
      >
        <svg {...segmentProps} data-testid={suffixify(testId, "svg")}>
          {paths.map((path, i) => (
            <path key={i} {...getSegmentPathProps({ path })} />
          ))}
          {currentPath && (
            <path {...getSegmentPathProps({ path: currentPath })} />
          )}
        </svg>
      </div>
      <Button
        variant="ghost"
        color="neutral"
        onClick={enhancedOnClear}
        disabled={paths.length === 0 || disabled}
        size="sm"
        data-testid={suffixify(testId, "clear-button")}
      >
        Clear
      </Button>
    </Flex>
  );
};

const ForwardedSignaturePad = forwardRef(SignaturePad);

export { ForwardedSignaturePad as SignaturePad };
