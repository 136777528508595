import React, { useEffect, useRef } from "react";
import { useMatch, useNavigate, useSearchParams } from "react-router";
import { Main, MainContent } from "@components/main";
import { useVendorAction } from "@store/vendors";
import type { Stage } from "@store/vendors/types";

import { VendorTable } from "./table/table";
import { Heading } from "./heading";

const Vendor = () => {
  const match = useMatch("/vendors/:vendorId");

  const vendorId = match?.params.vendorId;

  const navigate = useNavigate();

  const { showVendorById } = useVendorAction({
    onDrawerClose: () => navigate("/vendors"),
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const initialStageRef = useRef((searchParams.get("tab") || "info") as Stage);

  useEffect(() => {
    const initialStage = initialStageRef.current;

    if (vendorId) {
      showVendorById(vendorId, initialStage);
      initialStageRef.current = "info";
    }
  }, [vendorId, showVendorById]);

  useEffect(() => {
    setSearchParams({}, { replace: true });
  }, [setSearchParams]);

  return null;
};

export const Vendors = () => (
  <Main>
    <Heading />
    <MainContent>
      <VendorTable />
    </MainContent>
    <Vendor />
  </Main>
);
