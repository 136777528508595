import type { TableRow } from "@adaptive/design-system";
import { dotObject, fuzzySearch } from "@adaptive/design-system/utils";
import type { MarkupResponse } from "@api/budgets";
import type { BudgetLineItems } from "@api/jobs";
import {
  defaultArrayFormatter,
  type Formatter,
  type QueryItem,
} from "@components/table-filter/formatters";
import { isAccount } from "@utils/is-account";
import { isCostCode } from "@utils/is-cost-code";
import { sum } from "@utils/sum";

import type { UseLinesAndMarkupsFilters } from "./hooks/use-lines-and-markups";
import type { Line } from "./lines";
import type { Markup } from "./markups";

export const isNumber = (value: unknown): value is number =>
  typeof value === "number" && !isNaN(value);

export const getIsNullOrUndefined = (value: any): boolean =>
  value === null || value === undefined;

type GetPreferredCostBudgetAmountParams = {
  [key: string]: any;
  builderAmount?: number;
  changeAmount?: number;
  builderRevisedAmount?: number;
  ownersRevisedAmount?: number;
};

export const getPreferredCostBudgetAmount = (
  row?: GetPreferredCostBudgetAmountParams
): number => {
  if (!row) return 0;

  const { builderAmount, builderRevisedAmount } = row;

  if (!getIsNullOrUndefined(builderRevisedAmount)) {
    return builderRevisedAmount as number;
  }

  if (!getIsNullOrUndefined(builderAmount)) {
    return builderAmount as number;
  }

  return 0;
};

type GetPreferredRevenueBudgetAmountParams = {
  [key: string]: any;
  builderAmount?: number;
  changeAmount?: number;
  builderRevisedAmount?: number;
  ownersRevisedAmount?: number;
};

export const getPreferredRevenueBudgetAmount = (
  row: GetPreferredRevenueBudgetAmountParams
): number => {
  if (!row) return 0;

  const {
    builderAmount,
    builderRevisedAmount,
    ownersAmount,
    ownersRevisedAmount,
  } = row;

  if (
    !getIsNullOrUndefined(ownersRevisedAmount) &&
    isNumber(ownersRevisedAmount)
  ) {
    return ownersRevisedAmount;
  }

  if (!getIsNullOrUndefined(ownersAmount) && isNumber(ownersAmount)) {
    return ownersAmount;
  }

  if (
    !getIsNullOrUndefined(builderRevisedAmount) &&
    isNumber(builderRevisedAmount)
  ) {
    return builderRevisedAmount;
  }

  if (!getIsNullOrUndefined(builderAmount) && isNumber(builderAmount)) {
    return builderAmount;
  }

  return 0;
};

export const getBudgetRemaining = (row: TableRow<BudgetLineItems>) =>
  (row.data?.length ? row.data : [row]).reduce(
    (acc, line) =>
      sum(acc, sum(getPreferredCostBudgetAmount(line), -line.spent)),
    0
  );

type GetBudgetAmountParams = {
  type: "cost" | "revenue";
  ownersAmount: number;
  builderAmount: number;
  ownersAmountEnabled: boolean;
  ownersRevisedAmount?: number;
  builderRevisedAmount?: number;
  changeTrackingEnabled: boolean;
};

export const getBudgetAmount = ({
  type,
  ownersAmount,
  builderAmount,
  ownersRevisedAmount,
  ownersAmountEnabled,
  builderRevisedAmount,
  changeTrackingEnabled,
}: GetBudgetAmountParams) => {
  if (ownersAmountEnabled && type === "revenue") {
    return (changeTrackingEnabled ? ownersRevisedAmount : ownersAmount) || 0;
  }

  return (changeTrackingEnabled ? builderRevisedAmount : builderAmount) || 0;
};

type GetRemainingParams = {
  spent: number;
  builderAmount: number;
  ownersAmount: number;
  invoicedAmount: number;
  ownersAmountEnabled: boolean;
  builderRevisedAmount?: number;
  changeTrackingEnabled: boolean;
};

export const getRemaining = (row: GetRemainingParams) => {
  const preferredRevenueBudget = getBudgetAmount({ ...row, type: "revenue" });
  const preferredCostBudget = getBudgetAmount({ ...row, type: "cost" });
  const builderRemainingAmount = sum(preferredCostBudget, -row.spent);
  const invoicedRemainingAmount = sum(
    preferredRevenueBudget,
    -row.invoicedAmount
  );

  return { builderRemainingAmount, invoicedRemainingAmount };
};

export const sortByReference = <Data extends Record<string, unknown>[]>({
  key,
  original,
  reference,
}: {
  key: string;
  original: Data;
  reference: Data;
}) =>
  [...original].sort((a, b) => {
    const indexA = reference.findIndex(
      (item) => dotObject.get(item, key) === dotObject.get(a, key)
    );
    const indexB = reference.findIndex(
      (item) => dotObject.get(item, key) === dotObject.get(b, key)
    );
    return indexA === -1 || indexB === -1 ? 0 : indexA - indexB;
  });

export const matchItem = (
  item: Line | Markup,
  filters: Partial<UseLinesAndMarkupsFilters>
) => {
  if (filters.account) {
    if (isCostCode(item.jobCostMethod.url)) return false;

    const match = filters.account.includes(String(item.jobCostMethod.id));

    if (!match) return false;
  }

  if (filters.cost_code) {
    if (isAccount(item.jobCostMethod.url)) return false;

    const match = filters.cost_code.includes(String(item.jobCostMethod.id));

    if (!match) return false;
  }

  if (filters.category) {
    if (!("category" in item) || !item.category) return false;

    const match = filters.category.includes(String(item.category.id));

    if (!match) return false;
  }

  if (filters.query) {
    const fuzzyInstance = fuzzySearch([item], {
      keys: [
        "category.displayName",
        "jobCostMethod.displayName",
        "jobCostMethod.parentLabel",
        "budgetLine.category.displayName",
      ],
      threshold: 0,
    });

    return fuzzyInstance.search(filters.query).length > 0;
  }

  return true;
};

type GetLineTotals = {
  data: BudgetLineItems[];
  ownersAmountEnabled: boolean;
  changeTrackingEnabled: boolean;
};

export const getLineTotals = ({
  data,
  ownersAmountEnabled,
  changeTrackingEnabled,
}: GetLineTotals) => {
  const totals = data.reduce(
    (acc, line) => ({
      builderAmount: sum(acc.builderAmount, line.builderAmount),
      changeAmount: sum(acc.changeAmount, line.changeAmount ?? 0),
      builderRevisedAmount: sum(
        acc.builderRevisedAmount,
        line.builderRevisedAmount ?? 0
      ),
      spent: sum(acc.spent, line.spent),
      builderRemainingAmount: sum(
        acc.builderRemainingAmount,
        line.builderRemainingAmount
      ),
      ownersAmount: sum(acc.ownersAmount, line.ownersAmount),
      externalChangeAmount: sum(
        acc.externalChangeAmount,
        line.externalChangeAmount ?? 0
      ),
      ownersRevisedAmount: sum(
        acc.ownersRevisedAmount,
        line.ownersRevisedAmount ?? 0
      ),
      invoicedAmount: sum(acc.invoicedAmount, line.invoicedAmount),
      unpaidBills: sum(acc.unpaidBills, line.unpaidBills),
    }),
    {
      builderAmount: 0,
      changeAmount: 0,
      builderRevisedAmount: 0,
      unpaidBills: 0,
      spent: 0,
      builderRemainingAmount: 0,
      ownersAmount: 0,
      externalChangeAmount: 0,
      ownersRevisedAmount: 0,
      invoicedAmount: 0,
    }
  );

  const { builderRemainingAmount, invoicedRemainingAmount } = getRemaining({
    ...totals,
    ownersAmountEnabled,
    changeTrackingEnabled,
  });

  return { ...totals, builderRemainingAmount, invoicedRemainingAmount };
};

type GetMarkupTotals = {
  data: MarkupResponse[];
  ownersAmountEnabled: boolean;
  changeTrackingEnabled: boolean;
};

export const getMarkupTotals = ({
  data,
  ownersAmountEnabled,
  changeTrackingEnabled,
}: GetMarkupTotals) => {
  const totals = data.reduce(
    (acc, markup) => ({
      builderAmount: sum(acc.builderAmount, markup.builderAmount),
      changeAmount: sum(acc.changeAmount, markup.changeAmount),
      builderRevisedAmount: sum(
        acc.builderRevisedAmount,
        markup.builderRevisedAmount
      ),
      spent: sum(acc.spent, markup.budgetLine?.spent || 0),
      unpaidBills: sum(acc.unpaidBills, markup.budgetLine?.unpaidBills || 0),
      builderRemainingAmount: sum(
        acc.builderRemainingAmount,
        markup.budgetLine?.builderRemainingAmount || 0
      ),
      ownersAmount: sum(acc.ownersAmount, markup.ownersAmount || 0),
      externalChangeAmount: sum(
        acc.externalChangeAmount,
        markup.externalChangeAmount || 0
      ),
      ownersRevisedAmount: sum(
        acc.ownersRevisedAmount,
        markup.ownersRevisedAmount || 0
      ),
      invoicedAmount: sum(
        acc.invoicedAmount,
        markup.budgetLine?.invoicedAmount || 0
      ),
    }),
    {
      builderAmount: 0,
      changeAmount: 0,
      builderRevisedAmount: 0,
      unpaidBills: 0,
      spent: 0,
      builderRemainingAmount: 0,
      ownersAmount: 0,
      externalChangeAmount: 0,
      ownersRevisedAmount: 0,
      invoicedAmount: 0,
    }
  );

  const preferredRevenueBudget = getBudgetAmount({
    ...totals,
    type: "revenue",
    ownersAmountEnabled,
    changeTrackingEnabled,
  });

  return {
    ...totals,
    invoicedRemainingAmount: sum(
      preferredRevenueBudget,
      -totals.invoicedAmount
    ),
  };
};

export const formatter: Formatter<QueryItem[]> = (realmId, selections) =>
  defaultArrayFormatter(realmId, selections).map((item) => {
    if (item.dataIndex.startsWith("date_")) {
      const range = item.dataIndex.replace("date_", "");

      return { dataIndex: `combined_date_${range}`, value: item.value };
    }

    return item;
  });

export const getItemById = (data: Line[], id: string | number) => {
  const stack = [...data];

  while (stack.length) {
    const item = stack.pop();

    if (item?.id === id) {
      return item;
    }

    if (item?.data) {
      stack.push(...item.data);
    }
  }

  return undefined;
};

export const groupByCategory = (data: Line[]) =>
  data.reduce((acc, line) => {
    const group = acc.find((group) => group.category?.id === line.category?.id);

    if (group) {
      group.data!.push(line);
    } else {
      acc.push({ ...line, data: [line] });
    }

    return acc;
  }, [] as Line[]);

export const groupByParent = (data: Line[]) => {
  const result: Line[] = [];

  data.forEach((item) => {
    if (!item.jobCostMethod.parent) {
      result.push(item);
    } else {
      const parent = data.find(
        (parent) => parent.jobCostMethod.url === item.jobCostMethod.parent
      );

      if (parent) {
        if (!parent.data) {
          const originalParent = { ...parent };
          parent.id = `${parent.id}-parent`;
          parent.data = [originalParent];
        }
        parent.data.push(item);
      } else {
        result.push(item);
      }
    }
  });

  return result;
};

type SummarizeGroupParams = {
  data: Line[];
  ownersAmountEnabled: boolean;
  changeTrackingEnabled: boolean;
};

export const summarizeGroup = ({
  data,
  ownersAmountEnabled,
  changeTrackingEnabled,
}: SummarizeGroupParams) => {
  const processLine = (line: Line): Line => {
    const data = line.data?.map(processLine) || undefined;

    const { builderRemainingAmount, invoicedRemainingAmount, ...totals } =
      getLineTotals({
        data: data?.length ? data : [line],
        ownersAmountEnabled,
        changeTrackingEnabled,
      });

    return {
      ...line,
      data,
      remaining: invoicedRemainingAmount,
      budgetRemaining: builderRemainingAmount,
      ...totals,
    };
  };

  return data.map(processLine);
};
