import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Link,
  Loader,
  Table,
  type TableColumn,
  type TableEmptyState,
  type TableSelectAddon,
  TagGroup,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent, usePagination } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  parseDate,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import {
  useGetCustomerLinesQuery,
  useLazyGetLinkedInvoicesQuery,
} from "@api/jobs";
import { exportJob } from "@api/jobs/jobs";
import {
  BatchApplyObject,
  type BatchApplyObjectOnChangeHandlerProps,
} from "@components/batch-apply-object";
import {
  DownloadButton,
  type OnDownloadHandler,
} from "@components/download-button";
import {
  type StrictValuesFilters,
  TableFilterControls,
} from "@components/table-filter";
import {
  defaultArrayFormatter,
  type QueryItem,
} from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { useApplyObject } from "@hooks/use-apply-object";
import type { CustomerItemNestedLine } from "@hooks/useCustomerItemLines";
import {
  BILLABLE_STATUS_FILTER,
  DRAW_STATUS_FILTER,
  HUMAN_READABLE_BILL_STATUS,
  STATUS_FILTER,
} from "@src/bills/constants";
import type { QueryFilters } from "@src/bills/types";
import {
  HUMAN_READABLE_EXPENSE_REVIEW_STATUS,
  HUMAN_READABLE_EXPENSE_TYPE,
} from "@src/expenses/utils/constants";
import { api as reduxApi } from "@store/api-simplified";
import { useJobInfo } from "@store/jobs";
import * as analytics from "@utils/analytics";
import { noop } from "@utils/noop";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import {
  confirmSyncInvoiceLines,
  type InvoiceData,
} from "@utils/transaction-confirm-messages";

import type { JobTransactionsDialogProps } from "./";

const PER_PAGE = 10;

const TYPE_TO_URL = {
  Bill: "bills",
  Receipt: "expenses",
  "Vendor Credit": "bills",
};

const TRANSACTION_TYPE_FILTER = (
  [
    { value: "bill", label: "Bill" },
    { value: "receipt", label: "Receipt" },
    { value: "labor", label: "Labor" },
    { value: "vendor_credit", label: "Vendor credit" },
  ] as const
).map((filter) => ({ ...filter, groupLabel: "Transaction type" }));

const EXTRA_DATA = [
  ...STATUS_FILTER,
  ...TRANSACTION_TYPE_FILTER,
  ...DRAW_STATUS_FILTER,
  ...BILLABLE_STATUS_FILTER,
];

const INITIAL_FILTERS = {} as StrictValuesFilters;

type CustomerItemLine = CustomerItemNestedLine & {
  account: { display_name: string } | null;
  item: { display_name: string } | null;
};

const formatter: typeof defaultArrayFormatter = (realmId, selections) => {
  return defaultArrayFormatter(realmId, selections).reduce((acc, filter) => {
    if (filter.dataIndex === "account") {
      return [...acc, { dataIndex: "accounts", value: filter.value }];
    }
    if (filter.dataIndex === "cost_code") {
      return [...acc, { dataIndex: "items", value: filter.value }];
    }

    return [...acc, filter];
  }, [] as QueryItem[]);
};

export const JobTransactionsDialog = ({
  dialog,
}: JobTransactionsDialogProps) => {
  const { job } = useJobInfo();

  const dispatch = useDispatch();

  const [orderBy] = useState("-date");

  const pagination = usePagination();

  const [offset, setOffset] = useState(0);

  const [selected, setSelected] = useState<CustomerItemLine[]>([]);

  const [triggerGetLinkedInvoices] = useLazyGetLinkedInvoicesQuery();

  const applyObject = useApplyObject({ items: selected, resource: "lines" });

  const previousBatchApplyToast = useRef(noop);

  const {
    setFilters,
    filters = [],
    rawFilters,
  } = useTableFilters({
    formatter,
    initialFilters: INITIAL_FILTERS,
  });

  const { isFetching, data, refetch } = useGetCustomerLinesQuery(
    {
      customerId: job.id.toString(),
      limit: PER_PAGE,
      orderBy,
      offset,
      filters: filters as QueryFilters,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const getTransactionUrl = useCallback((row: CustomerItemLine) => {
    if (row.parent.human_readable_type in TYPE_TO_URL) {
      const path =
        TYPE_TO_URL[row.parent.human_readable_type as keyof typeof TYPE_TO_URL];

      return `/${path}/${row.parent.id}`;
    }
  }, []);

  const onClearFilters = useEvent(() => setFilters({}));

  const onFiltersChange = useEvent((filters) => setFilters(filters));

  const hasFilters = Object.values(rawFilters).some((filter) => !!filter);

  const select = useMemo<TableSelectAddon<CustomerItemLine>>(
    () => ({
      value: selected,
      onChange: setSelected,
    }),
    [selected]
  );

  const columns = useMemo<TableColumn<CustomerItemLine>[]>(
    () => [
      {
        id: "vendor",
        width: 250,
        name: "Vendor",
        render: (row) => (
          <Text truncate>
            {row.vendor?.display_name ||
              row.other_name?.display_name ||
              row.employee?.display_name}
          </Text>
        ),
      },
      {
        id: "accountCostCode",
        width: 270,
        name: "Account / Cost Code",
        render: (row) => (
          <TagGroup
            data={
              [row.account, row.item].filter(Boolean) as {
                display_name: string;
              }[]
            }
            render={(item) => item?.display_name}
          />
        ),
      },
      {
        id: "status",
        name: "Status",
        width: 140,
        render: (row) => (
          <Text truncate>
            {row.parent.human_readable_type === "Bill" ||
            row.parent.human_readable_type === "Vendor Credit"
              ? HUMAN_READABLE_BILL_STATUS[
                  row.parent
                    .review_status as keyof typeof HUMAN_READABLE_BILL_STATUS
                ]
              : HUMAN_READABLE_EXPENSE_REVIEW_STATUS[
                  row.parent
                    .review_status as keyof typeof HUMAN_READABLE_EXPENSE_REVIEW_STATUS
                ]}
          </Text>
        ),
      },
      {
        id: "type",
        name: "Type",
        width: 140,
        render: "parent.human_readable_type",
      },
      {
        id: "amount",
        name: "Cost",
        width: 140,
        textAlign: "right",
        render: (row) => (
          <Flex align="center" gap="sm" justify="flex-end">
            <Text align="right" truncate>
              {formatCurrency(row.amount, {
                currencySign: true,
                allowNegative: true,
              })}
            </Text>
            {row.parent.human_readable_type ===
              HUMAN_READABLE_EXPENSE_TYPE.LABOR &&
              !row.amount && (
                <Tooltip
                  name="info-circle"
                  as={Icon}
                  size="sm"
                  message="Hourly rate is not set in QuickBooks"
                />
              )}
          </Flex>
        ),
      },
      {
        id: "number",
        name: "Ref #",
        width: 120,
        render: (row) =>
          row.parent.doc_number &&
          row.parent.human_readable_type ===
            HUMAN_READABLE_EXPENSE_TYPE.LABOR ? (
            <Flex align="center" gap="sm">
              <Text truncate>{`#${row.parent.doc_number}`}</Text>
            </Flex>
          ) : row.parent.doc_number ? (
            <Link
              rel="noreferrer"
              href={getTransactionUrl(row)}
              target="_blank"
              variant="success"
            >
              <Text as="span" size="sm" truncate>
                #{row.parent.doc_number}
              </Text>
            </Link>
          ) : (
            "Missing ref #"
          ),
      },
      {
        id: "date",
        name: "Date",
        width: 120,
        render: (row) => formatDate(parseDate(row.date, "yyyy-MM-dd")),
      },
    ],
    [getTransactionUrl]
  );

  const onBatchApply = useEvent(
    async ({
      items = selected,
      option,
      fieldName,
    }: BatchApplyObjectOnChangeHandlerProps & {
      items?: CustomerItemLine[];
    }) => {
      if (!option) return;

      const handler = async (syncInvoiceLines = false) => {
        try {
          const { success } = await applyObject.execute({
            items,
            value: option.value,
            method:
              fieldName === "billable_status"
                ? "apply_billable_status_batch"
                : "apply_object_batch",
            fieldName,
            syncInvoiceLines,
          });

          await refetch();

          dispatch(
            reduxApi.util.invalidateTags(["CustomerMarkup", "BudgetLines"])
          );

          analytics.track("budgetTransactionBatchActions", {
            value: option.value,
            action: fieldName,
            transactionIds: items.map((transaction) => transaction.id),
          });

          if (fieldName === "customer") {
            if (job.url === option.value) {
              return toast.success(
                `Transaction${success > 1 ? "s" : ""} moved back to ${
                  job.display_name
                }`
              );
            }

            const undo = () => {
              previousBatchApplyToast.current();

              onBatchApply({
                value: job.url,
                items,
                option: { label: job.display_name, value: job.url },
                fieldName: "customer",
              });
            };

            const { dismiss } = toast.success(
              <Flex as="span" direction="column">
                <Text as="strong" weight="bold">
                  Transaction{success > 1 ? "s" : ""} moved to{" "}
                  {option?.label ?? "Unknown"}
                </Text>
                <Flex gap="lg">
                  <Link as="button" type="button" onClick={undo}>
                    Undo
                  </Link>
                  <Link
                    rel="noreferrer"
                    href={`/jobs/${parseRefinementIdFromUrl(option.value)}`}
                    target="_blank"
                  >
                    View job
                  </Link>
                </Flex>
              </Flex>,
              { duration: 10000 }
            );

            previousBatchApplyToast.current = dismiss;
          } else {
            toast.success(`${success} Line${success > 1 ? "s" : ""} updated!`);
          }
        } catch (e) {
          analytics.track("budgetBatchActionsError", {
            items,
            value: option.value,
            method: "apply_object_batch",
            fieldName,
            syncInvoiceLines,
          });
          handleErrors(e);
        }
      };

      const { data } = await triggerGetLinkedInvoices({
        billIds: selected
          .filter((item) => item.parent.human_readable_type === "Bill")
          .map(({ parent }) => parent.id),
        expenseIds: selected
          .filter((item) => item.parent.human_readable_type === "Receipt")
          .map(({ parent }) => parent.id),
      });

      const linkedInvoices: InvoiceData[] = data?.invoices ?? [];

      if (linkedInvoices.length) {
        confirmSyncInvoiceLines({
          isPlural: selected.length > 1,
          linkedInvoices,
          action: {
            primary: {
              color: "primary",
              onClick: () => handler(true),
              children: "Update line on draw",
            },
            secondary: {
              onClick: handler,
              children: "Don't update line on draw",
            },
          },
        });
      } else {
        handler();
      }
    }
  );

  const onDownload = useCallback<OnDownloadHandler>(
    async ({ params }) => {
      params.append("order_by", orderBy);
      return exportJob({ id: job.id, params });
    },
    [orderBy, job.id]
  );

  const emptyState = useMemo<TableEmptyState>(() => {
    if (hasFilters) {
      return {
        title: "No transactions match your filters",
      };
    }

    return {
      title: "There are no costs for this job",
    };
  }, [hasFilters]);

  useEffect(() => {
    setOffset(pagination.offset);
  }, [pagination.offset]);

  useEffect(() => {
    if (!dialog.isVisible) pagination.setPage(0);
  }, [dialog.isVisible, pagination]);

  return (
    <>
      {applyObject.isLoading && <Loader position="fixed" />}
      <Dialog
        size="auto"
        variant="dialog"
        show={dialog.isVisible}
        onClose={dialog.hide}
      >
        <DialogHeader>
          <Flex direction="column" gap="md">
            {job.display_name}
            <Text size="md">Transactions</Text>
          </Flex>
        </DialogHeader>
        <DialogContent>
          <Flex direction="column" gap="lg" minWidth="900px">
            <Flex gap="md" direction="column">
              <TableFilterControls
                filters={rawFilters}
                extraData={EXTRA_DATA}
                withFilterTags
                withDateFilter
                includeFilters={["vendors", "accounts", "costCodes"]}
                dateProps={{
                  grow: true,
                  maxWidth: "auto",
                }}
                filterProps={{
                  grow: true,
                  maxWidth: "auto",
                }}
                onFiltersChange={onFiltersChange}
                renderAfter={() => (
                  <Flex width="400px" gap="md" shrink={false}>
                    {hasFilters && (
                      <Button onClick={onClearFilters}>Clear filters</Button>
                    )}
                    <Flex gap="md" width="full" justify="flex-end">
                      {data?.results.length ? (
                        <DownloadButton
                          mode={{
                            all: { enabled: true, children: "Download" },
                            selection: { enabled: false },
                          }}
                          size="md"
                          withDate
                          onDownload={onDownload}
                          data-testid="budget"
                        />
                      ) : null}
                      {selected.length > 0 && (
                        <Dropdown placement="bottom-end">
                          <DropdownTrigger
                            as={Button}
                            color="primary"
                            data-testid={`${status}-actions-trigger`}
                          >
                            Actions
                            <Icon name="ellipsis-vertical" variant="solid" />
                          </DropdownTrigger>
                          <DropdownList>
                            <DropdownItem>
                              <BatchApplyObject
                                onChange={onBatchApply}
                                includeVendors={false}
                                accountFilters={{
                                  only_line_item_accounts: true,
                                }}
                                includeBillableStatus
                              />
                            </DropdownItem>
                          </DropdownList>
                        </Dropdown>
                      )}
                    </Flex>
                  </Flex>
                )}
              />
            </Flex>
            <Table
              id="job-transactions-dialog-table"
              size="sm"
              loading={isFetching}
              data={data?.results}
              select={select}
              columns={columns}
              maxHeight="500px"
              pagination={{
                page: pagination.page,
                total: data?.count ?? 0,
                perPage: pagination.perPage,
                onChange: pagination.setPage,
              }}
              data-testid="transaction-table"
              emptyState={emptyState}
            />
          </Flex>
        </DialogContent>
      </Dialog>
    </>
  );
};
