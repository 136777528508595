import { useCallback, useEffect, useId, useMemo, useRef } from "react";
import { toast } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  handleErrors,
  isNonFieldErrors,
  parseCustomErrors,
} from "@api/handle-errors";
import { transformErrorToCustomError } from "@api/handle-errors";
import type { PaymentTerm, Vendor } from "@api/vendors/types";
import type { Identifiable, Option } from "@shared/types";
import { api } from "@store/api-simplified";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useDrawerVisibility } from "@store/ui";
import { BasePermissions, useUserInfo } from "@store/user";
import type {
  ChangeSet,
  EditDocument,
  SavedDocument,
  Stage,
  VirtualDocument,
} from "@store/vendors/types";
import { summarizeResults } from "@utils/all-settled";
import { isAccount } from "@utils/is-account";
import { isCostCode } from "@utils/is-cost-code";
import { dispatchAndCollate } from "@utils/thunk";

import { refetchCurrentBill } from "../billSlice";
import { refetchCurrentExpense } from "../expenses/thunks";
import { refetchCurrentPurchaseOrder } from "../purchaseOrderSlice";

import {
  createVendor,
  resetLastCommit,
  setAchField,
  setAddressField,
  setField,
  setIsSubmitting,
} from "./actions";
import {
  byCreationId,
  selectHasEmailChange,
  selectIsSubmitting,
  selectUnsavedVendorInfoOrBankingChanges,
  selectVendorNeverSaved,
  vendorSelector,
} from "./selectors";
import { commitVendor, fetchById, updateVendor } from "./thunks";
import { commitAchInfo } from "./thunks-ach";
import {
  addDocument,
  removeDocument,
  syncDocuments,
  toggleEditDocument,
} from "./thunks-document";

type UseVendorActionParams = {
  onDrawerClose?: () => void;
};

export const useVendorAction = ({
  onDrawerClose,
}: UseVendorActionParams = {}) => {
  const creationId = useId();
  const dispatch = useAppDispatch();

  const isVisibleRef = useRef(false);

  const { setVisible, visible, state } = useDrawerVisibility("vendor");

  const neverSaved = useAppSelector(selectVendorNeverSaved);
  const { info: vendor } = useAppSelector(vendorSelector);
  const hasUnsavedInfoOrBankingChanges = useAppSelector(
    selectUnsavedVendorInfoOrBankingChanges
  );

  useEffect(() => {
    if (!visible && isVisibleRef.current) {
      onDrawerClose?.();
      isVisibleRef.current = false;
    }
  }, [visible, onDrawerClose]);

  const create = useCallback(
    (displayName = "") => {
      // TODO permission check here
      dispatch(createVendor({ displayName }, { creationId }));
      isVisibleRef.current = true;
      setVisible(true);
    },
    [dispatch, setVisible, creationId]
  );

  const fetchVendorById = useCallback(
    async (id: string | number, initialStage: Stage = "info") => {
      if (!id) return;
      await dispatch(fetchById({ id, initialStage }));
    },
    [dispatch]
  );

  const saveVendor = useCallback(async (): Promise<boolean> => {
    if (!hasUnsavedInfoOrBankingChanges || state.hasUnsavedDocuments) {
      return true;
    }

    const actions: any[] = [];
    const dependentActions: any[] = [];

    // vendors have information that is stored in different places
    // so, we need to collate the changes and dispatch them in the correct order
    // without issuing unnecessary requests
    const { info, banking } = hasUnsavedInfoOrBankingChanges;

    if (info || neverSaved) {
      actions.push(commitVendor());
    }

    if (banking) {
      dependentActions.push(commitAchInfo());
    }

    if (dependentActions.length) {
      actions.push(dependentActions);
    }

    dispatch(setIsSubmitting(true));

    const { rejected: issues } = await dispatchAndCollate(dispatch, actions);

    dispatch(setIsSubmitting(false));

    if (issues.length) {
      issues.forEach((issue) => {
        if (isNonFieldErrors(issue.payload)) handleErrors(issue.payload);
      });
    } else {
      /**
       * @todo move async logic to live on redux RTK to
       * avoid this kind of workaround to invalidate cache
       */
      dispatch(
        api.util.invalidateTags([
          "Vendors",
          "VendorsSimplified",
          "CostCodesAccountsSimplified",
        ])
      );

      /**
       * We need this logic below to make sure that we update current transaction
       * to have the latest vendor info, it only runs if this action is called inside a transaction
       */
      dispatch(refetchCurrentBill(["vendor"]));
      dispatch(refetchCurrentExpense(["vendor"]));
      dispatch(refetchCurrentPurchaseOrder(["vendor"], true));

      fetchVendorById(vendor.id);

      toast.success(
        `Vendor ${vendor.displayName} ${vendor.url ? "updated" : "created"}`
      );
    }

    return issues.length === 0;
  }, [
    hasUnsavedInfoOrBankingChanges,
    state.hasUnsavedDocuments,
    neverSaved,
    dispatch,
    fetchVendorById,
    vendor.id,
    vendor.displayName,
    vendor.url,
  ]);

  const updateVendors = useCallback(
    async (payload: Vendor[]): Promise<boolean> => {
      const requests = payload.map(async (vendor) => {
        try {
          await dispatch(updateVendor(vendor)).unwrap();
        } catch (error) {
          throw transformErrorToCustomError({
            error: { data: error },
            extra: { displayName: vendor.displayName },
            render: (message) => `${message} on the following vendors:`,
          });
        }
      });

      const { success, errorResponses } = summarizeResults(
        await Promise.allSettled(requests)
      );

      dispatch(api.util.invalidateTags(["CostCodesAccountsSimplified"]));

      const enhancedErrors = parseCustomErrors({
        errors: errorResponses,
        render: ({ isFirst, message, displayName }) =>
          `${message}${isFirst ? "" : ","} ${displayName ?? "UNKNOWN"}`,
      });

      if (enhancedErrors.length) {
        enhancedErrors.forEach((error) =>
          handleErrors(error, { maxWidth: 800, truncate: 2 })
        );
      }

      if (success) {
        toast.success(`${payload.length} vendors updated successfully`);
        dispatch(api.util.invalidateTags(["Vendors"]));
        return true;
      }

      return false;
    },
    [dispatch]
  );

  const showVendorById = useCallback(
    async (id: string, initialStage: Stage = "info") => {
      fetchVendorById(id, initialStage);
      isVisibleRef.current = true;
      setVisible(true);
    },
    [fetchVendorById, setVisible]
  );

  const markRefreshHandled = useCallback(() => {
    dispatch(resetLastCommit());
  }, [dispatch]);

  const setAccountNumber = useCallback(
    (accountNumber: string) => {
      dispatch(setAchField({ accountNumber }));
    },
    [dispatch]
  );

  const setDisplayName = useCallback(
    (displayName: string) => {
      dispatch(setField({ displayName }));
    },
    [dispatch]
  );

  const setPhoneNumber = useCallback(
    (phoneNumber: string) => {
      dispatch(setField({ phoneNumber }));
    },
    [dispatch]
  );

  const setEmail = useCallback(
    (email: string) => {
      dispatch(setField({ email }));
    },
    [dispatch]
  );

  const setDefaultPaymentDays = useCallback(
    (defaultPaymentDays: number | null) => {
      dispatch(setField({ defaultPaymentDays }));
    },
    [dispatch]
  );

  const setCity = useCallback(
    (city: string) => {
      dispatch(setAddressField({ city }));
    },
    [dispatch]
  );

  const setState = useCallback(
    (state: string) => {
      dispatch(setAddressField({ state }));
    },
    [dispatch]
  );

  const setDefaultCostCodeAccount = useCallback(
    (_: string, option?: Option) => {
      dispatch(
        setField({
          defaultItem: isCostCode(option)
            ? { displayName: option.label, url: option.value }
            : null,
        })
      );
      dispatch(
        setField({
          defaultAccount: isAccount(option)
            ? { displayName: option.label, url: option.value }
            : null,
        })
      );
    },
    [dispatch]
  );

  const setDefaultCostCodeAccounts = useCallback(
    (_: string[], options: Option[]) => {
      dispatch(
        setField({
          defaultItems: (options || []).filter(isCostCode).map((option) => ({
            displayName: option.label,
            url: option.value,
          })),
        })
      );
      dispatch(
        setField({
          defaultAccounts: (options || []).filter(isAccount).map((option) => ({
            displayName: option.label,
            url: option.value,
          })),
        })
      );
    },
    [dispatch]
  );

  const setCommonVendor = useCallback(
    (_: string, option?: Option) => {
      dispatch(setField({ commonVendor: option?.value || null }));
    },
    [dispatch]
  );

  const setRestrictedToContentTypes = useCallback(
    (val: string[]) => {
      dispatch(setField({ types: val }));
    },
    [dispatch]
  );

  const setAddressLine1 = useCallback(
    (line1: string) => {
      dispatch(setAddressField({ line1 }));
    },
    [dispatch]
  );

  const setAddressLine2 = useCallback(
    (line2: string) => {
      dispatch(setAddressField({ line2 }));
    },
    [dispatch]
  );

  const setPaymentTerm = useCallback(
    (val: string, option?: Option<PaymentTerm>) => {
      if (!option) return;
      dispatch(setField({ paymentTerm: option.value }));
    },
    [dispatch]
  );

  const setPostalCode = useCallback(
    (postalCode: string) => {
      dispatch(setAddressField({ postalCode }));
    },
    [dispatch]
  );

  const setRoutingNumber = useCallback(
    (routingNumber: string) => {
      dispatch(setAchField({ routingNumber }));
    },
    [dispatch]
  );

  const setTaxId = useCallback(
    (taxId: string) => {
      dispatch(setField({ taxId }));
    },
    [dispatch]
  );

  return {
    create,
    creationId,
    fetchById: fetchVendorById,
    markRefreshHandled,
    showVendorById,
    setAccountNumber,
    setAddressLine1,
    setAddressLine2,
    setRestrictedToContentTypes,
    setDisplayName,
    setPhoneNumber,
    setEmail,
    setDefaultPaymentDays,
    setCity,
    setPaymentTerm,
    setPostalCode,
    setRoutingNumber,
    setState,
    setDefaultCostCodeAccount,
    setDefaultCostCodeAccounts,
    setCommonVendor,
    setTaxId,
    saveVendor,
    updateVendors,
  };
};

export const useVendorInfo = () => {
  const {
    state: drawerState,
    visible: drawerOpen,
    setState: drawerSetState,
  } = useDrawerVisibility("vendor");
  const needsRefresh = useAppSelector((state) => state.vendors.needsRefresh);
  const { hasPermission } = useUserInfo();
  const hasUnsavedInfoOrBankingChanges = useAppSelector(
    selectUnsavedVendorInfoOrBankingChanges
  );
  const hasUnsavedChanges =
    !!hasUnsavedInfoOrBankingChanges || !!drawerState.hasUnsavedDocuments;

  const hasEmailChange = useAppSelector(selectHasEmailChange);

  const isSubmitting = useAppSelector(selectIsSubmitting);

  const canManageNonPaymentInfo = useMemo(
    () => hasPermission(BasePermissions.MANAGE_NON_PAYMENT_VENDORS),
    [hasPermission]
  );

  const canManagePaymentInfo = useMemo(
    () => hasPermission(BasePermissions.MANAGE_PAYMENT_VENDORS),
    [hasPermission]
  );

  const setHasUnsavedDocuments = useEvent((hasUnsavedDocuments: boolean) => {
    drawerSetState({ ...drawerState, hasUnsavedDocuments });
  });

  return {
    byCreationId,
    needsRefresh,
    drawerOpen,
    isSubmitting,
    hasEmailChange,
    hasUnsavedChanges,
    hasUnsavedDocuments: drawerState.hasUnsavedDocuments,
    setHasUnsavedDocuments,
    canManageNonPaymentInfo,
    canManagePaymentInfo,
    hasUnsavedInfoOrBankingChanges,
  };
};

export const useDocumentAction = () => {
  const dispatch = useAppDispatch();

  const add = useCallback(
    (document: VirtualDocument) => dispatch(addDocument(document)),
    [dispatch]
  );

  const edit = useCallback(
    (id: string | number) => dispatch(toggleEditDocument(id)),
    [dispatch]
  );

  const remove = useCallback(
    (document: Identifiable) => dispatch(removeDocument(document)),
    [dispatch]
  );

  const sync = useCallback(
    async (diff: ChangeSet<VirtualDocument, SavedDocument, EditDocument>) => {
      dispatch(setIsSubmitting(true));
      await dispatch(syncDocuments(diff));
      dispatch(api.util.invalidateTags(["Vendors"]));
      dispatch(setIsSubmitting(false));
    },
    [dispatch]
  );

  return {
    addDocument: add,
    toggleEditDocument: edit,
    removeDocument: remove,
    syncDocuments: sync,
  };
};
