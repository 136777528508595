import React, {
  type FocusEventHandler,
  type ForwardedRef,
  forwardRef,
  type KeyboardEvent,
  memo,
  type MouseEvent,
  type MouseEventHandler,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
} from "react";
import {
  type Components,
  type SizeFunction,
  Virtuoso,
  type VirtuosoHandle,
} from "react-virtuoso";
import { Command, usePopoverStore } from "@ariakit/react";
import cn from "clsx";

import { useDebouncedValue } from "../../hooks/use-debounced-value";
import { useDeepMemo } from "../../hooks/use-deep-memo";
import { useEvent } from "../../hooks/use-event";
import { useOnInteractOutside } from "../../hooks/use-on-interact-outside";
import { useResponsiveProp } from "../../hooks/use-responsive-prop";
import { is } from "../../utils/is";
import { isOption } from "../../utils/is-option";
import { mergeRefs } from "../../utils/merge-refs";
import { suffixify } from "../../utils/suffixify";
import { Button } from "../button";
import { Checkbox } from "../checkbox";
import { Flex } from "../flex";
import { Icon } from "../icon";
import { useProvider } from "../provider/provider-context";
import { Tag } from "../tag";
import { Text } from "../text";
import { TextField, type TextFieldRef } from "../text-field";
import { Tooltip } from "../tooltip";

import { ComboboxContext } from "./combobox-context";
import { ComboBoxHighlight } from "./combobox-highlight";
import { ComboBoxList } from "./combobox-list";
import type {
  Action,
  ClassName,
  Context,
  EnhancedOption,
  GroupedOption,
  Mode,
  Option,
  Props,
  Ref,
  State,
} from "./types";
import {
  getDefaultOptionHeight,
  getDepth,
  getGroupOrder,
  getInitialState,
  getMatch,
  getNextOptionIndex,
  getPreviousOptionIndex,
  isMultipleOption,
  isSingleOption,
  optimizedStateUpdate,
  orderByGroup,
  search,
} from "./utils";
import styles from "./combobox.module.css";

const OPTION_PADDING = {
  sm: "var(--spacing-md)",
  md: "var(--spacing-lg)",
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "set-index": {
      return optimizedStateUpdate(state, { index: action.payload });
    }
    case "set-group": {
      const data = state.data.filter(
        (option) => option.groupLabel === action.payload
      );

      const mode =
        action.payload || state.queryMode === "search"
          ? "option-group-interacting"
          : "group-interacting";

      const index = getNextOptionIndex({ data, mode });

      return optimizedStateUpdate(state, {
        mode,
        index,
        group: action.payload,
      });
    }
    case "set-option": {
      if (is.array(action.payload)) {
        const option = action.payload.reduce((acc, value) => {
          const nextOption = isOption(value)
            ? value
            : state.data.find((option) => option.value === value);
          return nextOption ? [...acc, nextOption] : acc;
        }, [] as Option[]);

        return optimizedStateUpdate(state, {
          mode:
            state.mode === "option-group-interacting" && !state.group
              ? "group-interacting"
              : state.mode,
          query: "",
          option,
          queryMode: "idle",
        });
      }

      const option = isOption(action.payload)
        ? action.payload
        : state.data.find((option) => option.value === action.payload);

      return optimizedStateUpdate(state, {
        query: option?.label || "",
        group: option?.groupLabel || "",
        option,
        queryMode: "idle",
      });
    }
    case "set-query": {
      let mode = state.mode;

      if (mode === "group-interacting") {
        mode = "option-group-interacting";
      } else if (mode === "option-group-interacting" && !action.payload) {
        mode = "group-interacting";
      }

      return optimizedStateUpdate(state, {
        mode,
        group: "",
        index: 0,
        query: action.payload,
        option:
          action.payload === "" && !Array.isArray(state.option)
            ? undefined
            : state.option,
        queryMode: action.payload ? "search" : "idle",
      });
    }
    case "set-open": {
      const mode =
        state.mode === "option-group-interacting" && !state.group
          ? "group-interacting"
          : state.mode;

      return state.open === action.payload
        ? state
        : optimizedStateUpdate(state, { mode, open: action.payload });
    }
    case "set-data": {
      return optimizedStateUpdate(state, {
        data: action.payload.filter(
          (option) =>
            typeof option === "object" &&
            "label" in option &&
            option.label !== undefined &&
            "value" in option
        ),
      });
    }
    case "set-mode": {
      if (action.payload === "idle") {
        const query =
          state.option && "label" in state.option ? state.option.label : "";

        const group =
          state.option && "groupLabel" in state.option
            ? state.option.groupLabel || ""
            : "";

        return optimizedStateUpdate(state, {
          open: false,
          query,
          group,
          index: 0,
          queryMode: "idle",
        });
      }

      return optimizedStateUpdate(state, { mode: action.payload });
    }
    case "set-tall-option-height": {
      return optimizedStateUpdate(state, { tallOptionHeight: action.payload });
    }
    /* c8 ignore next 3 */
    default: {
      return state;
    }
  }
};

const isActionVisible = (
  context: Pick<
    Context,
    "action" | "mode" | "count" | "group" | "index" | "query"
  >
): context is Omit<Context, "action"> & {
  action: Exclude<Context["action"], undefined>;
} => {
  if (!context.action) return false;

  if (typeof context.action.mode === "function") {
    return context.action.mode({
      mode: context.mode,
      count: context.count,
      group: context.group,
      index: context.index,
      query: context.query,
    });
  }

  if (context.action.mode === "hide") return false;

  if (context.action.mode === "always") return true;

  if (context.action.mode === "group" && context.mode === "group-interacting") {
    return true;
  }

  if (
    context.action.mode === "option" &&
    ["option-interacting", "option-group-interacting"].includes(context.mode)
  ) {
    return true;
  }

  if (context.action.mode === "empty" && context.count == 0) return true;

  if (
    context.action.mode === "no-match" &&
    context.query &&
    context.count == 0
  ) {
    return true;
  }

  if (context.action.mode === "has-query" && context.query) return true;

  return false;
};

const Header = memo(({ context }: { context: Context }) => {
  return (
    <>
      {context.header ? (
        <div className={cn(styles["option"], styles["-header"])}>
          {context.header}
        </div>
      ) : null}
      {context &&
      context.group &&
      context.mode === "option-group-interacting" ? (
        <Command
          id={suffixify(context.id, "option(-1)")}
          role="button"
          render={<div />}
          onClick={context.onGroupClear}
          className={cn(
            context.className.option,
            styles["option"],
            styles["-back"]
          )}
          focusable={false}
          data-testid={suffixify(context.testId, "back-button")}
          data-active={context.index === -1}
          onMouseOver={context.curriedSetIndex(-1)}
          clickOnSpace={false}
        >
          <Icon name="chevron-left" /> Back
        </Command>
      ) : null}
    </>
  );
});

Header.displayName = "Header";

const Footer = memo(({ context }: { context: Context }) => {
  if (!context) return null;

  const isEmpty = context.count === 0;

  const hasAction = isActionVisible(context);

  return (
    <>
      {isEmpty && (
        <div
          className={cn(styles["option"], {
            [styles["-empty-action"]]: isEmpty && hasAction,
            [styles["-empty-no-action"]]: isEmpty && !hasAction,
          })}
          data-testid={suffixify(context.testId, "empty-message")}
        >
          {context.query
            ? context.renderNoMatch(context.query)
            : context.emptyMessage}
        </div>
      )}
      {hasAction && (
        <div className={cn(styles["option"], styles["-action"])}>
          <Command
            id={suffixify(context.id, `option(${context.count})`)}
            role="button"
            render={<Button block size="sm" variant="ghost" />}
            onClick={context.action.onClick}
            focusable={false}
            onMouseOver={context.curriedSetIndex(context.count)}
            data-testid={suffixify(context.testId, "action-button")}
            data-active={context.index === context.count}
            clickOnSpace={false}
          >
            {context.action.icon && <Icon name={context.action.icon} />}
            {context.action.children}
          </Command>
        </div>
      )}
    </>
  );
});

Footer.displayName = "Footer";

const COMPONENTS = { Header, Footer } as Components<Option, Context>;

const EMPTY_DATA: Option[] = [];

const ComboBox = <IsMultiple extends boolean = false>(
  {
    id,
    data = EMPTY_DATA,
    size: rawSize,
    flip = false,
    label,
    value,
    group,
    header,
    action,
    suffix,
    inline = false,
    portal = false,
    onFocus,
    loading,
    listSize = 9,
    onChange,
    disabled,
    multiple,
    placement = "bottom-start",
    className,
    onKeyDown,
    addonAfter,
    placeholder = "Select",
    emptyMessage = "No options available",
    renderOption = (option, { data, highlightedLabel }) => {
      const parentLabel = data.find(
        (item) => item.value === option.parent
      )?.label;

      return (
        <Flex align="center" gap="lg">
          {option.multiple && (
            <Checkbox aria-hidden="true" checked={option.selected} />
          )}
          <Flex direction="column">
            <Flex gap="sm" align="center">
              {highlightedLabel}
              {typeof option.disabled === "string" && (
                <Tooltip
                  as={Icon}
                  size="sm"
                  name="exclamation-circle"
                  message={option.disabled}
                />
              )}
            </Flex>
            {option.groupLabel && (
              <Text
                size="xs"
                color={
                  option.selected && !option.multiple
                    ? undefined
                    : "neutral-600"
                }
              >
                {option.groupLabel}
              </Text>
            )}
            {parentLabel && (
              <Text
                size="xs"
                color={
                  option.selected && !option.multiple
                    ? undefined
                    : "neutral-600"
                }
              >
                Parent: {parentLabel}
              </Text>
            )}
          </Flex>
        </Flex>
      );
    },
    errorMessage,
    renderNoMatch = (query) => `No results matching “${query}”`,
    helperMessage,
    warningMessage,
    "data-testid": testId,
    renderMultipleValue = (option) => ({
      color: "neutral",
      children: option.label,
    }),
    hideOnInteractOutside,
    includeValueWhenMissing = true,
    ...props
  }: Props<IsMultiple>,
  ref: ForwardedRef<Ref>
) => {
  const { search: providerSearch } = useProvider();

  const memoizedData = useDeepMemo(() => data, [data]);

  const memoizedValue = useDeepMemo(() => value, [value]);

  const size = useResponsiveProp(rawSize, "md");

  const [state, dispatch] = useReducer(
    reducer,
    getInitialState({ size, data: memoizedData, multiple })
  );

  const [debouncedQuery] = useDebouncedValue(
    state.query,
    import.meta.env.MODE === "test" ? 0 : 300
  );

  const isSearching =
    state.queryMode === "search" && debouncedQuery !== state.query;

  const internalId = useId();
  const enhancedId = id ?? internalId;

  const listboxRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const internalRef = useRef<Ref>(null);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const beforeInputRef = useRef<HTMLDivElement>(null);

  const isInteractive = !disabled && !loading;

  const optionHeight = useMemo(() => getDefaultOptionHeight(size), [size]);

  const maxHeightList = optionHeight * listSize + optionHeight / 2;

  const enhancedClassName = useMemo<ClassName>(() => {
    if (typeof className === "string") {
      return {
        list: "",
        option: "",
        wrapper: className,
        combobox: "",
      };
    }

    return {
      list: className?.list,
      option: className?.option,
      wrapper: className?.wrapper,
      combobox: className?.combobox,
    };
  }, [className]);

  const popoverProps = useMemo(
    () => ({ open: state.open, placement }),
    [state.open, placement]
  );

  const popoverStore = usePopoverStore(popoverProps);

  const popoverStoreRender = popoverStore.render;

  const groupData = useMemo(() => {
    const seen = new Set<string>();
    const result: { label: string; value: string }[] = [];

    for (const option of state.data) {
      if (!option.groupLabel || seen.has(option.groupLabel)) {
        continue;
      }

      seen.add(option.groupLabel);
      result.push({ label: option.groupLabel, value: option.groupLabel });
    }
    return result;
  }, [state.data]);

  const hasGroupOptions = state.data.some((option) => !!option.groupLabel);

  /**
   * @todo We will need to check other approach to solve hierarchy
   * fuse search, today we show all results and to make it functional
   * we scroll to results, but that's not best way to handle it.
   */
  const options = useMemo(() => {
    const groupsOrder = getGroupOrder(state.data);

    let haystack = state.data.filter((option) =>
      state.group ? option.groupLabel == state.group : true
    ) as EnhancedOption[];

    const shouldOrderByGroup = group?.variant === "section" && hasGroupOptions;

    if (!(state.queryMode === "idle" || !debouncedQuery)) {
      haystack = search(haystack, debouncedQuery, providerSearch.threshold);
    }

    return shouldOrderByGroup ? orderByGroup(haystack, groupsOrder) : haystack;
  }, [
    group,
    state.data,
    state.group,
    debouncedQuery,
    state.queryMode,
    hasGroupOptions,
    providerSearch.threshold,
  ]);

  const interact = useEvent(() => {
    const shouldSelectGroup =
      group?.variant !== "section" && hasGroupOptions && !state.group;

    let mode: Mode = shouldSelectGroup
      ? "group-interacting"
      : "option-interacting";

    if (group?.variant !== "section" && hasGroupOptions && !shouldSelectGroup) {
      mode = "option-group-interacting";
    }

    dispatch({ type: "set-mode", payload: mode });

    requestAnimationFrame(() => internalRef.current?.select());
  });

  const onActionClick = useEvent(() => {
    if (action?.hideOnClick !== false) hide();

    action?.onClick(state.query);
  });

  const enhancedData = useMemo(
    () => (state.mode === "group-interacting" ? groupData : options),
    [options, state.mode, groupData]
  );

  const enhancedAction = useMemo<Context["action"]>(
    () =>
      action
        ? { mode: "option", ...action, onClick: onActionClick }
        : undefined,
    [action, onActionClick]
  );

  const count = enhancedData.length;

  const hasAction =
    enhancedAction &&
    isActionVisible({
      mode: state.mode,
      group: state.group,
      index: state.index,
      query: state.query,
      count,
      action: enhancedAction,
    });

  const show = useEvent((force?: boolean) => {
    if (isInteractive || force) dispatch({ type: "set-open", payload: true });
  });

  const hide = useEvent(() => {
    dispatch({ type: "set-mode", payload: "idle" });
  });

  const onWrapperClick = useEvent<MouseEventHandler>((e) => {
    if (!state.open) {
      show();
      if (isInteractive) interact();
    } else if (
      !beforeInputRef.current ||
      !beforeInputRef.current.contains(e.target as Node)
    ) {
      hide();
    }
  });

  const enhancedOnKeyDown = useEvent((e: KeyboardEvent<TextFieldRef>) => {
    if (
      e.code?.startsWith("Key") ||
      e.code?.startsWith("Digit") ||
      e.code?.startsWith("Numpad") ||
      ["ArrowUp", "ArrowDown", "Backspace"].includes(e.code)
    ) {
      show();
    }

    const isRemovingMultipleOption =
      state.query === "" &&
      multiple &&
      ["Backspace", "Delete"].includes(e.code);

    const isOpen = state.open || inline;

    const isSelecting =
      isOpen &&
      (e.code === "Enter" ||
        (e.code === "Tab" && !multiple && state.mode !== "group-interacting"));

    if (isRemovingMultipleOption) {
      const options = state.option as Option[];
      const lastOption = options[options.length - 1];
      lastOption && curriedSelectOption(lastOption)();
    } else if (e.code === "Escape") {
      hide();
    } else if (isSelecting && !isSearching) {
      if (e.code === "Enter") e.preventDefault();

      if (state.mode === "group-interacting") {
        const payload = groupData[state.index].value;
        dispatch({ type: "set-group", payload });
        group?.onChange?.(payload);
      } else if (state.index === -1) {
        dispatch({ type: "set-group", payload: "" });
        group?.onChange?.("");
      } else if (state.index === count && hasAction) {
        onActionClick();
      } else {
        curriedSelectOption(options[state.index])();
      }
    } else if (["ArrowUp", "ArrowDown"].includes(e.code)) {
      let payload = getNextOptionIndex({
        mode: state.mode,
        data: enhancedData,
        strict: !hasAction,
      });

      const minIndex =
        state.mode === "option-group-interacting" && state.group ? -1 : payload;

      let maxIndex = count - 1;

      if (hasAction) maxIndex += 1;

      if (e.code === "ArrowUp" && state.open) {
        e.preventDefault();

        payload = Math.max(
          minIndex,
          getPreviousOptionIndex({
            mode: state.mode,
            data: enhancedData,
            currentIndex: state.index,
          })
        );
      } else if (e.code === "ArrowDown" && state.open) {
        e.preventDefault();

        payload = Math.min(
          maxIndex,
          getNextOptionIndex({
            mode: state.mode,
            data: enhancedData,
            strict: !hasAction,
            currentIndex: state.index,
          })
        );
      }

      if (payload === -1) {
        virtuosoRef.current?.scrollTo({ top: 0 });
        dispatch({ type: "set-index", payload });
      } else {
        virtuosoRef.current?.scrollIntoView({
          index: payload,
          align: "center",
          behavior: "auto",
        });
        dispatch({ type: "set-index", payload });
      }
    } else if (e.code === "ArrowRight" && state.mode === "group-interacting") {
      const payload = groupData[state.index].value;
      dispatch({ type: "set-group", payload });
      group?.onChange?.(payload);
    } else if (
      e.code === "ArrowLeft" &&
      state.mode === "option-group-interacting" &&
      state.index === -1
    ) {
      dispatch({ type: "set-group", payload: "" });
      group?.onChange?.("");
    } else if (e.code === "Tab") {
      hide();
    }

    onKeyDown?.(e);

    return false;
  });

  const enhancedPlaceholder =
    multiple && (state.option as Option[])?.length > 0 ? "" : placeholder;

  const enhancedOnFocus = useEvent<FocusEventHandler<Ref>>((e) => {
    if (isInteractive) interact();
    onFocus?.(e);
  });

  const parseHideOnInteractOutside = useCallback(() => {
    if (hideOnInteractOutside === undefined) return true;

    return is.function(hideOnInteractOutside)
      ? hideOnInteractOutside()
      : hideOnInteractOutside;
  }, [hideOnInteractOutside]);

  const onInputChange = useEvent((value: string) => {
    dispatch({ type: "set-query", payload: value });

    if (value === "" && !multiple) {
      (onChange as Props<false>["onChange"])?.("", undefined);
    }
  });

  const onHeightChange = useEvent((height: number) => {
    if (!state.open) return;

    listboxRef.current?.style.setProperty(
      "--combobox-listbox-height",
      `${height}px`
    );
  });

  const enhancedSuffix = useMemo(
    () =>
      suffix !== undefined ? (
        suffix
      ) : (
        <Icon size={size} name={state.open ? "chevron-up" : "chevron-down"} />
      ),
    [size, suffix, state.open]
  );

  const listStyle = useMemo(
    () => ({
      "--combobox-option-padding": OPTION_PADDING[size],
      "--combobox-listbox-max-height": `${maxHeightList}px`,
    }),
    [maxHeightList, size]
  );

  const enhancedLabel = useMemo(() => {
    const correctTypedLabel = label as Props<IsMultiple>["label"];
    const correctTypedOption = state.option as IsMultiple extends true
      ? Option[]
      : Option | undefined;

    if (typeof correctTypedLabel !== "function") return correctTypedLabel;

    return correctTypedLabel({
      group: state.group,
      option: correctTypedOption,
    });
  }, [label, state.option, state.group]);

  const curriedSelectOption = useCallback(
    (option?: Option) => () => {
      if (!option || option.disabled) return;

      if (multiple) {
        const previousOptions = state.option as Option[];

        const isSelected = previousOptions.some(
          (item) => item.value == option.value
        );

        const options = isSelected
          ? previousOptions.filter((item) => item.value !== option.value)
          : [...previousOptions, option];

        memoizedValue === undefined &&
          dispatch({ type: "set-option", payload: options });
        (onChange as Props<true>["onChange"])?.(
          options.map((option) => option.value),
          options
        );
      } else {
        hide();
        memoizedValue === undefined &&
          dispatch({ type: "set-option", payload: option });
        (onChange as Props<false>["onChange"])?.(option.value, option);
      }
    },
    [hide, memoizedValue, multiple, onChange, state.option]
  );

  const curriedOnSelectOptionGroup = useCallback(
    (value: GroupedOption["groupLabel"]) => (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      dispatch({ type: "set-group", payload: value });
      group?.onChange?.(value);
    },
    [group]
  );

  const onGroupClear = useEvent<MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();
    dispatch({ type: "set-group", payload: "" });
    group?.onChange?.("");
  });

  const renderBeforeInput = useMemo(() => {
    if (!multiple || loading) return undefined;

    // eslint-disable-next-line react/display-name
    return () => (
      <div
        ref={beforeInputRef}
        className={styles["render-before-input-wrapper"]}
        data-length={state.option?.length ?? 0}
      >
        {(state.option as Option[]).map((option) => (
          <Tag
            key={option.value}
            size={size}
            onRemove={curriedSelectOption(option)}
            data-testid={suffixify(testId, "tag")}
            {...renderMultipleValue(option)}
          >
            {option.label}
          </Tag>
        ))}
      </div>
    );
  }, [
    multiple,
    loading,
    state.option,
    size,
    curriedSelectOption,
    testId,
    renderMultipleValue,
  ]);

  const enhancedValue = useMemo(() => {
    if ((state.open || inline) && state.query) {
      return state.query;
    }

    if (!multiple && isOption(state.option)) {
      return state.option.label;
    }

    return "";
  }, [inline, multiple, state.open, state.option, state.query]);

  /**
   * We need it to not blur input if click on scrollbar
   */
  const onPopoverMouseDown = useEvent<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
    }
  );

  const curriedSetIndex = useCallback(
    (index: number) => () => {
      dispatch({ type: "set-index", payload: index });
    },
    []
  );

  const itemContent = useCallback(
    (index: number) => {
      const indexSelected = index === state.index;

      if (state.mode === "group-interacting") {
        const option = groupData[index];

        return (
          <Command
            render={<div />}
            id={suffixify(enhancedId, `option(${index})`)}
            role="option"
            onClick={curriedOnSelectOptionGroup(option.value)}
            className={cn(
              enhancedClassName.option,
              styles["option"],
              styles["-suffix"]
            )}
            focusable={false}
            onMouseOver={curriedSetIndex(index)}
            data-active={indexSelected}
            clickOnSpace={false}
          >
            {option.label} <Icon name="chevron-right" />
          </Command>
        );
      }

      const option = options[index];
      const isFirstOfSectionGroup =
        option.groupLabel !== options[index - 1]?.groupLabel;
      const depth = getDepth(option, options);
      const selected = Array.isArray(state.option)
        ? state.option.some((item) => item.value === option.value)
        : option.value == state.option?.value;
      const highlighted = !!getMatch(option);
      const disabled =
        option.disabled === true || typeof option.disabled === "string";

      return (
        <>
          {group?.variant === "section" && isFirstOfSectionGroup ? (
            <strong className={styles["group-title"]}>
              {isFirstOfSectionGroup ? option.groupLabel : null}
            </strong>
          ) : null}
          <Command
            id={suffixify(enhancedId, `option(${index})`)}
            role="option"
            style={{
              pointerEvents: "initial",
              "--combobox-option-depth": depth,
            }}
            render={<div />}
            onClick={curriedSelectOption(option)}
            aria-disabled={disabled}
            className={cn(enhancedClassName.option, styles["option"], {
              [styles["-multiple"]]: multiple,
            })}
            focusable={false}
            onMouseOver={!disabled ? curriedSetIndex(index) : undefined}
            data-active={indexSelected}
            clickOnSpace={false}
            aria-selected={selected}
            data-highlighted={highlighted}
          >
            {renderOption(
              { ...option, selected, multiple },
              {
                data: options,
                highlightedLabel: <ComboBoxHighlight {...option} />,
              }
            )}
          </Command>
        </>
      );
    },
    [
      curriedOnSelectOptionGroup,
      curriedSelectOption,
      curriedSetIndex,
      enhancedClassName.option,
      enhancedId,
      group?.variant,
      groupData,
      multiple,
      options,
      renderOption,
      state.index,
      state.mode,
      state.option,
    ]
  );

  const atTopStateChange = useEvent((atTop: boolean) => {
    if (atTop) {
      listboxRef.current?.classList.remove(styles["-stuck"]);
    } else {
      listboxRef.current?.classList.add(styles["-stuck"]);
    }
  });

  const itemSize = useEvent<SizeFunction>((el, field) => {
    const rect = el.getBoundingClientRect();

    if (field === "offsetWidth") return rect.width;

    if (state.tallOptionHeight < rect.height) {
      dispatch({ type: "set-tall-option-height", payload: rect.height });
    }

    return rect.height;
  });

  const scrollToFirstSelectedOption = useEvent(() => {
    const index = getNextOptionIndex({
      mode: state.mode,
      data: enhancedData,
      strict: !hasAction,
    });

    const currentIndex = multiple
      ? index
      : options.findIndex(
          (option) => option.value === (state.option as Option)?.value
        );

    requestAnimationFrame(() => {
      const payload = currentIndex !== -1 ? currentIndex : index;

      if (payload > 0) {
        virtuosoRef.current?.scrollIntoView({
          index: payload,
          align: "center",
          behavior: "auto",
        });
      }

      dispatch({ type: "set-index", payload });
    });
  });

  const context = useMemo(
    () => ({
      id: enhancedId,
      mode: state.mode,
      count,
      index: state.index,
      group: state.group,
      query: state.query,
      testId,
      header,
      action: enhancedAction,
      className: enhancedClassName,
      onGroupClear,
      emptyMessage,
      renderNoMatch,
      curriedSetIndex,
    }),
    [
      count,
      curriedSetIndex,
      emptyMessage,
      enhancedAction,
      enhancedClassName,
      enhancedId,
      header,
      onGroupClear,
      renderNoMatch,
      state.group,
      state.index,
      state.mode,
      state.query,
      testId,
    ]
  );

  useOnInteractOutside([wrapperRef, listboxRef], () => {
    if (!state.open) return;

    requestAnimationFrame(() => {
      if (parseHideOnInteractOutside()) hide();
    });
  });

  useEffect(() => {
    if (state.open) scrollToFirstSelectedOption();
  }, [state.open, scrollToFirstSelectedOption]);

  useEffect(() => {
    if (!memoizedData) return;

    /**
     * Since we can pass a value that doesn't exist on the data array
     * we should includes it in the data to make it visible on the ComboBox.
     * It only work if you pass `value` following the Option or Option[] shape.
     */
    let includesData: Option[] = [];

    if (includeValueWhenMissing) {
      if (
        isSingleOption(memoizedValue) &&
        memoizedData.every((item) => item.value !== memoizedValue.value)
      ) {
        includesData = [memoizedValue];
      } else if (isMultipleOption(memoizedValue)) {
        includesData = memoizedValue.filter((item) =>
          memoizedData.every((innerItem) => innerItem.value !== item.value)
        );
      }
    }

    dispatch({ type: "set-data", payload: [...includesData, ...memoizedData] });
  }, [includeValueWhenMissing, memoizedData, memoizedValue]);

  useEffect(() => {
    const hasData = !!state.data.length;
    const hasExternalValue = memoizedValue !== undefined;

    if ((!hasData || !hasExternalValue) && memoizedValue !== "") return;

    dispatch({ type: "set-option", payload: memoizedValue });
  }, [memoizedValue, state.data]);

  useEffect(() => {
    if (!state.open && !state.option && memoizedValue) {
      dispatch({ type: "set-option", payload: memoizedValue });
    }
  }, [memoizedValue, state.open, state.option]);

  useEffect(() => {
    virtuosoRef.current?.scrollTo({ top: 0 });
  }, [state.group]);

  useEffect(() => {
    if (state.open) popoverStoreRender();
  }, [state.open, state.option, state.query, popoverStoreRender]);

  useImperativeHandle(ref, () => {
    const rootEl = internalRef.current!;

    rootEl.show = () => {
      rootEl.focus();
      requestAnimationFrame(() => show(true));
    };

    return rootEl;
  }, [show]);

  return (
    <div className={cn(enhancedClassName.wrapper, styles["combobox-wrapper"])}>
      <div
        ref={wrapperRef}
        onClick={onWrapperClick}
        className={styles["inner"]}
      >
        <TextField
          id={enhancedId}
          ref={mergeRefs(ref, internalRef)}
          role="combobox"
          size={size}
          label={enhancedLabel}
          value={enhancedValue}
          suffix={enhancedSuffix}
          onFocus={enhancedOnFocus}
          loading={loading}
          disabled={disabled}
          onChange={onInputChange}
          onKeyDown={enhancedOnKeyDown}
          className={cn(enhancedClassName.combobox, styles["combobox"], {
            [styles["-filled"]]: multiple
              ? (state.option as Option[])?.length > 0
              : state.option !== undefined,
            [styles[`-${size}`]]: size,
            [styles["-multiple"]]: multiple,
          })}
          addonAfter={addonAfter}
          data-testid={testId}
          placeholder={enhancedPlaceholder}
          containerRef={popoverStore.setAnchorElement}
          errorMessage={state.open || loading ? undefined : errorMessage}
          helperMessage={state.open || loading ? undefined : helperMessage}
          warningMessage={state.open || loading ? undefined : warningMessage}
          aria-haspopup="listbox"
          aria-expanded={state.open}
          aria-controls={suffixify(enhancedId, "listbox")}
          renderBeforeInput={renderBeforeInput}
          aria-activedescendant={suffixify(
            enhancedId,
            `option(${state.index})`
          )}
          triggerChangeOnFocusedUnmount={false}
          {...props}
        />
      </div>
      <ComboboxContext.Provider value={true}>
        <ComboBoxList
          id={suffixify(enhancedId, "listbox")}
          ref={listboxRef}
          flip={flip}
          store={popoverStore}
          style={listStyle}
          inline={inline}
          portal={portal}
          loading={count !== 0 && isSearching}
          className={enhancedClassName.list}
          onMouseDown={onPopoverMouseDown}
        >
          <Virtuoso<EnhancedOption | Option, Context>
            ref={virtuosoRef}
            context={context}
            itemSize={itemSize}
            components={COMPONENTS}
            totalCount={count}
            itemContent={itemContent}
            atTopStateChange={atTopStateChange}
            defaultItemHeight={optionHeight}
            increaseViewportBy={
              import.meta.env.MODE === "test"
                ? Infinity
                : state.tallOptionHeight * (listSize + 20)
            }
            customScrollParent={listboxRef.current!}
            totalListHeightChanged={onHeightChange}
            skipAnimationFrameInResizeObserver
          />
        </ComboBoxList>
      </ComboboxContext.Provider>
    </div>
  );
};

const ForwardedComboBox = forwardRef(ComboBox) as <
  IsMultiple extends boolean = false,
>(
  props: Props<IsMultiple> & { ref?: ForwardedRef<Ref> }
) => ReturnType<typeof ComboBox>;

export { ForwardedComboBox as ComboBox };
