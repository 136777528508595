import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Dialog as AriaKitDialog,
  useDialogStore,
  useStoreState,
} from "@ariakit/react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { useResizeObserver } from "../../hooks/use-resize-observer";
import {
  type ResponsiveProp,
  useResponsiveProp,
} from "../../hooks/use-responsive-prop";
import { debounce } from "../../utils/debounce";
import { getAllFocusableElements } from "../../utils/get-all-focusable-elements";
import { getScrollableParent } from "../../utils/get-scrollable-parent";
import { useProvider } from "../provider/provider-context";

import { DialogProvider } from "./dialog-provider";
import styles from "./dialog.module.css";

type DefaultElement = "div";

type Size = "sm" | "md" | "lg" | "auto";

type Align = "left" | "center";

export type DialogOnCloseHandler = (
  trigger: "close" | "popstate" | "interact-outside"
) => void;

type CommonProps = ComponentPropsWithoutRef<DefaultElement> & {
  show?: boolean;
  /**
   * This prop make the Dialog works like a "non-dialog", you could check
   * what it means with this link https://rawgit.com/w3c/aria-practices/master/aria-practices-DeletedSectionsArchive.html#dialog_nonmodal
   */
  modal?: boolean;
  onClose?: DialogOnCloseHandler;
  hideOnNavigate?: boolean | (() => boolean);
  autoFocusOnHide?: boolean;
};

export type DialogProps = CommonProps & {
  size?: ResponsiveProp<Size>;
  step?: never;
  align?: Align;
  variant: "dialog" | "confirmation";
  placement?: "top" | "center";
};

export type MultiStepDialogProps = Omit<DialogProps, "variant" | "step"> & {
  step: string;
  variant: "multi-step-dialog";
  placement?: "top" | "center";
};

export type DrawerProps = Omit<CommonProps, "placement"> & {
  size?: ResponsiveProp<Exclude<Size, "sm">>;
  step?: never;
  align?: never;
  variant: "drawer";
  placement?: "left" | "right" | "top" | "bottom";
};

export type MultiStepDrawerProps = Omit<DrawerProps, "variant" | "step"> & {
  step: string;
  variant: "multi-step-drawer";
};

export type Props =
  | DialogProps
  | DrawerProps
  | MultiStepDialogProps
  | MultiStepDrawerProps;

type GetDialogSizeProps = { size?: Size; variant: Props["variant"] };

const getDialogSize = ({ size, variant }: GetDialogSizeProps) => {
  const sizingStrategies: { [Key in Props["variant"]]: () => Size } = {
    dialog: () => size || "md",
    drawer: () => size || "md",
    confirmation: () => size || "sm",
    "multi-step-dialog": () => size || "md",
    "multi-step-drawer": () => size || "md",
  };

  return sizingStrategies[variant]();
};

type GetDialogAlignProps = { align?: Align; variant: Props["variant"] };

const getDialogAlign = ({ align, variant }: GetDialogAlignProps) => {
  const alignmentStrategies: { [Key in Props["variant"]]: () => Align } = {
    dialog: () => align ?? "left",
    drawer: () => align ?? "left",
    confirmation: () => align ?? "center",
    "multi-step-dialog": () => align ?? "left",
    "multi-step-drawer": () => align ?? "left",
  };

  return alignmentStrategies[variant]();
};

const ANIMATION_TIMING = 100;

const PERSIST_ELEMENTS_SELECTORS = [
  "[data-scope='toast']",
  "[data-dialog]",
  // Intercom
  "#intercom-frame",
  "#intercom-container",
  "#intercom-modal-container",
];

export const Dialog = ({
  show,
  size: rawSize,
  step,
  modal = true,
  align,
  variant = "dialog",
  onClose,
  children,
  placement,
  className,
  hideOnNavigate = true,
  autoFocusOnHide = true,
  ...props
}: Props) => {
  const size = useResponsiveProp(rawSize);

  const internalRef = useRef<ComponentRef<DefaultElement>>(null);

  const backdropRef = useRef<HTMLDivElement>(null);

  const openLocation = useRef(window.location.href);

  const initialFocusRef = useRef<HTMLElement>(null);

  const animationTimerRef = useRef<ReturnType<typeof setTimeout>>(undefined);

  const scrollableParentRef = useRef<HTMLElement>(null);

  const [innerEl, setInnerEl] = useState<HTMLElement | null>(null);

  const [wrapperEl, setWrapperEl] = useState<HTMLElement | null>(null);

  const dialogStore = useDialogStore();

  const mounted = useStoreState(dialogStore, "mounted");

  const { autoFocus: providerAutoFocus } = useProvider();

  const enhancedSize = useMemo(
    () => getDialogSize({ size, variant }),
    [size, variant]
  );

  const enhancedAlign = useMemo(
    () => getDialogAlign({ align, variant }),
    [align, variant]
  );

  const animating = useStoreState(dialogStore, "animating");

  const enhancedPlacement =
    placement ||
    (["drawer", "multi-step-drawer"].includes(variant) ? "right" : "center");

  const onInteractOutside = useEvent((e) => {
    if (
      animating ||
      (e?.target as HTMLElement)?.closest("#intercom-container-body") ||
      e instanceof FocusEvent
    ) {
      return false;
    }

    onClose?.("interact-outside");
    return true;
  });

  const getPersistentElements = useCallback(
    () => document.querySelectorAll(PERSIST_ELEMENTS_SELECTORS.join(", ")),
    []
  );

  const onResize = useEvent(() => {
    if (!innerEl || !wrapperEl?.classList.contains(styles["-animate"])) return;

    clearTimeout(animationTimerRef.current);

    if (wrapperEl.classList.contains(styles["-disabled"])) {
      wrapperEl.classList.add(styles["-hide"]);
    }

    const { width, height } = innerEl!.getBoundingClientRect();

    const isDrawer = !["drawer", "multi-step-drawer"].includes(variant);
    wrapperEl.style.width = `${width}px`; // eslint-disable-line
    wrapperEl.style.height = isDrawer ? `${height}px` : "100%"; // eslint-disable-line

    animationTimerRef.current = setTimeout(() => {
      wrapperEl.classList.remove(styles["-hide"]);
      wrapperEl.classList.remove(styles["-disabled"]);
    }, ANIMATION_TIMING);
  });

  const onPopState = useEvent(() => {
    if (
      typeof hideOnNavigate === "function"
        ? hideOnNavigate()
        : hideOnNavigate && openLocation.current !== window.location.pathname
    ) {
      onClose?.("popstate");
    }
  });

  const debouncedOnResize = useMemo(
    () => debounce(onResize, ANIMATION_TIMING),
    [onResize]
  );

  const innerElRef = useMemo(() => ({ current: innerEl }), [innerEl]);

  useResizeObserver(innerElRef, debouncedOnResize);

  /**
   * Basically this remove the nearest scroll while the
   * Dialog with "modal" prop with false is opened, we
   * need it to prevent unexpected scrolls from parent.
   *
   * @todo prevent Dialog parent child focus except Dialog component.
   */
  useEffect(() => {
    const el = (scrollableParentRef.current =
      scrollableParentRef.current || getScrollableParent(backdropRef.current));

    const reset = () => {
      if (!el) return;

      if (el.dataset.overflowY) {
        el.style.overflowY = el.dataset.overflowY;
        delete el.dataset.overflowY;
      }
    };

    if (!modal && show && el) {
      if (!el.dataset.overflowY) {
        el.dataset.overflowY = window.getComputedStyle(el).overflowY;
      }
      el.style.overflowY = "hidden";
    } else {
      reset();
    }

    return () => reset();
  }, [modal, show]);

  useEffect(() => {
    if (internalRef.current && show) {
      const [first] = getAllFocusableElements(internalRef.current);
      openLocation.current = window.location.href;
      initialFocusRef.current = first;
    }
  }, [show]);

  useEffect(() => {
    if (step && mounted && wrapperEl) {
      wrapperEl.classList.add(styles["-animate"]);
      wrapperEl.classList.add(styles["-disabled"]);
      debouncedOnResize();
    }
  }, [step, mounted, wrapperEl, debouncedOnResize]);

  useEffect(() => {
    window.addEventListener("popstate", onPopState);

    return () => {
      window.addEventListener("popstate", onPopState);
    };
  }, [onPopState]);

  return (
    <AriaKitDialog
      ref={internalRef}
      open={show}
      role={variant === "confirmation" ? "alertdialog" : "dialog"}
      store={dialogStore}
      render={(props) => (
        <div
          ref={backdropRef}
          className={cn(styles["backdrop"], {
            [styles["-modal"]]: modal,
            [styles["-scrollable"]]: !["drawer", "multi-step-drawer"].includes(
              variant
            ),
            [styles[`-${enhancedPlacement}`]]: enhancedPlacement,
          })}
          data-scope="backdrop"
          data-enter={(props as Record<string, any>)["data-enter"]}
        >
          <div {...props} />
        </div>
      )}
      backdrop={false}
      className={cn(className, styles["dialog"], {
        [styles["-closable"]]: onClose,
        [styles[`-${variant}`]]: variant,
        [styles[`-${enhancedSize}`]]: enhancedSize,
        [styles[`-align-${enhancedAlign}`]]: enhancedAlign,
        [styles[`-${enhancedPlacement}`]]: enhancedPlacement,
      })}
      modal={modal}
      portal={modal}
      initialFocus={initialFocusRef.current}
      hideOnEscape={onInteractOutside}
      unmountOnHide
      autoFocusOnHide={autoFocusOnHide}
      autoFocusOnShow={providerAutoFocus}
      preventBodyScroll={modal}
      hideOnInteractOutside={onInteractOutside}
      getPersistentElements={getPersistentElements}
      {...props}
    >
      <div ref={setWrapperEl} className={styles["dialog-wrapper"]}>
        <div ref={setInnerEl} className={styles["dialog-inner"]}>
          <DialogProvider
            show={show}
            step={step}
            onBack={undefined}
            variant={variant}
            onClose={onClose}
          >
            {children}
          </DialogProvider>
        </div>
      </div>
    </AriaKitDialog>
  );
};
