import React, { useMemo, useState } from "react";
import { Link as ReactRouterLink } from "react-router";
import {
  Button,
  Flex,
  Table as DSTable,
  TableConfigurationButton,
  type TablePaginationAddon,
  TableProvider,
  type TableRowAddon,
  VisuallyHidden,
} from "@adaptive/design-system";
import { useEvent, usePagination } from "@adaptive/design-system/hooks";
import { useGetVendorsQuery } from "@api/vendors/api";
import type { VendorsVendor } from "@api/vendors/types";
import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "@components/sticky";
import { TableFilterControls } from "@components/table-filter";
import { setFormatter } from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import {
  ACH_STATUS_OPTIONS,
  DOCUMENT_STATUS_OPTIONS,
  DOCUMENT_TYPE_OPTIONS,
} from "@src/vendors/constants";
import { useVendorAction } from "@store/vendors";
import * as analytics from "@utils/analytics";
import { getSearchParams } from "@utils/api";
import { scrollMainTop } from "@utils/scroll-main-top";

import { BatchActions } from "../batch-actions";

import { COLUMNS } from "./columns";

const DOC_TYPE_FILTER = DOCUMENT_TYPE_OPTIONS.map((option) => ({
  ...option,
  groupLabel: "Document type",
}));

const DOC_STATUS_FILTER = DOCUMENT_STATUS_OPTIONS.map((option) => ({
  ...option,
  groupLabel: "Document status",
}));

const ACH_STATUS_FILTER = ACH_STATUS_OPTIONS.map((option) => ({
  ...option,
  groupLabel: "ACH status",
}));

const EXTRA_DATA = [
  ...DOC_TYPE_FILTER,
  ...DOC_STATUS_FILTER,
  ...ACH_STATUS_FILTER,
];

export const VendorTable = () => {
  const { create } = useVendorAction();

  const [order, setOrder] = useState("display_name");

  const [stickOffset, setStickyOffset] = useState(0);

  const [selectedRows, setSelectedRows] = useState<VendorsVendor[]>([]);

  const { filters, setFilters, rawFilters } = useTableFilters({
    storageKey: "vendors",
    formatter: setFormatter,
  });

  const pagination = usePagination({ localStorageKey: "vendors-table-limit" });

  const searchParams = useMemo(() => {
    const enhancedFilters = { ...filters };

    if (enhancedFilters?.vendor) {
      enhancedFilters.id = enhancedFilters.vendor;
    }
    // since we're in vendors, we should replace vendor key with 'id'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return getSearchParams({
      ...enhancedFilters,
      limit: pagination.perPage,
      offset: pagination.offset,
      ordering: order,
    });
  }, [order, filters, pagination.offset, pagination.perPage]);

  const vendors = useGetVendorsQuery(searchParams.toString(), {
    // force refetch here to mitigate an issue
    // with the Table component where it will only re-calculate
    // column widths on changes to "loading" prop, and not data prop
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const onFiltersChange = useEvent((filters) => {
    setSelectedRows([]);
    setFilters(filters);
    pagination.setPage(0);
  });

  const row = useMemo<TableRowAddon<VendorsVendor>>(
    () => ({
      render: ({ row, props }) => (
        <ReactRouterLink {...props} to={`/vendors/${row.id}`}>
          <VisuallyHidden>Open {row.displayName}</VisuallyHidden>
        </ReactRouterLink>
      ),
      isError: (row) =>
        (row.errors ?? []).length > 0 &&
        row.errors?.some((error) => !error.isIgnored),
    }),
    []
  );

  const vendorsRefetch = vendors.refetch;

  const hasData = (vendors?.data?.results?.length ?? 0) > 0;

  const header = useMemo(
    () => ({ hide: !hasData, sticky: { offset: stickOffset } }),
    [hasData, stickOffset]
  );

  const onResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) => {
    setStickyOffset(sticky.height);
  });

  const select = useMemo(
    () => ({ value: selectedRows, onChange: setSelectedRows }),
    [setSelectedRows, selectedRows]
  );

  const enhancedPagination = useMemo<Required<TablePaginationAddon>>(
    () => ({
      page: pagination.page,
      total: vendors.data?.count || 0,
      perPage: pagination.perPage,
      perPageVariant: "lg",
      onChange: (page: number) => {
        scrollMainTop(0);
        pagination.setPage(page);
      },
      onPerPageChange: (perPage: number) => {
        pagination.setPage(0);
        pagination.setPerPage(perPage);
        analytics.track("perPageLimitChange", {
          location: "vendors-table",
          limit: perPage,
        });
      },
    }),
    [pagination, vendors.data?.count]
  );

  const hasFilters = Object.values(rawFilters).some((filter) => !!filter);

  const onClearFiltersClick = useEvent(() => {
    onFiltersChange({});
  });

  const sort = useMemo(
    () => ({ value: order, onChange: setOrder }),
    [order, setOrder]
  );

  const emptyState = useMemo(() => {
    if (vendors.isError) return "error";

    if (hasFilters) {
      return {
        title: "No vendors match your filters",
        subtitle:
          "Try adjusting your search or filters to find what you're looking for",
        action: {
          children: "Clear filters",
          onClick: onClearFiltersClick,
        },
      };
    }

    return {
      title: "You currently have no vendors",
      subtitle: "Create your first vendor",
      action: {
        children: "Create new vendor",
        onClick: () => create(),
      },
      hasError: vendors.isError,
    };
  }, [create, hasFilters, onClearFiltersClick, vendors.isError]);

  return (
    <Flex direction="column" shrink={false}>
      <TableProvider id="vendors-table">
        <StickyProvider onResize={onResize}>
          <Sticky
            style={{
              marginTop: "calc(var(--spacing-2xl) * -1)",
              paddingTop: "var(--spacing-2xl)",
              paddingBottom: "var(--spacing-lg)",
            }}
          >
            <Flex gap="md" direction="column">
              <TableFilterControls
                onFiltersChange={onFiltersChange}
                filters={rawFilters}
                extraData={EXTRA_DATA}
                includeFilters={["customers", "vendors"]}
                withFilterTags
              >
                {hasFilters && (
                  <Button onClick={onClearFiltersClick}>Clear filters</Button>
                )}

                <Flex grow gap="md" justify="flex-end">
                  <TableConfigurationButton />
                  <BatchActions
                    vendors={selectedRows}
                    filters={filters}
                    onAction={(clearSelections = true) => {
                      vendorsRefetch();
                      if (clearSelections) setSelectedRows([]);
                    }}
                  />
                </Flex>
              </TableFilterControls>
            </Flex>
          </Sticky>
          <StickyMeasurer>
            <DSTable
              row={row}
              size="sm"
              sort={sort}
              data={vendors.data?.results || []}
              header={header}
              select={select}
              columns={COLUMNS}
              loading={vendors.status === "pending"}
              pagination={enhancedPagination}
              emptyState={emptyState}
              data-testid="vendors-table"
            />
          </StickyMeasurer>
        </StickyProvider>
      </TableProvider>
    </Flex>
  );
};
