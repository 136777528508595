import React, { useMemo, useState } from "react";
import { Link } from "react-router";
import {
  Button,
  Flex,
  Loader,
  TextField,
  Timeline,
} from "@adaptive/design-system";
import { useForm } from "@adaptive/design-system/hooks";
import { formatDate, parseDate } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { postVendorPublicPOSignatureRequest } from "@api/vendor-requests";
import { z } from "zod";

import { RequestFormExpired, RequestFormSubmitted } from "./common";
import { STRINGS, VENDOR_REQUEST_TYPES } from "./constants";
import type { FormProps, VendorPoSignatureRequestType } from "./types";
import { VendorRequest } from "./vendor-request";

const schema = z.object({
  signature: z
    .string()
    .min(3, STRINGS.INVALID_SIGNATURE)
    .max(100, STRINGS.INVALID_SIGNATURE),
});

type Fields = z.infer<typeof schema>;

export const VendorPoSignatureRequest = () => {
  return (
    <VendorRequest
      type={VENDOR_REQUEST_TYPES["PO_SIGNATURE"]}
      form={POSignatureRequestForm}
    />
  );
};

export const POSignatureRequestForm = ({
  companyName,
  queryParams,
  request,
  onFormSubmit,
}: FormProps) => {
  const [isFormSubmitted, setFormSubmitted] = useState<boolean>(false);
  const initialValues: Fields = useMemo(() => ({ signature: "" }), []);

  const type = VENDOR_REQUEST_TYPES["PO_SIGNATURE"];

  const { purchase_order } = request as VendorPoSignatureRequestType;

  const { latest_external_po_signature: lastSignatureData } = purchase_order;

  const form = useForm<Fields>({
    schema,
    async onSubmit(values) {
      try {
        await postVendorPublicPOSignatureRequest(queryParams, values);
        setFormSubmitted(true);
        onFormSubmit?.();
      } catch (error) {
        handleErrors(error);
      }
    },
    initialValues,
  });

  const data = useMemo(() => {
    return [
      {
        icon: "signature",
        title: "Purchase order signed",
        subtitle: formatDate(
          parseDate(lastSignatureData?.created_at ?? "", [
            "MM/dd/yyyy h:mmaaa",
            "iso",
          ]),
          "MM/dd/yyyy h:mmaaa"
        ),
      },
    ];
  }, [lastSignatureData]);

  return (
    <>
      {form.isSubmitting && <Loader position="fixed" />}
      {request.is_completed || isFormSubmitted ? (
        <RequestFormSubmitted type={type}>
          <Timeline data={data} />
          <Flex>
            <Button
              as={Link}
              rel="noreferrer"
              target="_blank"
              to={lastSignatureData?.document ?? ""}
              aria-label="download"
            >
              Download purchase order
            </Button>
          </Flex>
        </RequestFormSubmitted>
      ) : request.is_expired ? (
        <RequestFormExpired companyName={companyName} type={type} />
      ) : (
        <>
          <Flex width="full" direction="column">
            <Flex
              direction="column"
              as="form"
              {...form.props}
              width="430px"
              minHeight="150px"
            >
              <TextField
                label={STRINGS.LABEL_SIGNATURE}
                placeholder="Enter your full name"
                {...form.register("signature")}
              />
              <Flex>
                <Button
                  size="lg"
                  data-testid="add-signature"
                  form={form.id}
                  type="submit"
                  disabled={form.isSubmitting || !form.isValid}
                >
                  {STRINGS.PO_SIGNATURE_FORM_BUTTON_LABEL}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};
