import React, { type ReactNode, useRef } from "react";
import { MenuButton, useMenuContext, useStoreState } from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { mergeRefs } from "../../utils/merge-refs";
import { Icon } from "../icon";

import { useDropdown } from "./dropdown-context";
import styles from "./dropdown.module.css";

const DEFAULT_COMPONENT = "button";

type Props = {
  render?: (open: boolean) => ReactNode;
  animated?: boolean;
};

export const DropdownTrigger = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      render,
      animated = false,
      children,
      className,
      ...props
    },
    ref
  ) => {
    const internalRef = useRef<HTMLButtonElement>(null);

    const menuStore = useMenuContext()!;

    const { trigger } = useDropdown() ?? {};

    const open = useStoreState(menuStore, "open");

    const content = render ? render(open) : children;

    return (
      <MenuButton
        ref={mergeRefs(ref, internalRef)}
        render={<Component />}
        showOnHover={trigger === "hover"}
        className={cn(className, styles["dropdown-trigger"], {
          [styles["-reset"]]: Component === "button",
        })}
        {...props}
      >
        {animated ? (
          <>
            {content}
            <Icon
              size="sm"
              variant="solid"
              name={open ? "caret-up" : "caret-down"}
            />
          </>
        ) : (
          content
        )}
      </MenuButton>
    );
  }
);

DropdownTrigger.displayName = "DropdownTrigger";
