import React, { type ReactNode, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router";
import {
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Loader,
  ResponsiveProvider,
  Text,
  toast,
} from "@adaptive/design-system";
import { useEvent, useVisibilityChange } from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import { RecordPreview } from "@components/attachment";
import { DropZone } from "@components/draggable";
import {
  Main,
  MainContent,
  MainHeader,
  MainHeaderBack,
  MainTitle,
} from "@components/main";
import { NotFound } from "@components/not-found";
import {
  StageNavigation,
  type StageNavigationProps,
} from "@components/stage-navigation";
import {
  MIN_LEFT_SIZE,
  MIN_RIGHT_SIZE,
  TransactionSplitView,
  type TransactionSplitViewProps,
} from "@components/transaction-split-view";
import { useCurrentClientFromRealm } from "@hooks/useCurrentClientFromRealm";
import type { Selector } from "@reduxjs/toolkit";
import { getOptionsFor } from "@store/attachments/hooks";
import type { AttachableLifeCycleHooks } from "@store/attachments/slice";
import { useExpenseAction, useExpensePermissions } from "@store/expenses";
import {
  expenseFetchStatusSelector,
  expenseSelector,
  expenseSelectors,
} from "@store/expenses/selectors";
import type { CollectionQueries } from "@store/expenses/types";
import { useClientSettings } from "@store/user";

import { STRINGS as STAGE_STRINGS } from "./stages/constants";
import { DROPZONE_MESSAGES, IMAGE_FORMATS, STRINGS } from "./utils/constants";
import { StatusMap } from "./utils/constants";
import { getTableRouterUrlForStatus } from "./utils/table";
import { CycleProvider, useCycle } from "./cycle-provider";
import { ExpenseForm } from "./expense-form";

const STAGES: StageNavigationProps["data"] = [
  {
    name: "DRAFT",
    title: STRINGS.draftTitle,
    subtitle: "Add the details of the receipt",
  },
  {
    name: "FOR_REVIEW",
    title: "Review",
    subtitle: "Review the receipt before syncing",
  },
];

const NAME: Record<CollectionQueries, string> = {
  ALL: "All",
  DRAFT: STRINGS.draftTitle,
  FOR_REVIEW: "For review",
};

const TAB_TO_STATUS = Object.fromEntries(
  Object.entries(StatusMap).map(([key, value]) => [value, key])
) as Record<string, CollectionQueries>;

const Title = () => {
  const cycle = useCycle();

  const { cardFeedEnabled } = useClientSettings();

  const docNumber = useSelector(expenseSelectors.docNumber as Selector);

  const fetchStatus = useSelector(expenseFetchStatusSelector);

  const { expenseId } = useParams();

  const isTransactionGeneratedDraft = useSelector(
    expenseSelectors.isTransactionGeneratedDraft
  );

  let title: ReactNode = null;

  if (expenseId === "new" || (cardFeedEnabled && isTransactionGeneratedDraft)) {
    title = STAGE_STRINGS.HEADING_PAGE;
  } else if (!cycle.status) {
    title = (
      <div style={{ display: "grid" }}>
        <Text as="span" size="2xl" weight="bold" truncate>
          Receipt {docNumber ? `#${docNumber}` : ""}
        </Text>
      </div>
    );
  } else {
    title = cycle.isLoading ? null : (
      <Flex align="center" grow justify="space-between" gap="md">
        <Flex gap="md" align="baseline" wrap shrink={false}>
          {NAME[cycle.status]}
          <Text weight="regular" size="xl" as="span">
            ({cycle.current} of {cycle.total})
          </Text>
        </Flex>
        <ButtonGroup
          size="sm"
          color="neutral"
          variant="ghost"
          direction="vertical"
        >
          <Button
            onClick={() => cycle.previous()}
            disabled={!cycle.hasNavigation}
            aria-label="Go to previous receipt"
          >
            <Icon variant="solid" name="chevron-up" />
          </Button>
          <Button
            onClick={() => cycle.next()}
            disabled={!cycle.hasNavigation}
            aria-label="Go to next receipt"
          >
            <Icon variant="solid" name="chevron-down" />
          </Button>
        </ButtonGroup>
      </Flex>
    );
  }

  return fetchStatus !== "pending" ? <MainTitle>{title}</MainTitle> : null;
};

const BREAKPOINTS = {
  mobile: 0,
  tablet: MIN_LEFT_SIZE + MIN_RIGHT_SIZE,
};

const RawExpense = () => {
  const {
    enable: enableCycle,
    disable: disableCycle,
    isLoading: isLoadingCycle,
  } = useCycle();

  const headerRef = useRef<HTMLElement>(null);

  const navigate = useNavigate();

  const { expenseId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const { state: locationState } = useLocation();

  const { canAddExpense, canEditExpense } = useExpensePermissions();

  const stageNavigationRef = useRef<HTMLDivElement>(null);

  const stateId = useSelector(expenseSelectors.id);
  const status = useSelector(expenseSelectors.reviewStatus);
  const initialStatus = useSelector(expenseSelectors.initialReviewStatus);
  const url = useSelector(expenseSelectors.url);
  const realm = useSelector(expenseSelectors.realm);
  const fetchStatus = useSelector(expenseFetchStatusSelector);

  const {
    fetchById,
    setStatus,
    setExpense,
    createNew,
    navigateTo,
    saveAttachment,
    syncDrawChanges,
  } = useExpenseAction();

  const isExistingExpense = useMemo(() => expenseId !== "new", [expenseId]);

  const onClose = useEvent(() => {
    const url = getTableRouterUrlForStatus(status);
    navigate(dotObject.get(locationState as object, "prev", url), {
      state: locationState,
    });
  });

  const onStageChange = useEvent((status) => {
    disableCycle();
    setStatus(status);
  });

  const onAttachmentDelete = useEvent(() => {
    fetchById(expenseId);
  });

  const onResize = useEvent<
    Exclude<TransactionSplitViewProps["onResize"], undefined>
  >(({ left, right }) => {
    if (!stageNavigationRef.current) return;

    let width = `${right}px`;

    if (left === 0 || right === 0) {
      width = "100%";
    }

    stageNavigationRef.current.style.minWidth = width;
    stageNavigationRef.current.style.maxWidth = width;
  });

  const { attachableOptions, onItemComplete, onComplete } = useMemo(() => {
    const attachableOptions = getOptionsFor({
      url,
      humanReadableType: "Receipt",
    });

    const onItemComplete: AttachableLifeCycleHooks["onItemComplete"] = ({
      data,
    }) => {
      isExistingExpense ? saveAttachment(data) : setExpense(data);
    };

    const onComplete = () => {
      if (attachableOptions.save) toast.success("Receipt processing complete");
    };

    return {
      attachableOptions,
      onItemComplete,
      onComplete,
    };
  }, [isExistingExpense, saveAttachment, setExpense, url]);

  const isReady =
    (isExistingExpense && stateId == expenseId) || !isExistingExpense;

  const currentStep = !isReady
    ? ""
    : status === "DRAFT"
      ? "DRAFT"
      : "FOR_REVIEW";

  /**
   * We disable lint rule here because we only need to update this value
   * when the initial status changes.
   */
  const initialStep = useMemo(() => currentStep, [initialStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isExistingExpense && !canAddExpense) navigate("/");
  }, [navigate, isExistingExpense, canAddExpense]);

  useCurrentClientFromRealm(realm);

  useEffect(() => {
    if (isExistingExpense) fetchById(expenseId);
  }, [fetchById, expenseId, isExistingExpense]);

  useEffect(() => {
    if (!isExistingExpense) stateId ? navigateTo(stateId, true) : createNew();
  }, [stateId, isExistingExpense, navigateTo, createNew]);

  useEffect(() => {
    const status = searchParams.get("status");

    if (status) {
      createNew().then(() => {
        enableCycle(TAB_TO_STATUS[status]);
        setSearchParams({}, { replace: true, state: locationState });
      });
    }
  }, [createNew, enableCycle, locationState, searchParams, setSearchParams]);

  useVisibilityChange((isVisible) => {
    if (isVisible && isExistingExpense && stateId) {
      syncDrawChanges(String(stateId));
    }
  });

  return fetchStatus === "rejected" ? (
    <NotFound to="/expenses" resource="receipts" />
  ) : (
    <Main>
      <ResponsiveProvider
        ref={headerRef}
        breakpoints={BREAKPOINTS}
        initialBreakpoint="tablet"
      >
        <MainHeader variant="unspaced" ref={headerRef}>
          <Flex
            gap="xl"
            height="full"
            direction={{ mobile: "column", tablet: "row" }}
          >
            <Flex
              gap="xl"
              width="full"
              align="center"
              padding={{
                mobile: ["none", "2xl", "none", "none"],
                tablet: "none",
              }}
              borderWidth={{
                mobile: ["none", "none", "sm", "none"],
                tablet: "none",
              }}
              minHeight={{ mobile: "90px", tablet: "auto" }}
              borderColor="neutral-300"
            >
              <MainHeaderBack onClick={onClose} />
              {fetchStatus !== "pending" && <Title />}
            </Flex>
            <StageNavigation
              ref={stageNavigationRef}
              data={STAGES}
              onChange={onStageChange}
              value={currentStep}
              initialValue={initialStep}
            />
          </Flex>
        </MainHeader>
        <MainContent scrollable={false} variant="unspaced">
          <Flex width="full" shrink={false} height="full">
            {(isLoadingCycle || fetchStatus === "pending") && (
              <Loader position="absolute" />
            )}
            <TransactionSplitView
              left={
                <RecordPreview
                  onDelete={canEditExpense ? onAttachmentDelete : undefined}
                  dataSelector={expenseSelector}
                >
                  {url ? (
                    <DropZone
                      attachableOptions={attachableOptions}
                      concurrentLimit={1}
                      grow
                      height="full"
                      showBorder
                      width="full"
                      onComplete={onComplete}
                      onItemComplete={onItemComplete}
                      hasPermission={canEditExpense}
                      idleMessage={DROPZONE_MESSAGES.IDLE}
                      imageFormats={IMAGE_FORMATS}
                      pendingMessage={DROPZONE_MESSAGES.PENDING}
                      draggingMessage={DROPZONE_MESSAGES.DRAGGING}
                    />
                  ) : (
                    <Flex gap="md" direction="column" align="center">
                      <Text align="center">
                        To upload a document, save the receipt first.
                      </Text>
                      <Text align="center">
                        To use the AI, drag and drop a document onto the receipt
                        list.
                      </Text>
                    </Flex>
                  )}
                </RecordPreview>
              }
              right={isReady && <ExpenseForm />}
              onResize={onResize}
            />
          </Flex>
        </MainContent>
      </ResponsiveProvider>
    </Main>
  );
};

export const Expense = () => (
  <CycleProvider>
    <RawExpense />
  </CycleProvider>
);
